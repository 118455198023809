import { useMemo } from "react";
import { View } from "tamagui";

import { supabase } from "../../lib/supabase";

import { Image, ImageStyle } from "react-native";
import { Role } from "../features/Profile/types";
import { StarIcon } from "./design-system/icons";
interface AvatarProps {
  size?: number;
  width?: number;
  height?: number;
  url: string | null;

  imageStyle?: ImageStyle | undefined;
  role?: Role;
}

export default function Avatar({
  url,
  size,
  imageStyle,
  height,
  width,
  role,
}: AvatarProps) {
  const widthOrSize = size ?? width ?? 100;
  const heightOrSize = size ?? height ?? 100;
  const avatarUrl = useMemo(
    () =>
      url
        ? supabase.storage.from("avatars").getPublicUrl(url)?.data?.publicUrl
        : null,
    [url]
  );
  const iconWidth = (size || width || 100) * 0.35;
  return (
    <View width={widthOrSize} height={heightOrSize} position="relative">
      {avatarUrl && avatarUrl?.length ? (
        <Image
          source={{ uri: avatarUrl }}
          accessibilityLabel="Avatar"
          style={{
            ...imageStyle,
            width: widthOrSize,
            height: heightOrSize,
          }}
        />
      ) : (
        <Image
          source={require("../../assets/default-avatar.gif")}
          accessibilityLabel="Avatar"
          style={{
            ...imageStyle,
            width: widthOrSize,
            height: heightOrSize,
          }}
        />
      )}
      {role && role !== "Member" ? (
        <View pos="absolute" bottom={0} right={-3}>
          <StarIcon width={iconWidth} height={iconWidth} />
        </View>
      ) : null}
    </View>
  );
}
