import { Label, View, XStack } from "tamagui";
import { Pill } from "../Pill/Pill";
import { Pressable } from "react-native";

export type PillSelectOption = {
  label: string;
  value: string;
};

type PillSelectProps = {
  label: string;
  options: PillSelectOption[];
  value: string;
  setValue: (value: string) => void;
};

export const PillSelect = ({
  label,
  options,
  value,
  setValue,
}: PillSelectProps) => {
  return (
    <View>
      <Label>{label}</Label>
      <XStack gap="$2" flexWrap="wrap">
        {options.map((o, i) => (
          <Pressable key={i} onPress={() => setValue(o.value)}>
            <Pill
              key={i}
              bg={value === o.value ? "black" : "$brandParchment"}
              borderColor={"black"}
              text={o.label}
              color={value === o.value ? "$brandParchment" : "black"}
            />
          </Pressable>
        ))}
      </XStack>
    </View>
  );
};
