import { User } from "@supabase/supabase-js";
import { atom } from "jotai";
import { iProfile } from "../src/features/Profile";
import { Role } from "../src/features/Profile/types";

export const currentUserAtom = atom<User | null>(null);

export const currentUserProfileAtom = atom<iProfile | null>(null);

export const currentUserRoleAtom = atom<Role | null>(null);

export const isAuthenticatedAtom = atom(false);
