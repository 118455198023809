import { G, Path, Svg, SvgProps } from "react-native-svg";

export const CalendarIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 102.3 92.18" {...props}>
      <G id="Layer_1-2">
        <Path d="m20.42,1.28c-.66.73-1.18,2.53-1.19,4.1-.05,6.12.02,6.08-8.71,5.98-11.05-.12-10.99-.18-10.22,11.97.33,5.1.69,16.21.8,24.67.11,8.46.31,17.65.44,20.41.13,2.77.3,8.29.38,12.32.12,6.42.33,7.53,1.74,9.18l1.6,1.88,39.38.29c44.84.33,54.11-.06,56.44-2.36,1.48-1.45,1.49-1.68.68-14.24-.45-7.01-1.16-21.16-1.58-31.4-1.11-27.23-1.45-31.68-2.5-33.1-.83-1.12-1.49-1.16-7.77-.38-3.75.47-9.62.85-12.97.85h-6.11v-4.5c0-4.85-.74-6.28-3.24-6.28s-3.24,1.43-3.24,5.6v3.82l-7-.2c-3.87-.11-12.35.07-18.97.41l-11.98.61v-4.06c0-2.25-.34-4.7-.76-5.49-.95-1.77-3.66-1.81-5.23-.07h0Zm38.82,15.63c4.03.43,4.44.64,4.44,2.18,0,1.84,2.32,4.5,3.92,4.5,1.37,0,3.92-2.49,3.92-3.82,0-.8,2.05-1.09,9.49-1.33,5.19-.17,9.8-.03,10.17.3.61.54.74,17.01.14,17.62-.11.11-3.07.56-6.62,1-6.11.76-18.22.63-52.96-.56-8.19-.28-14.97-.14-18.56.38-3.14.45-5.74.73-5.84.61-.09-.11-.4-4.5-.68-9.69l-.51-9.49h6.52c5.73,0,6.61.16,7.25,1.37,1.51,2.81,5.87,2.46,6.82-.55.39-1.23,1.7-1.43,12.75-2.05,6.76-.37,13-.74,13.82-.82.83-.08,3.48.08,5.94.34h0Zm-4.91,28.61c.3.18,8.94.35,19.18.37l18.63.03.48,9.11c.26,5.04.78,13.24,1.16,18.36s.52,9.42.31,9.62c-.21.21-7.03.24-15.22.07-8.19-.17-21.12-.23-28.84-.14-7.71.09-19.81-.03-26.96-.27l-12.97-.44-.58-5.46c-.32-3-.66-7.4-.75-9.76-.09-2.39-.5-5.74-.92-7.54-.42-1.8-.77-5.7-.78-8.77l-.03-5.53,10.58-.19c13.1-.24,36,.1,36.72.54h0Z" />
      </G>
    </Svg>
  );
};
