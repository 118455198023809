import { Path, Svg, SvgProps } from "react-native-svg";

export const LinkIcon = (props: SvgProps) => {
  return (
    <Svg width="60" height="48" viewBox="0 0 60 48" fill="none" {...props}>
      <Path
        d="M44.6794 0.497731C42.0421 1.39121 33.5079 5.65525 30.4639 7.60858C25.8116 10.5848 25.0968 11.5399 26.4894 12.9325C27.537 13.98 28.8987 13.7828 31.1417 12.2547C32.2385 11.5091 35.5659 9.69747 38.5668 8.21245C43.1759 5.93254 44.4637 5.49505 47.0271 5.31635C49.3132 5.15614 50.3915 5.28554 51.4514 5.83395C54.1873 7.25735 54.976 10.9483 53.3431 14.6701C52.505 16.5803 48.4875 20.5486 44.8951 23.0195C42.0606 24.9667 41.3397 25.7677 41.3397 26.9816C41.3397 28.3804 42.5659 29.6498 43.9215 29.6498C45.5113 29.6498 47.4769 28.4235 52.3387 24.3936C56.9971 20.5363 58.0508 19.0697 59.5481 14.3744C60.953 9.97476 58.9689 4.1271 55.1732 1.45283C53.5156 0.288225 52.8255 0.0972059 49.8616 0.0109391C47.8713 -0.0506801 45.7146 0.152663 44.6794 0.503892V0.497731Z"
        fill="black"
      />
      <Path
        d="M16.1003 46.6628C18.6699 45.5783 26.8652 40.7043 29.7613 38.5353C34.1856 35.2263 34.8264 34.2219 33.3414 32.9402C32.2199 31.9728 30.8766 32.2686 28.7508 33.957C27.7094 34.7826 24.5299 36.8284 21.6399 38.5291C17.2095 41.1356 15.9586 41.6717 13.4138 42.0352C11.1462 42.3618 10.0555 42.3125 8.96485 41.8442C6.13037 40.6241 5.07668 37.0009 6.4323 33.1744C7.1286 31.2087 10.8442 26.957 14.2518 24.2335C16.9384 22.0829 17.5977 21.2326 17.5053 20.0187C17.4005 18.6261 16.088 17.4492 14.7386 17.5478C13.155 17.6648 11.2817 19.0328 6.72807 23.4016C2.35311 27.5855 1.40418 29.126 0.258062 33.9138C-0.820273 38.4059 1.58287 44.0933 5.56347 46.478C7.30113 47.5193 8.00359 47.6549 10.9675 47.5317C12.9578 47.4454 15.0898 47.0818 16.1003 46.6567V46.6628Z"
        fill="black"
      />
      <Path
        d="M41.2964 13.216C40.6679 13.6904 36.8105 15.9765 32.7067 18.318C24.2895 23.1182 16.5686 28.4544 15.8969 29.9394C15.2869 31.295 16.5008 33.1991 17.9797 33.1991C18.6082 33.1991 20.9867 31.7325 23.8581 29.5635C26.5263 27.5486 30.969 24.4492 33.8035 22.6191C44.7162 15.5698 45.6097 14.8366 45.1229 13.2776C44.747 12.076 42.8615 12.0514 41.2964 13.2221V13.216Z"
        fill="black"
      />
    </Svg>
  );
};
