import mixpanel from "mixpanel-browser";

mixpanel.init("3ca9be30dbf06ed6a3e7bdf6df99d510", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

const setName = (name: string) => {
  mixpanel.people.set({ $name: name });
};

export { setName };
export default mixpanel;
