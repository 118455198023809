import {
  AGroupIcon,
  BGroupIcon,
  CGroupIcon,
  DGroupIcon,
  PartyGroupIcon,
} from "../../../components/design-system/icons";
import { Pace } from "../../../screens/Events/__generated__/EventsListQuery.graphql";

const groupIcons = {
  A: AGroupIcon,
  B: BGroupIcon,
  C: CGroupIcon,
  D: DGroupIcon,
  Party: PartyGroupIcon,
  "%future added value": AGroupIcon,
};

export const useGetGroupIcon = ({ group, fill }: { group: Pace }) => {
  return groupIcons[group];
};
