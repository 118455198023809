import { styled, TextArea as TamaguiTextArea } from "tamagui";

export const TextArea = styled(TamaguiTextArea, {
  bg: "white",
  borderWidth: 1,
  borderRadius: 6,
  borderColor: "black",
  outlineWidth: 0,

  py: "$4",
  px: "$3",
  height: "auto",
  fontSize: 18,
  placeholderTextColor: "rgba(0,0,0,0.70)",
  focusStyle: {
    bg: "white",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "black",
    outlineWidth: 0,
    shadowOffset: { width: 1.1, height: 1.1 },
    shadowColor: "black",
    py: "$4",
    height: "auto",
    fontSize: 18,
  },
});
