import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../atoms/global";
import { supabase } from "../../lib/supabase";
import { useState } from "react";
import { Friendship } from "../components/FriendShipButton/FriendshipButton";

export const useFriendRequestUser = () => {
  const currentUser = useAtomValue(currentUserAtom);

  const [isLoading, setIsLoading] = useState(false);

  const handleFriendRequestUser = async (
    userId: string,
    callback: (friendship: Friendship | null) => void
  ) => {
    const args = {
      friend_id: userId,
      user_id: currentUser?.id,
      status: "pending",
    };
    setIsLoading(true);

    await supabase
      .from("friendships")
      .insert(args)
      .select("*")
      .single()
      .then((res) => {
        callback(res.data);
        setIsLoading(false);
      });
  };

  return [handleFriendRequestUser, isLoading] as const;
};
