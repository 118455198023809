import { G, Path, Svg, SvgProps } from "react-native-svg";

export const BikeIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 164.94 114.41" {...props}>
      <G id="Layer_1-2">
        <Path d="m107.33,1.33c-3.08,1.79-4.86,4.59-5.14,8.09-.4,4.93,4.46,8.65,10.86,8.31,2.18-.12,3.26-.68,4.92-2.55,1.14-1.29,2.14-3.1,2.15-3.91.08-6.09-1.13-8.38-5.35-10.14-3.7-1.55-4.46-1.53-7.44.2h0Z" />
        <Path d="m88.02,19.36c-2.93,1.48-7.06,3.37-9.11,4.17-2.09.81-5.98,2.64-8.77,4.12-2.79,1.48-6.35,3.28-8.02,4.07-3.93,1.85-10.67,6.25-11.71,7.64-1.33,1.78.52,4.33,4.44,6.1,5.18,2.34,23.98,15.36,24.05,16.66.02.41-1.5,4.39-3.4,8.86-7,16.5-8.18,22.1-5.26,25.02,1.52,1.52,4.05,1.72,6.1.48,1.53-.92,2.02-2.42,4.44-13.55,1.04-4.77,2.7-10.27,3.68-12.16,5.69-10.98,3.68-14.63-14.66-26.68-3.19-2.09-5.56-4.09-5.26-4.43s4.95-2.67,10.35-5.2c5.4-2.53,11.83-5.83,14.31-7.35,4.17-2.56,4.57-2.68,5.45-1.59.52.64,1.44,3,2.02,5.16.58,2.16,1.54,4.83,2.11,5.83,1.36,2.43,12.03,12.61,15.71,15,5.02,3.26,10.28,1.48,9.49-3.21-.24-1.45-1.39-2.62-4.36-4.45-6.64-4.1-13.51-10.66-14.56-13.89-3.18-9.81-5.9-13.69-9.44-13.46-1.23.08-4.68,1.37-7.61,2.85h0Z" />
        <Path d="m22.92,55.56c-6.02,1.22-13.7,5.85-17.6,10.6-2.64,3.22-3.33,4.76-4.18,9.24-.55,2.95-1.06,7.98-1.12,11.13-.1,5.5.03,6.01,2.98,11.94,3.62,7.26,6.7,10.32,13.25,13.19,6.99,3.06,12.08,3.48,20.46,1.71,8.04-1.7,12.94-4.48,17.04-9.66,4-5.07,4.83-7.64,6.72-20.86.57-3.98-1.07-11.29-3.46-15.4-2.27-3.91-8.99-9.2-12.8-10.08-1.8-.42-4.71-1.11-6.51-1.56-4.69-1.16-9.84-1.25-14.78-.25h0Zm7.98,8.08c2.61.17,6.52.75,8.78,1.3,5.04,1.24,8.35,4.62,10.56,10.79,1.38,3.85,1.45,4.78.85,10.34-1.31,12.17-5.18,16.74-16.12,19.02-7.3,1.52-10.73,1.32-15.77-.95-5.13-2.3-6.65-3.66-9.64-8.63-2.3-3.82-2.5-4.58-2.36-8.97.27-8.15,1.81-13.36,4.92-16.71,3.94-4.24,9.17-7.25,11.87-6.84,1.22.19,4.3.47,6.9.63h0Z" />
        <Path d="m126.89,55.79c-3.08,1.15-9.32,6.06-11.86,9.34-4.55,5.87-6.74,19.77-4.15,26.4,1.62,4.15,7.17,11.24,10.4,13.28,4.09,2.58,16.03,4.58,21.38,3.59,8.93-1.66,18.66-10.14,20.99-18.29,1.55-5.41,1.74-15.52.37-19.84-.55-1.75-1.81-4.28-2.82-5.66-1.83-2.51-10.07-7.89-14.18-9.26-2.79-.93-17.32-.61-20.14.44h0Zm12.09,7.57c4.28.76,13.94,5.13,15.77,7.12,1.32,1.44,2.4,12.79,1.58,16.59-.81,3.73-5.08,9.24-8.86,11.44-2.86,1.67-7.06,2.16-11.21,1.3-1.46-.3-4.21-.85-6.22-1.25-5.94-1.17-12.33-8.29-12.64-14.09-.08-1.5.54-5.22,1.38-8.27,1.77-6.45,4.74-10.17,9.71-12.17,3.97-1.59,5.04-1.66,10.49-.69h0Z" />
      </G>
    </Svg>
  );
};
