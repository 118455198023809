import { G, Path, Svg, SvgProps } from "react-native-svg";

export const PhoneIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 61.26 99.17" {...props}>
      <G id="Layer_1-2">
        <Path d="m21.77,3.33C8.38,10.05,3.23,16.81,1.16,30.39c-1.68,11.02-1.55,17.85.44,23.78.91,2.72,2.51,8.23,3.55,12.26,5.34,20.65,15.99,31.2,32.91,32.61,7.24.6,12.8-.83,18.1-4.66,4.11-2.97,5.1-4.39,5.1-7.31,0-2.32-5.39-18.59-7.6-22.95-2.3-4.53-5.46-5.59-8.77-2.96-4.77,3.79-9.82,6.34-13.41,6.78-3.29.4-4.07.15-5.99-1.93-4.3-4.66-7.39-20.73-4.88-25.42,1.24-2.32,6.09-4.93,13.01-6.99,6.91-2.06,9.08-4.25,8.24-8.31-2.15-10.37-5.66-22.05-7.18-23.86-1.98-2.35-5.41-1.85-12.9,1.91h0Zm10.81,6.24c1.81,7.16,3.67,15.35,3.67,16.21,0,.46-2.22,1.42-5.02,2.17-8.15,2.19-13.52,5.3-15.6,9.02-4.84,8.64.14,29.42,8.44,35.23,4.23,2.96,12.34,1.26,20.9-4.37l2.39-1.57,1.73,2.8c.96,1.56,1.98,4.36,2.3,6.33.32,1.97,1.05,4.7,1.65,6.17,2.45,6.01,2.38,7.07-.58,8.86-5.09,3.07-10.94,3.76-16.78,1.99-2.8-.85-6.07-2.05-7.24-2.66-7.68-3.99-13.96-12.54-17.2-23.4-.9-3.03-2.7-9.01-3.99-13.29-2.11-6.98-2.28-8.6-1.75-15.84.82-11.11,2.33-15.58,7.02-20.81,2.89-3.22,5.92-5.37,11.02-7.82,3.87-1.85,7.28-3.22,7.57-3.04.3.18.95,1.97,1.47,4.03h0Z" />
      </G>
    </Svg>
  );
};
