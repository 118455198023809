import { G, Path, Svg, SvgProps } from "react-native-svg";

export const ArrowRightIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 100.68 96.71" {...props}>
      <G id="Layer_1-2">
        <Path d="m54.79.74c-4.48,2.62-2.74,6.99,6.85,17.23,4.38,4.67,7.49,8.39,15.23,18.22l3.92,4.98-1.98.54c-4.04,1.1-21.98,2.01-46.75,2.37-13.99.2-26.5.53-27.78.73-2.66.42-4.82,3.13-4.16,5.23,1.19,3.79,13.99,5.84,28.12,4.49,5.04-.48,16.86-.83,26.35-.77,9.49.06,19.62-.14,22.59-.44l5.39-.55-1.98,2.46c-1.08,1.34-3.3,4.33-4.88,6.59-1.58,2.25-4.29,5.84-5.95,7.88-3.5,4.3-9.73,13.42-11.47,16.79-1.73,3.34-1.5,6.16.67,8.33,2.13,2.13,4.91,2.48,7.17.91.82-.57,4.01-4.73,7.03-9.17,7.43-10.92,19.64-27.8,25.24-34.88,3.9-4.93,3.64-5.53-7.1-16.52-5.27-5.39-10.7-11.23-12.13-13.04C74.94,16.82,58.12-.03,57.04,0c-.57.01-1.57.34-2.25.74h0Z" />
      </G>
    </Svg>
  );
};
