import { useRef } from "react";
import ViewShot from "react-native-view-shot";
import * as Sharing from "expo-sharing";
import { Platform } from "react-native";
import { ScrollView, View, XStack } from "tamagui";
import { userProfileStateAtom } from "../../features/Profile/atoms";
import { useAtom, useAtomValue } from "jotai";
import { currentUserAtom } from "../../../atoms/global";
import { CloseIcon } from "../../components/design-system/icons";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { ClubNavigatorParamList } from "../../navigators/ClubNavigator";
import { Button } from "../../components/design-system";
import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import { ShareProfile } from "../../components/ShareProfile";
import * as Linking from "expo-linking";
import * as Clipboard from "expo-clipboard";
import { customColorTokens } from "../../../tamagui.config";
import { graphql } from "relay-runtime";
import { useLazyLoadQuery } from "react-relay";
import { ShareScreenQuery } from "./__generated__/ShareScreenQuery.graphql";

export const ShareScreen = () => {
  const user = useAtomValue(currentUserAtom);
  const data = useLazyLoadQuery<ShareScreenQuery>(ShareScreenQueryString, {
    id: user?.id ?? "",
  });

  const [profileState] = useAtom(userProfileStateAtom);

  const profileUrl = `https://app.breakfastclubatx.com/members/${profileState.id}`;

  const imageRef = useRef<ViewShot>(null);
  const onSaveImageAsync = async () => {
    try {
      if (Platform.OS === "web") {
        Linking.openURL(profileUrl);
      } else {
        imageRef?.current?.capture?.().then(async (uri) => {
          await Sharing.shareAsync(uri, {
            dialogTitle: "Share your member card",
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const copyUrl = async () => {
    if (Platform.OS === "web") {
      navigator.clipboard.writeText(profileUrl);
    } else {
      await Clipboard.setUrlAsync(profileUrl);
    }
  };

  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();
  const topInset = useSafeAreaInsets().top;

  return (
    <View flex={1} p="relative">
      <View
        bg="$brandParchment"
        flex={1}
        style={{
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <View
          left="$4"
          $platform-native={{ top: topInset - 5 }}
          $platform-web={{ top: "$4" }}
          zIndex={100}
          onPress={() => navigation.navigate("Profile")}
        >
          <CloseIcon width={25} height={25} />
        </View>
        <SafeAreaView
          style={{
            flex: 1,
            position: "relative",
            width: "100%",
            backgroundColor: customColorTokens.brandParchment,
          }}
        >
          <ScrollView w="100%">
            <ViewShot
              ref={imageRef}
              options={{ fileName: "member-card", format: "png", quality: 1 }}
              style={{
                flex: 1,
                width: "100%",
                position: "relative",
                borderColor: "black",
                transform: [{ scale: 0.9 }],
              }}
            >
              <View bg="$brandYellow" py={40}>
                {data.profilesCollection?.edges?.[0]?.node ? (
                  <ShareProfile
                    fragmentRef={data.profilesCollection?.edges?.[0]?.node}
                  />
                ) : null}
              </View>
            </ViewShot>
          </ScrollView>

          <XStack
            zIndex={100}
            justifyContent="space-between"
            px="$4"
            gap="$2"
            $platform-web={{ pb: "$4" }}
            flexWrap="wrap"
          >
            <Button
              fontSize={16}
              bg="$brandParchment"
              pressStyle={{ backgroundColor: "$brandParchment" }}
              onPress={copyUrl}
              flex={1}
            >
              Copy Url
            </Button>
            <Button fontSize={16} onPress={onSaveImageAsync} flex={1}>
              Share
            </Button>
          </XStack>
        </SafeAreaView>
      </View>
    </View>
  );
};

const ShareScreenQueryString = graphql`
  query ShareScreenQuery($id: UUID!) {
    profilesCollection(filter: { id: { eq: $id } }, first: 1) {
      edges {
        node {
          id
          name
          bio
          avatar_url
          breakfast_food
          ...ProfileCard_fragment
        }
      }
    }
  }
`;
