import { Text, XStack } from "tamagui";
import { Visibility as iVisibility } from "../__generated__/EventDetailQuery.graphql";
import { Globe2 } from "@tamagui/lucide-icons";
import { LinkIcon } from "../../../../components/design-system/icons";

export const EventVisibility = ({
  visibility,
}: {
  visibility: iVisibility;
}) => {
  return (
    <XStack gap="$2" alignItems="center">
      {visibility === "Public" ? (
        <Globe2 size={20} />
      ) : (
        <LinkIcon width={20} height={20} />
      )}
      <Text textTransform="uppercase">{visibility}</Text>
    </XStack>
  );
};
