/**
 * @generated SignedSource<<3560f051ef5a68a0cc15c9f0b35c9848>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type attendancesInsertInput = {
  created_at?: any | null | undefined;
  event_id?: any | null | undefined;
  pending?: boolean | null | undefined;
  reward_id?: any | null | undefined;
  user_id?: any | null | undefined;
};
export type useJoinRideMutation$variables = {
  input: attendancesInsertInput;
};
export type useJoinRideMutation$data = {
  readonly insertIntoattendancesCollection: {
    readonly records: ReadonlyArray<{
      readonly event_id: any | null | undefined;
      readonly events: {
        readonly id: any;
      } | null | undefined;
      readonly id: any;
      readonly user_id: any | null | undefined;
    }>;
  } | null | undefined;
};
export type useJoinRideMutation = {
  response: useJoinRideMutation$data;
  variables: useJoinRideMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "objects.0",
        "variableName": "input"
      }
    ],
    "kind": "ListValue",
    "name": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useJoinRideMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "attendancesInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoattendancesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "attendances",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "events",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useJoinRideMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "attendancesInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoattendancesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "attendances",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "events",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "05867b2c3fee22b384eae12491c687e4",
    "id": null,
    "metadata": {},
    "name": "useJoinRideMutation",
    "operationKind": "mutation",
    "text": "mutation useJoinRideMutation(\n  $input: attendancesInsertInput!\n) {\n  insertIntoattendancesCollection(objects: [$input]) {\n    records {\n      id\n      user_id\n      event_id\n      events {\n        id\n        nodeId\n      }\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "85aedaf34710973cff95537d4939a1a3";

export default node;
