import { G, Path, Svg, SvgProps } from "react-native-svg";

export const PeopleIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 96.83 89.27" {...props}>
      <G id="Layer_1-2">
        <Path d="m47.83.2c-1.41.41-2.42,3.2-1.77,4.9.49,1.29,3.14,2.44,7.64,3.32,4.1.8,5.73,2.71,5.73,6.71,0,3.21-.69,4.16-3.62,5.03-4.09,1.21-5.55,4.72-2.93,7.07,1.63,1.46,3.48,1.51,6.69.17,4.9-2.06,7.04-6.13,7.24-13.77.11-4.3-.13-5.43-1.6-7.58C62.37,1.9,59.36.3,53.9.06c-2.66-.12-5.4-.06-6.07.14h0Z" />
        <Path d="m23.23,6.46c-1.17.52-3.58,1.57-5.29,2.29-4.58,1.95-6.65,5.49-7.05,12.04-.28,4.5-.08,5.65,1.48,8.74,3.32,6.53,8.5,9.62,16.15,9.62,4.57,0,10.11-2.28,12.95-5.32,1.82-1.95,3.58-8.39,3.61-13.17.02-3.69-2.12-8.11-4.82-9.98-2.25-1.56-11.84-5.21-13.65-5.19-.66,0-2.22.45-3.38.97h0Zm6.86,9.18c7.1,2.01,8.02,3.51,6.25,10.21-.74,2.8-1.42,3.75-3.45,4.83-8.72,4.66-18.53-5.58-13.92-14.52,1.05-2.04,1.23-2.1,4.85-1.67,2.05.24,4.9.77,6.28,1.16h0Z" />
        <Path d="m57.86,34.21c-1.64.95-1.68,4.46-.07,5.77.87.71,3.89.96,12.01.98,15.08.04,15.77.5,17.56,11.85.4,2.53.73,6.41.73,8.53,0,6.89,1.39,12.85,3.28,14.1,3.95,2.6,6.67-1.13,4.9-6.73-.59-1.88-1.37-7.44-1.73-12.35-.36-4.91-1-10.17-1.43-11.67-1.08-3.74-4.67-7.61-8.91-9.58-3.28-1.53-4.61-1.68-14.37-1.65-6.01.02-11.29.35-11.98.75h0Z" />
        <Path d="m26.81,47.47c-10.03,1.32-13.67,2.27-18.5,4.8C1.98,55.6.44,58.06.05,65.5c-.33,6.35,1.01,20.43,2.16,22.59.83,1.57,3.03,1.57,4.6,0,1.1-1.1,1.16-2.25.62-10.92-.75-11.88-.64-15.61.51-16.99,2.89-3.48,14.91-5.99,26.99-5.63,12.35.37,20.07,1.16,20.89,2.15,1.24,1.49,4.09,14.47,4.86,22.11.77,7.64,1.62,9.28,4.83,9.28,4.51,0,4.99-2.66,2.96-16.31-2.39-15.97-4.08-20.48-8.76-23.34-2.2-1.35-3.65-1.52-14.09-1.71-6.62-.12-14.73.2-18.8.74h0Z" />
      </G>
    </Svg>
  );
};
