import { RouteProp, useRoute } from "@react-navigation/native";
import { ClubNavigatorParamList } from "../../../navigators/ClubNavigator";
import { useQueryLoader } from "react-relay";
import { PerkQuery } from "./__generated__/PerkQuery.graphql";
import { Perk, PerkQueryString } from "./Perk";
import { Suspense, useEffect } from "react";
import { Loader } from "../../../components/design-system/Loader";

export const PerkQueryRenderer = () => {
  const route = useRoute<RouteProp<ClubNavigatorParamList, "Perk">>();

  const [queryRef, loadQuery] = useQueryLoader<PerkQuery>(PerkQueryString);

  useEffect(() => {
    if (route.params.perkId) {
      loadQuery({ id: route.params.perkId });
    }
  }, [loadQuery, route.params.perkId]);

  return (
    <Suspense fallback={<Loader />}>
      {queryRef ? <Perk queryRef={queryRef} /> : null}
    </Suspense>
  );
};
