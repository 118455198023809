import { useState } from "react";
import { supabase } from "../../../../lib/supabase";

export const useSyncStravaRoutes = (callback: () => void) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const syncStravaRoutes = async () => {
    setIsSyncing(true);
    await supabase.functions.invoke("sync-strava-routes");
    setIsSyncing(false);
    callback();
  };

  return [syncStravaRoutes, isSyncing] as const;
};
