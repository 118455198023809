import { RouteProp, useRoute } from "@react-navigation/native";
import { EventShare } from "../../features/Events/EventShare";
import { BaseNavigatorParamList } from "../../navigators/BaseNavigator";

export const EventShareScreen = () => {
  const route = useRoute<RouteProp<BaseNavigatorParamList, "EventShare">>();

  const eventId = route.params.eventId;

  return <EventShare eventId={eventId} />;
};
