import { createClient } from "@sanity/client";

export const sanityClient = createClient({
  // Find your project ID and dataset in `sanity.json` in your studio project
  projectId: "b1h47391",
  dataset: "production",
  useCdn: true,
  apiVersion: "2021-03-25",
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
});
