import { RouteProp, useRoute } from "@react-navigation/native";
import { WalletNavigatorParamList } from "../../../navigators/WalletNavigator";
import { useQueryLoader } from "react-relay";
import { PartnerQuery } from "./__generated__/PartnerQuery.graphql";
import { Partner, PartnerQueryString } from "./Partner";
import { Suspense, useEffect } from "react";
import { Loader } from "../../../components/design-system/Loader";

export const PartnerQueryRenderer = () => {
  const route = useRoute<RouteProp<WalletNavigatorParamList, "Partner">>();

  const [queryRef, loadQuery] =
    useQueryLoader<PartnerQuery>(PartnerQueryString);

  useEffect(() => {
    loadQuery({ id: route.params.partnerId });
  }, [route.params.partnerId, loadQuery]);

  return (
    <Suspense fallback={<Loader />}>
      {queryRef ? <Partner queryRef={queryRef} /> : null}
    </Suspense>
  );
};
