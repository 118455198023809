import { G, Path, Svg, SvgProps } from "react-native-svg";

export const MapIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 96.8 107.04" {...props}>
      <G id="Layer_1-2">
        <Path d="m35.53,1.41c-3.15,1.38-16.04,5.5-24.19,7.73C2.33,11.62,2.7,10.78,2.7,28.71c0,8.64-.41,21.34-.92,28.22C.28,77.5-.44,97.88.28,99.96c.84,2.43,3.91,2.52,10.32.31,16.45-5.66,22.26-7.77,23.94-8.72,1.65-.92,2.3-.74,5.1,1.47,5.1,4.02,21.11,14.01,22.46,14.01,2.3,0,20.57-9.71,27.4-14.56l6.83-4.85.27-35.46c.35-46.32.29-50.78-.72-51.83-.52-.54-5.93,1.78-15.89,6.83l-15.1,7.65-3.25-2.1c-1.78-1.15-6.17-3.6-9.71-5.43-3.54-1.83-7.93-4.24-9.71-5.35C40.43.81,38.9-.04,38.78,0c-.11.04-1.6.69-3.25,1.41h0Zm55.37,31.41c-.5,13.41-.92,30.89-.95,38.75l-.04,14.32-3.66,2.26c-2,1.24-7.9,4.5-13,7.2l-9.34,4.94.53-14.56c.29-7.98.96-20.42,1.48-27.52.52-7.16,1.19-18.74,1.48-25.87l.53-12.92,11.64-5.8c6.42-3.2,11.77-5.67,11.93-5.51.16.16-.12,11.31-.62,24.72h0Zm-55.37-15.84c0,4.62-.37,14.69-.82,22.25-.45,7.57-1.05,18.9-1.32,25.05-.27,6.17-.73,13.51-1.03,16.29l-.53,5.06-4.85,2.15c-5.35,2.37-19.2,7.15-20.73,7.15-1.2,0-1.27-12.26-.14-25.5.44-5.21.91-18.39,1.05-29.33.14-10.94.47-20.79.74-21.93.43-1.8,2.02-2.52,12.55-5.76,6.66-2.05,12.73-3.74,13.57-3.78,1.35-.07,1.52.86,1.52,8.35h0Zm24.81,1.85c1.77,1.28,1.93,2.14,1.93,10.04,0,4.77-.37,11.92-.82,15.96-.45,4.02-1.27,17.65-1.81,30.24-.54,12.59-1.09,23.02-1.22,23.16-.38.4-10.13-5.38-14.99-8.88l-4.69-3.38.49-29.87c.27-16.45.96-33.84,1.52-38.71l1.03-8.84,8.31,4.44c4.61,2.46,9.17,5.07,10.24,5.84h0Z" />
      </G>
    </Svg>
  );
};
