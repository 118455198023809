import { G, Path, Svg, SvgProps } from "react-native-svg";

export const DGroupIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 254.12 266.25" {...props}>
      <G id="Layer_1-2">
        <Path
          fill={props.fill ?? "#e1b228"}
          d="m116.74.19c-5.73.96-8.48,1.47-10.37,1.94-1.17.29-4.51,1.01-7.39,1.59-7.67,1.55-7.82,1.59-12.48,3.26-7.97,2.85-18.99,7.81-22.93,10.31-.53.34-2.72,1.72-4.9,3.1-2.18,1.38-5.47,3.32-7.36,4.35-4.99,2.71-6.29,3.69-10.41,7.81-2.04,2.05-4.9,4.8-6.35,6.11-4.83,4.37-12.93,14.28-17.49,21.41C7.53,74.95,4.14,85.09.89,108.44c-.08.55-.21,2.26-.29,3.8-.08,1.55-.27,4.34-.41,6.25-.35,4.57-.19,15.61.29,20.78,1.93,20.6,3.27,26.83,9.61,44.86,3.15,8.97,7.83,21.46,8.97,23.96,3.94,8.64,10.16,17.74,17,24.85,1.22,1.27,2.64,2.79,3.17,3.41,4.06,4.71,11.2,11.27,16.37,15.05,5.44,3.98,7.32,4.89,13.21,6.42.95.25,3.59.95,5.86,1.57,8.7,2.35,10.2,2.72,13.73,3.3,4.91.81,10.95,1.58,15.19,1.93,1.1.09,3.3.37,4.94.62,4.44.69,11.07.99,22.21,1.01,9.32.02,10.12-.03,12.05-.67,3.25-1.09,5.11-1.45,8.3-1.63,1.98-.11,3.76-.44,5.42-1.02,3.87-1.34,10.4-3.82,13.27-5.03,3.43-1.45,6.69-2.56,9.53-3.27,6.76-1.68,12.37-4.43,19.08-9.33,1.12-.82,2.97-2.14,4.07-2.91,1.12-.78,2.6-1.85,3.33-2.39.71-.53,2.27-1.56,3.42-2.27,2.92-1.78,3.24-2.05,7.51-6.32,2.07-2.08,4.72-4.54,5.94-5.52,1.33-1.08,2.19-2.02,2.18-2.4-.02-1.34,1.28-4.07,2.97-6.23,1.83-2.34,6.97-9.74,9.03-13,3.96-6.27,8.68-15.91,9.22-18.81.13-.73.75-3.13,1.36-5.37.62-2.24,1.33-5.42,1.58-7.12.25-1.69,1.17-6.34,2.03-10.34,2.07-9.54,2.65-12.69,2.93-15.68.13-1.37.18-8.56.12-16-.06-7.44-.06-17.75,0-22.94.1-8.7-.14-13.14-.98-18.42-.28-1.72-1.43-6.88-1.84-8.2-.19-.63-.76-2.49-1.24-4.08-.49-1.61-1.79-5.52-2.9-8.7-1.11-3.19-2.11-6.22-2.22-6.75-1-4.57-1.33-5.45-3.01-8.06-.98-1.52-2.12-3.22-2.55-3.76-.42-.55-1.16-1.71-1.64-2.58-1.44-2.62-5.05-7.77-6.37-9.08-.69-.69-1.88-1.63-2.67-2.11-.78-.48-2.86-2.16-4.57-3.7-4.34-3.9-4.04-3.67-11.2-8.28-1.05-.67-2.57-1.55-3.39-1.96-.82-.41-1.78-.99-2.15-1.31-.93-.81-4.75-2.61-8.34-3.94-1.67-.62-4.21-1.77-5.66-2.56-5.13-2.82-8.91-4.64-10.05-4.83-2.26-.38-9.46-2.75-11.77-3.89-6.79-3.33-9.66-3.87-20.9-3.95-10.86-.07-15.79-.22-18.88-.56-3.49-.39-10.86-1.15-12.23-1.26-.65-.05-1.88.03-2.79.18h0Z"
        />
        <Path
          fill="black"
          d="m86.61,46.07c-2.95.57-4.5,1.06-4.93,1.58-.49.59-.81,8.57-.91,22.63-.08,11.12-.29,18.71-1.05,36.92-.37,8.82-.22,44.05.21,48.97.47,5.49.75,15.13.89,31.49.11,12.9.52,20.48,1.18,22.07.86,2.06.72,2.03,5.8,1.31,2.39-.34,3.41-.35,4.71-.05,2.6.6,9.51.48,13.97-.25,2.17-.35,4.3-.57,4.77-.5.46.08,2.64-.14,4.8-.49,2.84-.45,5.81-.64,10.52-.67,9.83-.06,13.96-.53,18.99-2.14,1.35-.43,3.45-1.04,4.61-1.33,1.18-.3,3.09-.98,4.29-1.54,1.19-.56,2.84-1.21,3.69-1.48.85-.26,3.83-1.58,6.66-2.95,5.02-2.43,5.17-2.53,7.74-5.34,3.48-3.8,3.76-4.17,5.88-7.91,3.21-5.65,8.05-16.26,8.77-19.21,1.38-5.64,2.4-12.56,2.7-18.27.11-2.17.44-5.81.72-8.07.9-7.22.74-15.31-.33-16.95-.27-.41-.58-1.63-.7-2.7-.35-3.39-1.18-10.67-1.83-16.14-.35-2.89-.73-6.62-.85-8.24-.14-1.88-.51-3.75-1.02-5.1-.44-1.16-1.19-3.26-1.65-4.61-1.51-4.4-4.56-9.96-6.97-12.71-1.21-1.38-2.83-3.27-3.61-4.21-4.53-5.4-12.74-12.75-14.24-12.75-.35,0-1.19-.32-1.88-.71-1.14-.65-6.28-2.22-8.98-2.74-2.06-.4-5.05-1.4-8.41-2.8-4.61-1.93-5.72-2.24-9.23-2.6-2.07-.21-3.87-.64-5.49-1.3-2.52-1.03-6.62-1.52-11.33-1.34-3.77.14-19.79.03-22.41-.16-1.66-.12-3.53-.02-5.05.27h0Zm38.68,37.78c2.31.68,3.17,1.06,6.94,3.07,6.97,3.72,8.74,5.13,10.3,8.25,2.57,5.12,4.35,11.46,5.03,17.83.68,6.41,1.41,20.88,1.44,28.47.03,7.06-.04,8.02-.72,10.71-1.61,6.31-4.46,13.19-6.86,16.54-5.28,7.41-10.6,9.93-19.42,9.2l-2.7-.22-.28-3.36c-.16-1.84-.25-8.26-.21-14.22.17-25.02.12-70.13-.08-73.24-.19-3.08-.16-3.37.38-3.51,1.42-.37,4.02-.17,6.18.47h0Z"
        />
      </G>
    </Svg>
  );
};
