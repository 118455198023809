import { KeyboardAvoidingView } from "react-native";
import { Heading, View, YStack } from "tamagui";
import { Logo } from "../../components/Logo";
import { Button, Input } from "../../components/design-system";
import { useState } from "react";
import { supabase } from "../../../lib/supabase";
import { SafeAreaView } from "react-native-safe-area-context";
import { currentUserAtom, currentUserProfileAtom } from "../../../atoms/global";
import { useAtom, useAtomValue } from "jotai";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { BaseNavigatorParamList } from "../../navigators/BaseNavigator";
import { customColorTokens } from "../../../tamagui.config";
import { iProfile } from "../../features/Profile";

export const Name = () => {
  const [name, setName] = useState("");

  const [profileState, setProfileState] = useAtom(currentUserProfileAtom);

  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();

  const user = useAtomValue(currentUserAtom);

  const saveName = async () => {
    const profile: iProfile = { ...profileState, name, id: user?.id };
    setProfileState(profile);

    await supabase.from("profiles").upsert(profile);

    navigation.navigate("Onboarding", {
      screen: "BreakfastFood",
    });
  };

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: customColorTokens.brandParchment }}
    >
      <YStack
        justifyContent="space-between"
        flex={1}
        alignItems="flex-start"
        p="$4"
        w="100%"
        space={10}
      >
        <View w="100%" alignItems="center" justifyContent="center">
          <Logo />
          <Heading w="100%" size={"$2"} mt={32} textTransform="uppercase">
            {`WHAT'S YOUR FULL NAME?`}
          </Heading>
        </View>

        <KeyboardAvoidingView
          behavior="padding"
          enabled
          keyboardVerticalOffset={80}
          style={{ width: "100%", flex: 1 }}
        >
          <YStack w="100%" flex={1} justifyContent="space-between">
            <View justifyContent="flex-start" flex={1}>
              <Input
                onChangeText={(text) => setName(text)}
                placeholder="Enter your full name"
                autoFocus
              />
            </View>
            <Button w="100%" disabled={name.length === 0} onPress={saveName}>
              CONTINUE
            </Button>
          </YStack>
        </KeyboardAvoidingView>
      </YStack>
    </SafeAreaView>
  );
};
