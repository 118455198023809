import { supabase } from "../../lib/supabase";
import { useState } from "react";
import { Friendship } from "../components/FriendShipButton/FriendshipButton";

export const useAcceptFriendRequest = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptFriendRequest = async (
    friendshipId: string,
    callback: (friendship: Friendship | null) => void
  ) => {
    setIsLoading(true);

    await supabase
      .from("friendships")
      .update({ status: "accepted" })
      .eq("id", friendshipId)
      .select("*")
      .single()
      .then((res) => {
        callback(res.data);
        setIsLoading(false);
      });
  };

  return [handleAcceptFriendRequest, isLoading] as const;
};
