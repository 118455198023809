import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { ScrollView, Text, View, XStack } from "tamagui";
import { RidesNavigatorParamList } from "../../../navigators/CreateARideNavigator";
import { CloseIcon } from "../../../components/design-system/icons";
import ViewShot from "react-native-view-shot";
import { EventShare } from "../../../features/Events/EventShare";
import * as Sharing from "expo-sharing";
import { useRef } from "react";
import { Linking, Platform } from "react-native";
import { Button } from "../../../components/design-system";
import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import { customColorTokens } from "../../../../tamagui.config";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import * as Clipboard from "expo-clipboard";
import * as Burnt from "burnt";

export const Share = () => {
  const route = useRoute<RouteProp<RidesNavigatorParamList, "Share">>();
  const navigation = useNavigation();

  const eventId = route.params.eventId;

  const eventUrl = `https://app.breakfastclubatx.com/rides/${eventId}}`;

  const imageRef = useRef<ViewShot>(null);

  const onSaveImageAsync = async () => {
    await copyUrl();
    try {
      if (Platform.OS === "web") {
        Linking.openURL(eventUrl);
      } else {
        imageRef?.current?.capture?.().then(async (uri) => {
          await Sharing.shareAsync(uri, {
            dialogTitle: "Share Event",
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { bottom } = useSafeAreaInsets();

  const copyUrl = async () => {
    if (Platform.OS === "web") {
      navigator.clipboard.writeText(eventUrl);
    } else {
      await Clipboard.setUrlAsync(eventUrl);
    }
    Burnt.toast({
      title: "Link copied to clipboard",
      haptic: "success",
    });
  };

  return (
    <View flex={1} pos="relative">
      <ScrollView backgroundColor="$brandParchment">
        <SafeAreaView
          style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
        >
          <View
            px="$4"
            pb="$4"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <XStack justifyContent="space-between" w="100%" alignItems="center">
              <View>
                <HeaderButton
                  onPress={() => {
                    const p = navigation.getParent();
                    if (p) {
                      p.goBack();
                    } else {
                      navigation.goBack();
                    }
                  }}
                >
                  <CloseIcon color="black" width={16} height={16} />
                </HeaderButton>
              </View>
              <View justifyContent="flex-end" alignItems="flex-end">
                <Text fontSize={10}>Rides by Breakfast Club</Text>
                <Text fontFamily="$italicHeadingFont" fontSize={16}>
                  Share Event
                </Text>
              </View>
            </XStack>
          </View>
          <View flex={1}>
            <ViewShot
              ref={imageRef}
              options={{ fileName: "member-card", format: "png", quality: 1 }}
              style={{
                width: "100%",
                position: "relative",
                transform: [{ scale: 0.9 }],
                marginTop: -32,
                flex: 1,
                height: "100%",
              }}
            >
              <EventShare eventId={route.params.eventId} />
            </ViewShot>
          </View>
        </SafeAreaView>
      </ScrollView>
      <View
        pos="absolute"
        bottom={0}
        paddingBottom={bottom}
        left={0}
        right={0}
        bg="$brandParchment"
        px="$4"
        pt="$2"
        borderTopWidth={1}
        flexDirection="row"
        gap="$2"
      >
        <Button fontSize={16} onPress={copyUrl} flex={1} w="100%">
          Copy Url
        </Button>
        <Button fontSize={16} onPress={onSaveImageAsync} flex={1} w="100%">
          Share
        </Button>
      </View>
    </View>
  );
};
