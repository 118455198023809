import { Suspense, useEffect } from "react";
import { Profile, ProfileQueryString } from "./Profile";
import { Loader } from "../../../components/design-system/Loader";
import { useQueryLoader } from "react-relay";
import { ProfileQuery } from "./__generated__/ProfileQuery.graphql";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";

export const ProfileQueryRenderer = () => {
  const user = useAtomValue(currentUserAtom);

  const [queryRef, loadQuery] =
    useQueryLoader<ProfileQuery>(ProfileQueryString);

  useEffect(() => {
    if (user?.id) {
      loadQuery({ id: user.id });
    }
  }, [loadQuery, user?.id]);

  return (
    <Suspense fallback={<Loader />}>
      {queryRef ? <Profile queryRef={queryRef} /> : null}
    </Suspense>
  );
};
