import { View } from "tamagui";
import { BcAppIcon, BcIcon } from "./design-system/icons";
import { ProfileCard } from "./ProfileCard";
import { ProfileCard_fragment$key } from "./__generated__/ProfileCard_fragment.graphql";

type ShareProfileProps = {
  fragmentRef: ProfileCard_fragment$key;
};

export const ShareProfile = ({ fragmentRef }: ShareProfileProps) => {
  return (
    <View
      pos="relative"
      justifyContent="center"
      alignItems="center"
      gap={20}
      py={20}
      overflow="scroll"
      w="100%"
    >
      <BcAppIcon width={225} height={55} />
      <View
        bg="$brandParchment"
        borderWidth={1}
        borderRadius={20}
        {...{
          shadowColor: "black",
          shadowOffset: { width: 2, height: 2 },
          shadowOpacity: 0.9,
          shadowRadius: 0,
        }}
        minHeight={450}
        w={330}
      >
        <ProfileCard fragmentRef={fragmentRef} />
      </View>
      <BcIcon width={44} height={44} />
    </View>
  );
};
