import { XStack } from "tamagui";
import { Pill } from "../../../components/design-system/Pill";
import { Pressable } from "react-native";
import { CloseIcon } from "../../../components/design-system/icons";
import { useAtom } from "jotai";
import { paceFilterAtom, terrainFilterAtom } from "../atoms";

export const terrainOptions = ["Road", "Gravel", "Mixed", "MTB"];
export const paceOptions = ["A", "B", "C", "D", "Party"];

export const Filters = () => {
  const [terrainFilter, setTerrainFilter] = useAtom(terrainFilterAtom);
  const [paceFilter, setPaceFilter] = useAtom(paceFilterAtom);

  const handleTerrainPress = (terrain: string) => {
    setTerrainFilter(terrainFilter === terrain ? null : terrain);
  };

  const handlePacePress = (pace: string) => {
    setPaceFilter(paceFilter === pace ? null : pace);
  };

  return (
    <XStack
      gap="$2"
      bg="$brandParchment"
      pt="$4"
      pb="$2"
      px="$4"
      flexWrap="wrap"
    >
      {terrainOptions.map((t) => (
        <Pressable onPress={() => handleTerrainPress(t)} key={t}>
          <Pill
            icon={
              terrainFilter === t ? (
                <CloseIcon fill="black" color="black" width={12} height={12} />
              ) : null
            }
            text={t}
            bg={terrainFilter === t ? "$brandYellow" : "$brandParchment"}
            color={"black"}
            borderColor={terrainFilter === t ? "black" : "black"}
          />
        </Pressable>
      ))}
      {paceOptions.map((p) => (
        <Pressable onPress={() => handlePacePress(p)} key={p}>
          <Pill
            icon={
              paceFilter === p ? (
                <CloseIcon fill="black" color="black" width={12} height={12} />
              ) : null
            }
            text={p}
            bg={paceFilter === p ? "$brandYellow" : "$brandParchment"}
            color={"black"}
            borderColor={paceFilter === p ? "black" : "black"}
          />
        </Pressable>
      ))}
    </XStack>
  );
};
