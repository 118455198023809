import { XStack, Text, YStack, Image, View } from "tamagui";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { BaseNavigatorParamList } from "../../../navigators/BaseNavigator";
import { format, parse } from "date-fns";
import mixpanel from "../../../../mixpanel";
import * as Haptics from "expo-haptics";

import { useGetGroupIcon } from "../../../features/Events/hooks/useGetEventGroupIcons";
import { Platform } from "react-native";
import Avatar from "../../Avatar";
import { Role } from "../../../features/Profile/types";
import { Pill } from "../Pill";
import { getFeet, getMiles } from "../../../features/Routes/utils";
import { EventImage } from "../../../screens/Events/components/EventImage";
import { Event } from "../../../features/Events/types";
import { brandShadowSm } from "../../../../tamagui.config";

type EventCardProps = {
  disableLink?: boolean;
  event: Event;
};

export const EventCard = ({ disableLink = false, event }: EventCardProps) => {
  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();

  const dateObj = event.date && parse(event.date, "yyyy-MM-dd", new Date());

  const timeObj = event.time && parse(event.time, "HH:mm:ss", new Date());

  const formatedTime = timeObj && format(timeObj, "hh:mm a");

  const formattedDate = dateObj && format(dateObj, "MM-dd-yyyy");
  const host = event.profiles;
  const role = host ? host.user_rolesCollection?.edges[0]?.node.role : null;

  const handlePress = () => {
    if (disableLink) return;
    mixpanel.track("Event Card Pressed", { eventId: event.id });
    navigation.navigate("EventDetail", { eventId: event.id });
    if (Platform.OS !== "web") Haptics.selectionAsync();
  };

  const groupIcons = event.groups?.map((g, i) => {
    const Icon = g ? useGetGroupIcon({ group: g }) : null;
    return Icon ? (
      <View
        {...brandShadowSm}
        borderWidth={1}
        borderRadius={999}
        bg="black"
        key={i}
      >
        <Icon width={32} height={32} key={i} />
      </View>
    ) : null;
  });

  const route = event.route;
  const isClubRide = event.type === "ClubRide";

  return (
    <YStack
      borderWidth={1}
      borderColor="black"
      borderRadius={15}
      bg={"white"}
      pos="relative"
      pressStyle={{
        scaleX: 0.99,
        scaleY: 0.99,
        shadowOffset: { height: 1, width: 1 }, // Reduced shadow offset for pressed effect
        shadowOpacity: 0.7, // Lower opacity for a softer shadow when pressed
        // Keep the shadow radius unchanged or adjust slightly if needed
      }}
      {...{
        shadowColor: "black",
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 1,
        shadowRadius: 0,
      }}
      onPress={handlePress}
    >
      {event.type === "ClubRide" && route ? (
        <YStack overflow="scroll" borderBottomWidth={1}>
          <Image
            source={{ uri: route.image_url ?? "" }}
            w="100%"
            h={200}
            resizeMode="cover"
            borderTopRightRadius={15}
            borderTopLeftRadius={15}
          />
        </YStack>
      ) : null}
      <XStack
        alignItems="flex-start"
        justifyContent="space-between"
        px="$4"
        pt={route ? "$4" : "$6"}
        gap="$2"
      >
        <YStack
          flex={1}
          gap="$2"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <YStack alignItems="flex-start" justifyContent="flex-start" gap="$2">
            <YStack gap="$2">
              <Text
                fontFamily="$italicHeadingFont"
                fontSize={20}
                flexWrap="wrap"
              >
                {event.name}
              </Text>
              <Text fontSize={16}>
                {formattedDate} • {formatedTime ? formatedTime : "TBD"}
              </Text>

              <XStack gap={2} alignItems="center">
                <Text fontSize={14} fontStyle="italic" fontWeight="600">
                  {event.location ? event.location : "Location: TBD"}
                </Text>
              </XStack>
            </YStack>
          </YStack>
          <XStack
            gap={1}
            mt={0}
            rowGap={4}
            columnGap={4}
            flexWrap="wrap"
            pb={"$6"}
          >
            {isClubRide ? (
              <>
                <Pill
                  bg="$brandYellow"
                  color="black"
                  borderColor="black"
                  text={event.groups?.[0] ?? ""}
                />
                {event.terrain ? (
                  <Pill
                    bg="$brandYellow"
                    color="black"
                    borderColor="black"
                    text={event.terrain}
                  />
                ) : null}

                <Pill
                  bg="$brandYellow"
                  color="black"
                  borderColor="black"
                  text={`${getMiles(route?.distance ?? 0) ?? ""} mi`}
                />
                <Pill
                  bg="$brandYellow"
                  color="black"
                  borderColor="black"
                  text={`${getFeet(route?.elevation ?? 0)} ft`}
                />
              </>
            ) : (
              groupIcons
            )}
          </XStack>
        </YStack>

        {event.type === "ClubRide" && host ? (
          <YStack justifyContent="center" alignItems="center">
            <Avatar
              imageStyle={{
                borderRadius: 999,
              }}
              size={75}
              url={host.avatar_url ?? null}
              role={role as Role}
            />
            <Text fontSize={14} mt={4}>
              {host.name}
            </Text>
          </YStack>
        ) : (
          <EventImage width={90} height={90} type={event.type ?? "PopUp"} />
        )}
      </XStack>
    </YStack>
  );
};
