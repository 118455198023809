import { graphql } from "relay-hooks";
import { EventDetailActions_event$key } from "./__generated__/EventDetailActions_event.graphql";
import { useFragment } from "react-relay";
import { isBefore, parse } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../../atoms/global";
import { View, Button } from "tamagui";
import { useJoinRide } from "../hooks/useJoinRide";
import { useLeaveRide } from "../hooks/useLeaveRide";
import { EventDetailConfirmationModal } from "./EventDetailConfirmationModal";
import { brandShadow } from "../../../../../tamagui.config";
import { Event } from "../../../../hooks/useGetEvent";

export const EventDetailActions = ({
  event,
  refreshQuery,
  copyUrl,
}: {
  event: Event;
  copyUrl: () => void;
  refreshQuery: () => void;
}) => {
  const dateTimeString = `${event.date}T${event.time ?? "00:00:00"}`;

  // Parse the combined string
  const parsedDateTime = parse(
    dateTimeString,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  );

  const now = new Date();

  // Check if the parsed date is before now
  const isEventInThePast = isBefore(parsedDateTime, now);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const currentUser = useAtomValue(currentUserAtom);

  const currentUserAttendance = useMemo(
    () => event?.attendees?.find((a) => a.user_id === currentUser?.id),
    [event?.attendees, currentUser?.id]
  );
  const [isUserAttending, setIsUserAttending] = useState(
    !!currentUserAttendance
  );

  useEffect(() => {
    setIsUserAttending(!!currentUserAttendance);
  }, [setIsUserAttending, currentUserAttendance]);

  const [handleJoinRide, isJoiningRide] = useJoinRide(() => {
    setIsUserAttending(true);
    refreshQuery();
    setIsConfirmationModalOpen(true);
  });

  const onJoinRidePress = () => {
    handleJoinRide({
      user_id: currentUser?.id,
      event_id: event?.id,
    });
  };

  const [handleLeaveRide, isLeavingRide] = useLeaveRide(() => {
    setIsUserAttending(false);
    refreshQuery();
  });

  const onLeaveRidePress = () => {
    if (currentUserAttendance?.id) {
      handleLeaveRide({
        id: { eq: parseInt(currentUserAttendance.id) },
      });
    }
  };

  const isDisabled = isJoiningRide || isLeavingRide || isEventInThePast;

  return (
    <>
      <View
        bg="$brandParchment"
        borderTopWidth={1}
        p="$4"
        pb="$0"
        position="absolute"
        bottom={0}
        w={"100%"}
        paddingBottom={20}
      >
        {isUserAttending ? (
          <Button
            opacity={isDisabled ? 0.5 : 1}
            borderWidth={1}
            fontSize={16}
            fontFamily="$heading"
            borderColor={"black"}
            disabled={isDisabled}
            bg="red"
            color="white"
            onPress={onLeaveRidePress}
            pressStyle={{
              shadowColor: "$colorTransparent",
              shadowOffset: { width: 0, height: 0 },
              borderWidth: 1,
              borderColor: "black",
              scale: 0.98,
            }}
            {...brandShadow}
            // variant="danger"
          >
            LEAVE
          </Button>
        ) : (
          <Button
            disabled={isDisabled}
            opacity={isDisabled ? 0.5 : 1}
            onPress={onJoinRidePress}
            bg="$brandYellow"
            pressStyle={{
              bg: "$brandYellow",
              shadowColor: "$colorTransparent",
              shadowOffset: { width: 0, height: 0 },
              borderWidth: 1,
              borderColor: "black",
              scale: 0.98,
            }}
            borderWidth={1}
            fontSize={16}
            fontFamily="$heading"
            borderColor={"black"}
            {...brandShadow}
          >
            JOIN
          </Button>
        )}
      </View>
      <EventDetailConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        event={event}
        copyUrl={copyUrl}
      />
    </>
  );
};
