import { Modal } from "react-native";
import { rideAtom } from "../atoms";
import { useAtom } from "jotai";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Text, View, XStack } from "tamagui";
import { HeaderButton } from "../../design-system/Button/HeaderButton";
import { ArrowLeftIcon } from "../../design-system/icons";
import { Suspense, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { useQueryLoader } from "react-relay";
import { RouteListQuery } from "./__generated__/RouteListQuery.graphql";
import { RouteListQueryString, RoutesList } from "./RouteList";
import { useSyncStravaRoutes } from "../../../features/Strava/hooks/useSyncStravaRoutes";
import { Input } from "../../design-system";
import { Loader } from "../../design-system/Loader";
import { RefreshCcw } from "@tamagui/lucide-icons";

type RouteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
};

export const RouteModal = ({ isOpen, onClose, userId }: RouteModalProps) => {
  const [ride, setRide] = useAtom(rideAtom);

  const [searchTerm, setQuery] = useState("");

  const setDebounceSearchTerm = debounce(setQuery, 500);

  const [queryRef, loadQuery] =
    useQueryLoader<RouteListQuery>(RouteListQueryString);

  const iLikeReadySearchTerm = useMemo(() => `%${searchTerm}%`, [searchTerm]);

  useEffect(() => {
    if (userId && iLikeReadySearchTerm) {
      loadQuery({ profileId: userId, searchTerm: iLikeReadySearchTerm });
    }
  }, [userId, loadQuery, iLikeReadySearchTerm]);

  const refreshQuery = () => {
    if (userId) {
      loadQuery(
        { profileId: userId, searchTerm: iLikeReadySearchTerm },
        { fetchPolicy: "network-only" }
      );
    }
  };

  const [syncStravaRoutes, isSyncing] = useSyncStravaRoutes(refreshQuery);

  const { top } = useSafeAreaInsets();
  return (
    <Modal
      visible={isOpen}
      animationType="fade"
      transparent={false}
      onRequestClose={onClose}
    >
      <View flex={1} paddingTop={top} bg="$brandParchment">
        <View
          px="$4"
          pb="$4"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <XStack
            justifyContent="space-between"
            w="100%"
            mb="$5"
            alignItems="flex-start"
          >
            <HeaderButton onPress={() => onClose()}>
              <ArrowLeftIcon color="black" width={16} height={16} />
            </HeaderButton>
            <View justifyContent="flex-end" alignItems="flex-end" gap={4}>
              <HeaderButton onPress={() => syncStravaRoutes()}>
                <RefreshCcw color="black" width={16} height={16} />
              </HeaderButton>
            </View>
          </XStack>
        </View>

        <Text
          fontSize={16}
          fontFamily="$heading"
          textTransform="uppercase"
          pl="$4"
        >
          Select a Route
        </Text>

        <View p="$4">
          <Input
            placeholder="Search Routes"
            onChangeText={setDebounceSearchTerm}
          />
        </View>
        <Suspense fallback={<Loader />}>
          {queryRef ? (
            <RoutesList
              isSyncing={isSyncing}
              queryRef={queryRef}
              onRefresh={refreshQuery}
              onRoutePress={(route) => {
                setRide({
                  ...ride,
                  route: {
                    name: route.node.name ?? "",
                    id: route.node.id,
                  },
                });
                onClose();
              }}
            />
          ) : null}
        </Suspense>
      </View>
    </Modal>
  );
};
