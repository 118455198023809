import { Modal } from "react-native";
import { rideAtom } from "../atoms";
import { useAtom } from "jotai";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  AnimatePresence,
  Button,
  Label,
  RadioGroup,
  Text,
  View,
  XStack,
  YStack,
} from "tamagui";
import { HeaderButton } from "../../design-system/Button/HeaderButton";
import { ArrowLeftIcon, LinkIcon } from "../../design-system/icons";

import { useGetFriendsList } from "../../../hooks/useGetFriendsList";
import Avatar from "../../Avatar";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { brandShadow, customColorTokens } from "../../../../tamagui.config";

type InvitesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  userId?: string;
};

export const InvitesModal = ({
  isOpen,
  onClose,
  userId,
}: InvitesModalProps) => {
  const [ride, setRide] = useAtom(rideAtom);

  const { friends } = useGetFriendsList(userId);

  const toggleFriendChecked = (
    friend: { id: string; name: string },
    checked: boolean
  ) => {
    const newInvites = checked
      ? [...(ride?.invited_users ?? []), friend]
      : ride?.invited_users?.filter((i) => i.id !== friend.id);

    setRide({
      ...ride,
      invited_users: newInvites,
    });
  };

  const { top, bottom } = useSafeAreaInsets();
  return (
    <Modal
      visible={isOpen}
      animationType="fade"
      transparent={false}
      onRequestClose={onClose}
    >
      <View flex={1} paddingTop={top} bg="$brandParchment">
        <View
          px="$4"
          pb="$4"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <XStack
            justifyContent="space-between"
            w="100%"
            mb="$5"
            alignItems="flex-start"
          >
            <View>
              <HeaderButton onPress={() => onClose()}>
                <ArrowLeftIcon color="black" width={16} height={16} />
              </HeaderButton>
            </View>
          </XStack>
        </View>
        <View px="$4" flex={1}>
          <Text
            fontSize={16}
            fontFamily="$heading"
            textTransform="uppercase"
            mb="$4"
          >
            Who do you want to invite?
          </Text>

          {friends.map((f) => (
            <YStack key={f.id}>
              <XStack
                alignItems="center"
                justifyContent="space-between"
                w="100%"
              >
                <YStack flexGrow={1}>
                  <XStack alignItems="center" gap={"$2"}>
                    <Avatar
                      size={32}
                      url={f.friend.avatar_url ?? null}
                      imageStyle={{
                        borderRadius: 20,
                      }}
                    />
                    <Label fontSize={20}>{f.friend.name}</Label>
                  </XStack>
                </YStack>
                <View justifyContent="flex-end" alignItems="flex-end">
                  <BouncyCheckbox
                    size={32}
                    fillColor={customColorTokens.brandYellow}
                    unFillColor={customColorTokens.brandParchment}
                    iconStyle={{ borderColor: "red" }}
                    innerIconStyle={{
                      borderWidth: 1,
                      borderColor: "black",
                    }}
                    isChecked={ride?.invited_users?.some(
                      (u) => u.id === f.friend.id
                    )}
                    onPress={(isChecked: boolean) =>
                      toggleFriendChecked(
                        {
                          id: f.friend.id ?? "",
                          name: f.friend.name ?? "",
                        },
                        isChecked
                      )
                    }
                  />
                </View>
              </XStack>
            </YStack>
          ))}

          {ride?.invited_users && ride.invited_users.length > 0 ? (
            <View
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              paddingBottom={bottom}
            >
              <AnimatePresence>
                <Button
                  onPress={onClose}
                  animation="subtle"
                  enterStyle={{
                    opacity: 0,
                    scale: 0.95,
                    y: 5,
                  }}
                  exitStyle={{
                    opacity: 0,
                    scale: 0.95,
                    y: 5,
                  }}
                  bg="$brandYellow"
                  pressStyle={{
                    bg: "$brandYellow",
                    shadowColor: "$colorTransparent",
                    shadowOffset: { width: 0, height: 0 },
                    borderWidth: 1,
                    borderColor: "black",
                    scale: 0.98,
                  }}
                  borderWidth={1}
                  fontSize={16}
                  fontFamily="$heading"
                  borderColor={"black"}
                  w="100%"
                  {...brandShadow}
                >
                  CONTINUE
                </Button>
              </AnimatePresence>
            </View>
          ) : null}
        </View>
      </View>
    </Modal>
  );
};
