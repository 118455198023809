const originalWarn = console.warn;
console.warn = (message, ...args) => {
  if (message.startsWith("@supabase/gotrue-js")) return;
  originalWarn(message, ...args);
};

import "react-native-url-polyfill/auto";
import { createClient } from "@supabase/supabase-js";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const SUPABASE_URL = "https://aifqfukkewqdyfrcofdf.supabase.co";
export const SUPABASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFpZnFmdWtrZXdxZHlmcmNvZmRmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE2MTIwODQsImV4cCI6MjAwNzE4ODA4NH0._MjtzAfnG_d4W6FbwvloZm1qvvUbPocq18vOBbghwcI";

export const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY, {
  auth: {
    storage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
});
