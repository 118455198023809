import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useMemo } from "react";
import { Heading, Text, View } from "tamagui";
import { ClubNavigatorParamList } from "../../../navigators/ClubNavigator";
import { supabase } from "../../../../lib/supabase";
import { Loader } from "../../../components/design-system/Loader";
import QRCode from "react-native-qrcode-svg";
import { Image } from "react-native";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import { CloseIcon } from "../../../components/design-system/icons";
import { graphql } from "relay-hooks";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { PerkQuery } from "./__generated__/PerkQuery.graphql";

type PerkProps = {
  queryRef: PreloadedQuery<PerkQuery>;
};

export const Perk = ({ queryRef }: PerkProps) => {
  const data = usePreloadedQuery<PerkQuery>(PerkQueryString, queryRef);

  const perk = data.perksCollection?.edges[0]?.node;

  const partner = perk?.partners ?? null;

  const partnerLogo = useMemo(
    () =>
      partner?.image_url
        ? supabase.storage.from("partners").getPublicUrl(partner.image_url)
            ?.data?.publicUrl
        : null,
    [partner?.image_url]
  );

  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  return (
    <View
      bg="$brandParchment"
      flex={1}
      p="$4"
      pt="$8"
      alignItems="center"
      gap="$4"
    >
      <View w="100%">
        <HeaderButton
          onPress={() =>
            navigation.navigate("Partner", {
              partnerId: perk?.partners?.id ?? "",
            })
          }
        >
          <CloseIcon width={16} height={16} />
        </HeaderButton>
      </View>
      {partnerLogo ? (
        <Image
          style={{ objectFit: "contain", width: "100%", minHeight: 50 }}
          source={{ uri: partnerLogo }}
          resizeMode="contain"
        />
      ) : partnerLogo ? (
        <Loader />
      ) : (
        <Heading
          $platform-native={{
            fontSize: "$3",
          }}
          $platform-web={{
            fontSize: 24,
          }}
          textTransform="uppercase"
        >
          {partner?.name}
        </Heading>
      )}
      <View mt="$5" justifyContent="center" alignItems="center">
        <Text
          $platform-native={{
            fontSize: "$3",
          }}
          $platform-web={{
            fontSize: 24,
          }}
          fontFamily="$heading"
          textTransform="uppercase"
          textAlign="center"
        >
          {perk?.name}
        </Text>
        <Text mt="$2" textAlign="center" fontSize={16}>
          {perk?.description}
        </Text>
      </View>
      {perk?.code ? (
        <View mt="$5" justifyContent="center" alignItems="center">
          <QRCode value={perk.code} size={200} />
        </View>
      ) : null}
    </View>
  );
};

export const PerkQueryString = graphql`
  query PerkQuery($id: BigInt!) {
    perksCollection(filter: { id: { eq: $id } }, first: 1) {
      edges {
        node {
          id
          name
          description
          code
          partners {
            id
            name
            image_url
          }
        }
      }
    }
  }
`;
