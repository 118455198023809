import { Text, View, XStack } from "tamagui";
import {
  NavigationProp,
  StackActions,
  useNavigation,
} from "@react-navigation/native";
import { CloseIcon } from "../../../../components/design-system/icons";

import { useAtomValue } from "jotai";

import { Loader } from "../../../../components/design-system/Loader";

import { currentUserAtom } from "../../../../../atoms/global";

import { useState } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { HeaderButton } from "../../../../components/design-system/Button/HeaderButton";
import { BaseNavigatorParamList } from "../../../../navigators/BaseNavigator";

import { supabase } from "../../../../../lib/supabase";
import { RideForm } from "../../../../components/RideForm/RideForm";
import { Ride } from "../../../../components/RideForm/types";

export const CreateRideForm = () => {
  const currentUser = useAtomValue(currentUserAtom);

  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();

  const [loading, setLoading] = useState(false);

  const onSubmit = (ride: Ride) => {
    setLoading(true);

    supabase
      .rpc("create_event_with_invites", {
        p_name: ride.name,
        p_description: ride.description,
        p_image_url: null,
        p_type: "ClubRide", // One of your EventType values
        p_date: ride.date,
        p_time: ride.time,
        p_location: ride?.location ?? "",
        p_groups: ride.groups,
        p_host_id: currentUser?.id ?? "",
        p_terrain: ride.terrain, // One of your Terrains values
        p_route_id: ride?.route?.id,
        p_visibility: ride?.visibility, // One of your Visibility values
        p_recipient_ids: ride?.invited_users?.map((i) => i.id) ?? [],
      })
      .then(({ data, error }) => {
        if (data?.[0]?.event_id) {
          navigation.dispatch(StackActions.replace("Home"));

          setTimeout(() => {
            navigation.navigate("EventDetail", { eventId: data[0]?.event_id });
          }, 200);
        } else {
          console.log(error);
        }
        setLoading(false);
      });
  };

  const { top } = useSafeAreaInsets();

  return (
    <View flex={1} bg="$brandParchment" paddingTop={top}>
      <View px="$4" pb="$4" justifyContent="flex-start" alignItems="flex-start">
        <XStack justifyContent="space-between" w="100%" alignItems="center">
          <View>
            <HeaderButton onPress={() => navigation.goBack()}>
              <CloseIcon color="black" width={16} height={16} />
            </HeaderButton>
          </View>
          <View justifyContent="flex-end" alignItems="flex-end">
            <Text fontSize={16}>Rides by Breakfast Club</Text>
          </View>
        </XStack>
      </View>
      {loading ? <Loader /> : null}
      <RideForm currentUserId={currentUser?.id ?? ""} handleSubmit={onSubmit} />
    </View>
  );
};
