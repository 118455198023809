/**
 * @generated SignedSource<<5ef8010c582790becf9e3c05d8ad5abd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RouteListQuery$variables = {
  profileId: any;
  searchTerm?: string | null | undefined;
};
export type RouteListQuery$data = {
  readonly routesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly distance: number | null | undefined;
        readonly elevation: number | null | undefined;
        readonly id: any;
        readonly image_url: string | null | undefined;
        readonly name: string | null | undefined;
        readonly url: string | null | undefined;
      };
    }>;
  } | null | undefined;
};
export type RouteListQuery = {
  response: RouteListQuery$data;
  variables: RouteListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profileId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "ilike",
            "variableName": "searchTerm"
          }
        ],
        "kind": "ObjectValue",
        "name": "name"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "profileId"
          }
        ],
        "kind": "ObjectValue",
        "name": "profile_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "strava_created_at": "DescNullsLast"
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "elevation",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "distance",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "routesConnection",
        "kind": "LinkedField",
        "name": "routesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "routesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "routes",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RouteListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "routesConnection",
        "kind": "LinkedField",
        "name": "routesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "routesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "routes",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nodeId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40ffa2f7afcfa696ab3e26fac834fafb",
    "id": null,
    "metadata": {},
    "name": "RouteListQuery",
    "operationKind": "query",
    "text": "query RouteListQuery(\n  $profileId: UUID!\n  $searchTerm: String\n) {\n  routesCollection(filter: {profile_id: {eq: $profileId}, name: {ilike: $searchTerm}}, orderBy: {strava_created_at: DescNullsLast}) {\n    edges {\n      node {\n        id\n        name\n        elevation\n        image_url\n        distance\n        url\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e240cc6a455c0867dc7be8959a5205ac";

export default node;
