/**
 * @generated SignedSource<<cee3c431da158a7cd929a30da7336ed0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OAuthProviders = "Strava" | "%future added value";
export type auth_tokensInsertInput = {
  code?: string | null | undefined;
  created_at?: any | null | undefined;
  expires_at?: any | null | undefined;
  profile_id?: any | null | undefined;
  provider?: OAuthProviders | null | undefined;
  refresh_token?: string | null | undefined;
  token?: string | null | undefined;
  uid?: string | null | undefined;
};
export type useCreateAuthTokenMutation$variables = {
  input: ReadonlyArray<auth_tokensInsertInput>;
};
export type useCreateAuthTokenMutation$data = {
  readonly insertIntoauth_tokensCollection: {
    readonly records: ReadonlyArray<{
      readonly code: string | null | undefined;
      readonly expires_at: any | null | undefined;
      readonly id: any;
      readonly provider: OAuthProviders | null | undefined;
      readonly refresh_token: string | null | undefined;
      readonly token: string | null | undefined;
    }>;
  } | null | undefined;
};
export type useCreateAuthTokenMutation = {
  response: useCreateAuthTokenMutation$data;
  variables: useCreateAuthTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expires_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refresh_token",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateAuthTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "auth_tokensInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoauth_tokensCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "auth_tokens",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateAuthTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "auth_tokensInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoauth_tokensCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "auth_tokens",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f6354ace8b1fee569d8ba869362607c",
    "id": null,
    "metadata": {},
    "name": "useCreateAuthTokenMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateAuthTokenMutation(\n  $input: [auth_tokensInsertInput!]!\n) {\n  insertIntoauth_tokensCollection(objects: $input) {\n    records {\n      id\n      provider\n      code\n      expires_at\n      refresh_token\n      token\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "df23d222aee7dd2a5a620c77a5b0cf56";

export default node;
