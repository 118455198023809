/**
 * @generated SignedSource<<b77c582fd046e163a699128bc37bff6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Pace = "A" | "B" | "C" | "D" | "Party" | "%future added value";
export type profilesUpdateInput = {
  avatar_url?: string | null | undefined;
  bio?: string | null | undefined;
  breakfast_food?: string | null | undefined;
  created_at?: any | null | undefined;
  emergency_contact_name?: string | null | undefined;
  emergency_contact_number?: string | null | undefined;
  favorite_groups?: ReadonlyArray<Pace | null | undefined> | null | undefined;
  full_name?: string | null | undefined;
  id?: any | null | undefined;
  instagram?: string | null | undefined;
  member_number?: number | null | undefined;
  name?: string | null | undefined;
  push_token?: string | null | undefined;
  strava_code?: string | null | undefined;
  updated_at?: any | null | undefined;
  website?: string | null | undefined;
};
export type useUpdateProfileMutation$variables = {
  id: any;
  input: profilesUpdateInput;
};
export type useUpdateProfileMutation$data = {
  readonly updateprofilesCollection: {
    readonly records: ReadonlyArray<{
      readonly emergency_contact_name: string | null | undefined;
      readonly emergency_contact_number: string | null | undefined;
      readonly strava_code: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ProfileCard_fragment">;
    }>;
  };
};
export type useUpdateProfileMutation = {
  response: useUpdateProfileMutation$data;
  variables: useUpdateProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Literal",
    "name": "atMost",
    "value": 1
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "set",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emergency_contact_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emergency_contact_number",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "strava_code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "profilesUpdateResponse",
        "kind": "LinkedField",
        "name": "updateprofilesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profiles",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileCard_fragment"
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpdateProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "profilesUpdateResponse",
        "kind": "LinkedField",
        "name": "updateprofilesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profiles",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar_url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bio",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "breakfast_food",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "instagram",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created_at",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "user_rolesConnection",
                "kind": "LinkedField",
                "name": "user_rolesCollection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user_rolesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user_roles",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9be0622761448ed538314978da03d40",
    "id": null,
    "metadata": {},
    "name": "useUpdateProfileMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateProfileMutation(\n  $input: profilesUpdateInput!\n  $id: UUID!\n) {\n  updateprofilesCollection(set: $input, filter: {id: {eq: $id}}, atMost: 1) {\n    records {\n      ...ProfileCard_fragment\n      emergency_contact_name\n      emergency_contact_number\n      strava_code\n      nodeId\n    }\n  }\n}\n\nfragment ProfileCard_fragment on profiles {\n  id\n  name\n  avatar_url\n  bio\n  strava_code\n  breakfast_food\n  instagram\n  created_at\n  user_rolesCollection {\n    edges {\n      node {\n        role\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d646e31e9c225d3c41d7f36fbc93330";

export default node;
