import { Button, View } from "tamagui";
import { CalendarIcon } from "../../../components/design-system/icons";
import { brandShadowSm } from "../../../../tamagui.config";
import {
  NavigationProp,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import { RidesNavigatorParamList } from "../../../navigators/RidesNavigator";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";
import { useEffect, useState } from "react";
import { supabase } from "../../../../lib/supabase";

export const CalendarButton = () => {
  const [invitesCount, setInvitesCount] = useState(0);
  const navigation = useNavigation<NavigationProp<RidesNavigatorParamList>>();

  const isFocused = useIsFocused();

  const user = useAtomValue(currentUserAtom);

  useEffect(() => {
    if (isFocused) {
      supabase
        .from("invites")
        .select("id")
        .eq("recipient_id", user?.id)
        .order("created_at", { ascending: false })
        .then(({ data }) => {
          setInvitesCount(data?.length ?? 0);
        });
    }
  }, [isFocused, setInvitesCount]);

  return (
    <View position="relative">
      {invitesCount > 0 ? (
        <View
          pos="absolute"
          top={-4}
          right={-4}
          bg="red"
          borderWidth={1}
          borderColor={"black"}
          w={12}
          h={12}
          borderRadius={8}
          zIndex={100}
        />
      ) : null}

      <Button
        py={4}
        h={32}
        w={32}
        bg="$brandParchment"
        pressStyle={{
          backgroundColor: "$brandParchment",
          borderColor: "black",
        }}
        onPress={() => navigation.navigate("Calendar")}
        borderWidth={1}
        borderColor={"black"}
        {...brandShadowSm}
      >
        <CalendarIcon width={24} height={24} />
      </Button>
    </View>
  );
};
