import { useMutation } from "react-relay";
import { graphql } from "relay-hooks";
import {
  attendancesInsertInput,
  useJoinRideMutation,
} from "./__generated__/useJoinRideMutation.graphql";

export const useJoinRide = (callback: () => void) => {
  const [joinRide, isJoiningRide] = useMutation<useJoinRideMutation>(
    useJoinRideMutationString
  );

  const handleJoinRide = (input: attendancesInsertInput) => {
    joinRide({
      variables: {
        input,
      },
      onCompleted: () => {
        callback();
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return [handleJoinRide, isJoiningRide] as const;
};

const useJoinRideMutationString = graphql`
  mutation useJoinRideMutation($input: attendancesInsertInput!) {
    insertIntoattendancesCollection(objects: [$input]) {
      records {
        id
        user_id
        event_id
        events {
          id
        }
      }
    }
  }
`;
