import { MaterialTopTabBarProps } from "@react-navigation/material-top-tabs";
import { SafeAreaView } from "react-native-safe-area-context";
import { brandShadow } from "../../../tamagui.config";
import { Button, Text, View } from "tamagui";
import { MotiView } from "moti";
import { Easing } from "react-native-reanimated";

export const TabBar = ({
  state,
  descriptors,
  navigation,
}: MaterialTopTabBarProps) => {
  const currentRoute = state.routes[state.index];

  // Use the current route's key to access its descriptor
  const currentDescriptor = descriptors[currentRoute.key];

  // Now you have access to the current route's options
  const currentOptions = currentDescriptor.options;

  return (
    <SafeAreaView
      edges={["bottom"]}
      style={[
        {
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          paddingHorizontal: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        currentOptions.tabBarStyle,
      ]}
    >
      <View
        bg="white"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        gap="$2"
        borderRadius={15}
        borderWidth={1}
        width="auto"
        margin="0 auto"
        py="$2"
        px="$2"
        {...brandShadow}
      >
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label = options.title ?? "";

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: "tabPress",
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name, route.params);
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: "tabLongPress",
              target: route.key,
            });
          };

          const isCreateRide = route.key.includes("createRide");

          return (
            <MotiView
              from={{
                opacity: 0,
                translateY: 50,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
              }}
              transition={{
                delay: index * 100,
                duration: 500,
                rubberBandEffect: {
                  damping: 10,
                  stiffness: 100,
                },
                easing: Easing.inOut(Easing.ease),
              }}
              key={index}

              // style={styles.item}
            >
              <Button
                unstyled
                chromeless
                key={index}
                accessibilityRole="button"
                accessibilityState={isFocused ? { selected: true } : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                onPress={onPress}
                onLongPress={onLongPress}
                opacity={isCreateRide ? 1 : isFocused ? 1 : 0.5}
                justifyContent="space-between"
                alignItems="center"
                minHeight={45}
                h="100%"
                bg={
                  isCreateRide
                    ? "$brandYellow"
                    : isFocused
                    ? "$brandYellow"
                    : undefined
                }
                px="$2"
                borderRadius={999}
                py="$2"
                w={50}
                height={50}
              >
                {options.tabBarIcon?.({
                  focused: isFocused,
                  color: "black",
                })}
                <Text
                  color="black"
                  mt={1}
                  fontSize={9}
                  maxWidth={72}
                  textAlign="center"
                >
                  {label}
                </Text>
              </Button>
            </MotiView>
          );
        })}
      </View>
    </SafeAreaView>
  );
};
