import { Path, Svg, SvgProps } from "react-native-svg";

export const BcIcon = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 50 50" fill="none" {...props}>
      <Path
        d="M18.0597 0.177933C15.9012 0.6703 11.2061 2.35842 9.80814 3.15412C7.63205 4.38943 4.23823 8.12615 3.06007 10.588C1.772 13.274 0.259731 19.8858 0.0487167 23.7588C-0.0479983 25.5041 0.00475536 28.4539 0.15862 30.375C0.422388 33.5534 0.563065 34.0941 1.89509 36.8989C4.01403 41.3697 6.41872 43.9459 10.1642 45.7747C13.9933 47.6386 17.2464 48.6365 22.1657 49.4586C27.1157 50.2851 30.0611 50.1532 33.2088 48.9619C35.9695 47.92 40.3217 45.5417 41.7285 44.3064C43.5309 42.7281 46.5423 38.8551 47.0038 37.5319C47.2456 36.8417 47.7028 35.7603 48.0457 35.0701C48.3842 34.3931 48.9777 32.4456 49.3646 30.7355C49.9888 27.9923 50.0592 26.9416 49.9668 21.7498L49.8613 15.8677L48.7008 13.4982C47.1269 10.2846 43.7111 6.84248 39.271 3.99818C37.7104 3.00025 31.9251 0.854938 30.4788 0.740638C29.7007 0.679092 28.5708 0.47687 27.9598 0.292232C26.8959 -0.0286858 19.3478 -0.112212 18.0597 0.177933ZM28.2016 3.33875C28.6632 3.64648 29.8237 4.12566 30.7821 4.41581C35.5211 5.83136 37.6577 6.82049 40.414 8.86909C43.443 11.1243 44.5508 12.4432 45.6498 15.116C46.2477 16.5667 46.3752 17.5778 46.4895 21.7762C46.6038 26.036 46.5291 27.179 45.9356 29.9662C44.986 34.437 43.5661 37.2769 40.8712 40.108C39.0293 42.0423 38.1456 42.6754 35.4464 44.0074C33.6748 44.8822 31.6525 45.7483 30.9623 45.9329C26.7508 47.0583 16.9211 45.3307 11.2501 42.4644C8.52007 41.0884 6.17253 38.4815 4.55036 35.0349L3.23592 32.2434L3.13041 26.8889C3.03809 22.0883 3.10843 21.1739 3.81621 18.0834C4.92403 13.252 5.7505 11.2342 7.48698 9.14165C9.61031 6.5875 11.7864 5.23789 15.6858 4.06851C17.5146 3.519 19.1279 3.02663 19.2994 2.96069C19.4708 2.89474 21.3568 2.8288 23.4845 2.81122C26.6629 2.78484 27.507 2.88156 28.1972 3.33436L28.2016 3.33875Z"
        fill="black"
      />
      <Path
        d="M12.0282 13.1643C10.7797 13.9204 10.7314 14.0963 10.4852 18.5363C10.3753 20.5454 10.1379 23.0116 9.94884 24.0579C9.47406 26.7088 9.37734 27.9573 9.34218 31.949C9.30701 35.8176 9.47406 36.666 10.3797 37.1672C10.7094 37.3518 12.6305 37.5804 14.6703 37.6771C20.3105 37.9497 21.88 37.3122 23.4406 34.1075C24.078 32.7974 24.3374 31.7072 24.4385 29.9048C24.61 26.8143 24.0604 25.5086 21.9899 24.1063L20.4908 23.0907L21.1502 22.1764C21.6294 21.5125 21.8404 20.6905 21.9239 19.1914C22.0778 16.3427 21.2161 14.637 19.0225 13.4412C16.9827 12.329 13.6196 12.1927 12.0326 13.1599L12.0282 13.1643ZM17.0398 15.8723C17.6685 16.1361 18.3235 16.6152 18.5037 16.9493C19.0401 17.9385 18.9258 19.9475 18.2971 20.497C17.1849 21.473 15.0747 22.3434 14.2746 22.15C13.5141 21.9653 13.4965 21.8862 13.4965 19.1826C13.4965 17.6527 13.5844 16.18 13.69 15.8987C13.9317 15.2568 15.5363 15.2436 17.0354 15.8723H17.0398ZM19.5016 27.4165C20.2402 27.9353 20.3017 28.1375 20.1918 29.641C20.0336 31.861 19.317 33.2502 18.1213 33.6722C16.6793 34.1778 13.2547 34.0899 12.947 33.5404C12.8063 33.2898 12.7404 31.6061 12.7975 29.7729L12.903 26.4582L15.1539 26.3615C17.4926 26.2604 18.0729 26.401 19.506 27.4121L19.5016 27.4165Z"
        fill="black"
      />
      <Path
        d="M31.9208 14.2851C30.1447 14.9313 28.0918 17.0986 27.4279 19.0153C27.0015 20.2506 26.8784 21.6266 26.8784 25.1655C26.8784 29.5441 26.9136 29.799 27.762 31.5267C28.8039 33.6544 29.881 34.5469 32.6418 35.5668C34.4442 36.2306 34.7739 36.2658 36.3037 35.9229C38.5238 35.4261 40.757 33.9402 41.1966 32.6697C42.0011 30.3573 39.4118 29.2539 37.605 31.1399C36.3433 32.4587 35.042 32.6301 33.1517 31.7289C30.9624 30.6871 30.6635 29.9133 30.6767 25.3194C30.6899 21.209 31.024 19.7275 32.2901 18.2064C32.9099 17.4635 33.3188 17.2744 34.3387 17.2744C35.3586 17.2744 35.7938 17.4723 36.629 18.3867C38.1985 20.1056 40.0273 19.7671 40.0273 17.758C40.0273 16.7557 37.9567 14.5444 36.8181 14.329C34.4486 13.8806 33.0682 13.8674 31.9252 14.2851H31.9208Z"
        fill="black"
      />
    </Svg>
  );
};
