import { G, Path, Svg, SvgProps } from "react-native-svg";

export const CloseIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 58.5 59.79" {...props}>
      <G id="Layer_1-2">
        <Path d="m8.07.89c-2.34,1.63-1.82,4.54,1.6,9.06,1.62,2.14,3.51,4.65,4.16,5.52.65.88,3.12,4.19,5.46,7.34,2.35,3.15,4.28,6.03,4.28,6.39s-5.35,5.99-11.94,12.56C.23,53.13-.29,53.75.08,55.62c.7,3.62,4.36,5.24,7.64,3.39.75-.42,4.32-4.28,7.93-8.55,3.61-4.28,8.24-9.39,10.28-11.34l3.71-3.56,4.28,5.52c2.35,3.03,5.55,7.55,7.1,10.01,1.57,2.49,3.64,5.07,4.66,5.81,2.61,1.88,6.31,1.84,7.86-.08,2.06-2.55,1.53-5.05-1.92-9.15-1.75-2.08-3.9-4.85-4.84-6.24-.93-1.37-3.76-4.75-6.27-7.48l-4.57-4.99,5.49-5.32c3.04-2.94,8.08-7.71,11.29-10.69,4.87-4.52,5.79-5.68,5.79-7.28,0-2.52-1.43-3.86-4.1-3.86-2.27,0-1.66-.51-19.19,16.2-2.85,2.72-5.05,4.37-5.31,3.99-1.75-2.61-9.91-12.94-12.09-15.32-1.47-1.6-3.41-3.76-4.32-4.81-1.85-2.14-3.37-2.41-5.42-.98h0Z" />
      </G>
    </Svg>
  );
};
