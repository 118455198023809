import { G, Path, Svg, SvgProps } from "react-native-svg";

export const SmsIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 123 104.9" {...props}>
      <G id="Layer_1-2">
        <Path d="m67.35.17c-1.65.11-16.33.52-32.62.9C13.75,1.57,4.8,2.09,4.04,2.84,2.91,3.97,2.36,15.84.25,83.88c-.59,19-.31,20.98,2.97,21.02,2.21.03,9.79-6.01,19.5-15.53l9.3-9.11,33.86-.16c18.59-.09,35.6-.17,37.72-.18,6.75-.03,16.65-1.62,17.94-2.89,1.03-1,1.27-7.38,1.44-37.66.14-24.93-.08-36.67-.7-37.15C119.98.45,114.61.04,93.22,0c-12.59-.02-24.22.05-25.87.17h0Zm40.15,8.84c1.41-.04,4.24.16,6.21.45l3.62.53-.25,30.65c-.18,22.05-.52,30.92-1.23,31.63-.73.73-10.28.94-36.61.82-20.4-.09-39.14.22-43.85.74l-8.23.9-3.87,4.44c-6.45,7.4-14.52,15.33-15.63,15.36-.6.02-.8-.76-.49-1.99.27-1.11.53-20.32.58-42.7.05-22.38.36-40.96.7-41.3.34-.34,22.21-.36,48.54-.04s49.09.54,50.52.49h0Z" />
        <Path d="m34.07,23.06c-1.48.82-1.69,3.69-.33,4.53,1.15.71,69.69.65,70.84-.07,1.49-.92.9-4.56-.82-5.11-2.47-.78-68.22-.17-69.68.64h0Z" />
        <Path d="m33.01,38.14c-1.47,3.91,1.39,4.26,42.2,5.1l28.22.58,1.05-1.97c2.28-4.28,1.58-4.36-36.26-4.58-27.15-.15-34.89.04-35.2.87h0Z" />
        <Path d="m66.15,52.79c-.09.09-7.12.29-15.59.45-13.08.24-15.56.5-16.29,1.69-.46.74-.63,2.02-.37,2.71.43,1.15,4.81,1.32,34.84,1.36,23.69.03,34.71-.25,35.5-.9,1.48-1.21,1.48-4.08,0-4.65-1.07-.41-37.71-1.04-38.09-.66h0Z" />
      </G>
    </Svg>
  );
};
