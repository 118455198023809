import * as WebBrowser from "expo-web-browser";
import { makeRedirectUri, useAuthRequest } from "expo-auth-session";
import { supabase } from "../../../../lib/supabase";
import {
  FunctionsFetchError,
  FunctionsHttpError,
  FunctionsRelayError,
} from "@supabase/supabase-js";

WebBrowser.maybeCompleteAuthSession();

const discovery = {
  authorizationEndpoint: "https://www.strava.com/oauth/mobile/authorize",
  tokenEndpoint: "https://www.strava.com/oauth/token",
  revocationEndpoint: "https://www.strava.com/oauth/deauthorize",
};

export const useStravaAuth = () => {
  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: "121473",
      scopes: ["activity:read_all"],
      redirectUri: makeRedirectUri({
        // the "redirect" must match your "Authorization Callback Domain" in the Strava dev console.
        native:
          "com.breakfastclub.bcapp://app.breakfastclubatx.com/oauth/strava",
      }),
    },
    discovery
  );

  const getToken = async () => {
    const { error } = await supabase.functions.invoke("strava-token", {});

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context;
      console.error("Function returned an error", errorMessage);
    } else if (error instanceof FunctionsRelayError) {
      console.error("Relay error:", error.message);
    } else if (error instanceof FunctionsFetchError) {
      console.error("Fetch error:", error.message);
    }
  };

  const getRefreshToken = async () => {
    const { error } = await supabase.functions.invoke("refresh-strava-token");

    if (error instanceof FunctionsHttpError) {
      const errorMessage = await error.context;
      console.error("Function returned an error", errorMessage);
    } else if (error instanceof FunctionsRelayError) {
      console.error("Relay error:", error.message);
    } else if (error instanceof FunctionsFetchError) {
      console.error("Fetch error:", error.message);
    }
  };

  return { request, response, promptAsync, getToken, getRefreshToken } as const;
};
