import { useSetAtom } from "jotai";
import {
  currentUserAtom,
  currentUserProfileAtom,
  currentUserRoleAtom,
  isAuthenticatedAtom,
} from "../../atoms/global";
import { supabase } from "../../lib/supabase";

export const useLogOut = () => {
  const setIsAuthed = useSetAtom(isAuthenticatedAtom);
  const setCurrentUser = useSetAtom(currentUserAtom);
  const setUserRole = useSetAtom(currentUserRoleAtom);
  const setUserProfile = useSetAtom(currentUserProfileAtom);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsAuthed(false);
    setCurrentUser(null);
    setUserRole(null);
    setUserProfile(null);
  };

  return [handleLogout] as const;
};
