import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  Members,
  ShareScreen,
  PartnerQueryRenderer,
  PerkQueryRenderer,
} from "../screens/Club";
import {
  RouteProp,
  getFocusedRouteNameFromRoute,
} from "@react-navigation/native";
import { useLayoutEffect } from "react";
import { HomeTabsParamList } from "./HomeTabsNavigator";
import { ProfileQueryRenderer } from "../screens/Club/Profile";
import { MemberQueryRenderer } from "../screens/Member/MemberQueryRenderer";
import { EditProfileQueryRenderer } from "../screens/Club/EditProfile";
import { FriendsAndRequests } from "../screens/Club/FriendsAndRequests/FriendsAndRequests";

export type ClubNavigatorParamList = {
  Profile: undefined;
  EditProfile: undefined;
  Members: undefined;
  Followers: undefined;
  Member: { id: string };
  Partner: { partnerId: string };
  Perk: { perkId: string; partnerId: string };
  Share: undefined;
  FriendsAndRequests: undefined;
};

const Stack = createNativeStackNavigator<ClubNavigatorParamList>();

const tabBarHiddenRoutes = [
  "Share",
  "Perk",
  "Partner",
  "Member",
  "Members",
  "EditProfile",
  "Followerrs",
];

export const ClubNavigator = ({
  navigation,
  route,
}: {
  navigation: any;
  route: RouteProp<HomeTabsParamList, "profile">;
}) => {
  useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);

    if (tabBarHiddenRoutes.includes(routeName ?? "")) {
      navigation.setOptions({ tabBarStyle: { display: "none" } });
    } else {
      navigation.setOptions({ tabBarStyle: { display: "flex" } });
    }
  }, [navigation, route]);

  return (
    <Stack.Navigator screenOptions={{ headerShown: false, title: "Profile" }}>
      <Stack.Screen name="Profile" component={ProfileQueryRenderer} />
      <Stack.Screen
        name="EditProfile"
        component={EditProfileQueryRenderer}
        options={{
          presentation: "fullScreenModal",
          animation: "ios",
        }}
      />
      <Stack.Screen
        name="Members"
        component={Members}
        options={{
          presentation: "fullScreenModal",
        }}
      />
      <Stack.Screen
        name="FriendsAndRequests"
        component={FriendsAndRequests}
        options={{
          presentation: "fullScreenModal",
        }}
      />
      <Stack.Screen
        name="Member"
        component={MemberQueryRenderer}
        options={{
          presentation: "transparentModal",
          animation: "fade",
        }}
      />

      <Stack.Screen
        name="Followers"
        component={Members}
        options={{
          presentation: "fullScreenModal",
          animation: "fade",
        }}
      />
      <Stack.Screen
        name="Partner"
        component={PartnerQueryRenderer}
        options={{
          presentation: "fullScreenModal",
        }}
      />

      <Stack.Screen
        name="Share"
        component={ShareScreen}
        options={{
          presentation: "fullScreenModal",
        }}
      />
      <Stack.Screen
        name="Perk"
        component={PerkQueryRenderer}
        options={{
          presentation: "formSheet",
        }}
      />
    </Stack.Navigator>
  );
};
