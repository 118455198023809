import { Text, XStack } from "tamagui";
import { Button } from "../../../../components/design-system";
import { ArrowRightIcon } from "../../../../components/design-system/icons/ArrowRightIcon";
import { Platform } from "react-native";
import * as Haptics from "expo-haptics";
import mixpanel from "../../../../../mixpanel";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { ClubNavigatorParamList } from "../../../../navigators/ClubNavigator";
import { graphql, useFragment } from "relay-hooks";
import { PartnersList_fragment$key } from "./__generated__/PartnersList_fragment.graphql";

type PartnersListProps = {
  fragmentRef: PartnersList_fragment$key;
};

export const PartnersList = ({ fragmentRef }: PartnersListProps) => {
  const data = useFragment(PartnersListFragment, fragmentRef);

  const partners = data.partnersCollection?.edges || [];

  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  const onPartnerPress = (partnerId: string, name: string) => {
    if (Platform.OS === "ios") Haptics.selectionAsync();

    mixpanel.track("Partner Pressed", { partner: name });

    navigation.navigate("Partner", { partnerId });
  };

  return (
    <>
      {partners.map((partner) => {
        return (
          <Button
            key={partner.node.id}
            bg="white"
            margin={0}
            p={0}
            mb="$2"
            pressStyle={{
              scaleX: 0.99,
              scaleY: 0.99,
              bg: "white",
              shadowOffset: { height: 1, width: 1 }, // Reduced shadow offset for pressed effect
              shadowOpacity: 0.7, // Lower opacity for a softer shadow when pressed
              // Keep the shadow radius unchanged or adjust slightly if needed
            }}
            {...{
              shadowColor: "black",
              shadowOffset: { width: 2, height: 2 },
              shadowOpacity: 0.9,
              shadowRadius: 0,
            }}
            onPress={() =>
              onPartnerPress(partner.node.id, partner.node.name ?? "")
            }
          >
            <XStack
              w="100%"
              p="$4"
              key={partner.node.id}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text fontFamily="$heading" fontSize={16}>
                {partner.node.name}
              </Text>
              <ArrowRightIcon width={20} height={20} />
            </XStack>
          </Button>
        );
      })}
    </>
  );
};

export const PartnersListFragment = graphql`
  fragment PartnersList_fragment on Query {
    partnersCollection(
      orderBy: { index: AscNullsLast }
      filter: { enabled: { eq: true } }
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
