import { Image } from "tamagui";

export const Logo = () => {
  return (
    <Image
      source={{
        width: 200,
        height: 20,
        uri: "https://aifqfukkewqdyfrcofdf.supabase.co/storage/v1/object/public/Assets/black-logo.png",
      }}
      resizeMode="contain"
    />
  );
};
