/**
 * @generated SignedSource<<fc6b38ae6318a99debf245bb54a9c31a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type attendancesFilter = {
  and?: ReadonlyArray<attendancesFilter> | null | undefined;
  created_at?: DatetimeFilter | null | undefined;
  event_id?: BigIntFilter | null | undefined;
  id?: BigIntFilter | null | undefined;
  nodeId?: IDFilter | null | undefined;
  not?: attendancesFilter | null | undefined;
  or?: ReadonlyArray<attendancesFilter> | null | undefined;
  pending?: BooleanFilter | null | undefined;
  reward_id?: BigIntFilter | null | undefined;
  user_id?: UUIDFilter | null | undefined;
};
export type BigIntFilter = {
  eq?: any | null | undefined;
  gt?: any | null | undefined;
  gte?: any | null | undefined;
  in?: ReadonlyArray<any> | null | undefined;
  is?: FilterIs | null | undefined;
  lt?: any | null | undefined;
  lte?: any | null | undefined;
  neq?: any | null | undefined;
};
export type DatetimeFilter = {
  eq?: any | null | undefined;
  gt?: any | null | undefined;
  gte?: any | null | undefined;
  in?: ReadonlyArray<any> | null | undefined;
  is?: FilterIs | null | undefined;
  lt?: any | null | undefined;
  lte?: any | null | undefined;
  neq?: any | null | undefined;
};
export type UUIDFilter = {
  eq?: any | null | undefined;
  in?: ReadonlyArray<any> | null | undefined;
  is?: FilterIs | null | undefined;
  neq?: any | null | undefined;
};
export type BooleanFilter = {
  eq?: boolean | null | undefined;
  is?: FilterIs | null | undefined;
};
export type IDFilter = {
  eq?: string | null | undefined;
};
export type useLeaveRideMutation$variables = {
  filter: attendancesFilter;
};
export type useLeaveRideMutation$data = {
  readonly deleteFromattendancesCollection: {
    readonly records: ReadonlyArray<{
      readonly event_id: any | null | undefined;
      readonly events: {
        readonly id: any;
      } | null | undefined;
      readonly id: any;
      readonly user_id: any | null | undefined;
    }>;
  };
};
export type useLeaveRideMutation = {
  response: useLeaveRideMutation$data;
  variables: useLeaveRideMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "atMost",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLeaveRideMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "attendancesDeleteResponse",
        "kind": "LinkedField",
        "name": "deleteFromattendancesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "attendances",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "events",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLeaveRideMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "attendancesDeleteResponse",
        "kind": "LinkedField",
        "name": "deleteFromattendancesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "attendances",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "events",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae02984709eea235b0dd811293014efa",
    "id": null,
    "metadata": {},
    "name": "useLeaveRideMutation",
    "operationKind": "mutation",
    "text": "mutation useLeaveRideMutation(\n  $filter: attendancesFilter!\n) {\n  deleteFromattendancesCollection(atMost: 1, filter: $filter) {\n    records {\n      id\n      user_id\n      event_id\n      events {\n        id\n        nodeId\n      }\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e909a1ff2a17ab5e4abb1ce9c046b2ab";

export default node;
