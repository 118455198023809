import { OpaqueColorValue } from "react-native";
import { View, Text, ColorTokens, ThemeValueFallback } from "tamagui";
import React from "react";
import { brandShadowSm } from "../../../../tamagui.config";

type PillProps = {
  text: string;
  bg?: ColorTokens | ThemeValueFallback | OpaqueColorValue | null | undefined;
  color?: ColorTokens | ThemeValueFallback | OpaqueColorValue | undefined;
  borderColor?: ColorTokens | ThemeValueFallback | OpaqueColorValue | undefined;
  icon?: React.ReactNode;
};

export const Pill = ({ text, bg, color, icon, borderColor }: PillProps) => {
  return (
    <View
      bg={bg || "$brandYellow"}
      py="$2"
      px="$3"
      h={32}
      borderRadius="$7"
      borderColor={borderColor || bg || "black"}
      borderWidth={1}
      flexDirection="row"
      alignItems="center"
      gap="$2"
      {...brandShadowSm}
    >
      {icon ? icon : null}
      <Text
        color={color}
        fontFamily="$heading"
        textTransform="uppercase"
        fontSize={14}
        fontWeight={800}
      >
        {text}
      </Text>
    </View>
  );
};
