import { G, Mask, Path, Svg, SvgProps } from "react-native-svg";

export const ClubLogo = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 819 417" fill="none" {...props}>
      <Mask id="mask0_369_258" maskUnits="userSpaceOnUse" x="0" y="0">
        <Path d="M0 0H819V417H0V0Z" fill="white" />
      </Mask>
      <G mask="url(#mask0_369_258)">
        <Path d="M0 0H819.188V417.479H0V0Z" stroke="black" strokeWidth="18" />
      </G>
      <Path
        d="M235.153 251.139L227.518 287.873C224.606 298.779 220.242 308.602 214.789 316.962C209.336 324.238 203.153 331.149 194.789 336.602C186.06 342.785 175.513 345.696 162.784 345.696C150.054 345.696 140.601 342.785 134.054 336.602C127.872 331.149 124.601 324.238 123.143 316.962C120.601 308.602 120.601 299.144 122.784 287.873L156.237 130.404C158.784 119.857 162.784 110.399 168.601 101.67C174.06 94.3991 179.877 87.1283 188.242 81.67C196.606 76.2168 207.518 72.5814 220.606 72.5814C233.7 72.5814 242.794 76.2168 248.976 81.67C255.159 87.1283 258.794 94.3991 260.247 101.67C262.794 109.675 262.794 119.128 260.976 130.404L252.976 167.134H216.242L224.247 130.404C225.7 123.493 224.971 117.675 221.7 113.675C220.242 111.857 217.336 110.764 213.336 110.764C206.424 110.764 202.06 115.857 199.153 120.581C198.06 123.128 196.606 126.404 195.513 130.404L162.06 287.873C160.966 294.779 161.695 300.597 164.601 304.597C166.06 306.42 168.242 307.509 171.513 307.509C179.877 307.509 184.242 303.144 187.148 297.691C188.242 295.509 189.695 292.232 190.789 287.873L198.424 251.139H235.153Z"
        fill="currentColor"
      />
      <Path
        d="M322.794 75.4877H362.07L312.972 306.42H364.977L357.341 342.785H266.06L322.794 75.4877Z"
        fill="currentColor"
      />
      <Path
        d="M560.988 75.4877L515.894 287.873C512.983 298.779 508.618 308.602 503.165 316.962C497.712 324.238 491.53 331.149 483.165 336.602C474.436 342.785 463.889 345.696 451.16 345.696C438.431 345.696 428.978 342.785 422.431 336.602C416.249 331.149 412.978 324.238 411.519 316.962C408.978 308.602 408.978 299.144 411.16 287.873L456.254 75.4877H495.53L450.436 287.873C448.613 294.779 449.342 300.597 452.254 304.597C453.707 306.42 455.889 307.509 459.16 307.509C466.071 307.509 470.436 303.144 472.983 297.691C474.071 295.509 475.525 292.232 476.618 287.873L521.712 75.4877H560.988Z"
        fill="currentColor"
      />
      <Path
        d="M559.527 342.785L616.262 75.4877H650.809C674.085 75.4877 691.173 79.8523 700.996 91.1283C710.814 102.399 712.996 119.857 707.178 146.404C704.632 159.134 701.355 168.587 695.538 177.316C689.72 186.045 682.449 193.316 671.173 201.68C688.267 212.227 690.449 240.956 684.632 267.144C680.267 287.509 672.991 304.597 660.991 318.785C648.262 332.967 631.168 341.696 607.897 342.785H559.527ZM631.897 185.68C635.168 185.68 642.809 186.045 650.444 181.68C657.72 177.316 664.626 168.587 668.626 150.04C670.444 140.951 670.809 133.675 669.72 128.946C667.173 119.128 661.355 115.128 654.809 114.764C651.538 114.399 648.991 114.399 647.173 114.399L631.897 185.68ZM606.803 303.873C610.079 303.873 617.715 304.597 625.35 299.873C632.626 295.144 640.262 284.597 644.626 262.779C647.173 251.868 647.897 243.503 646.809 237.686C644.626 226.41 638.809 222.045 631.897 221.686C628.626 221.686 626.079 221.686 624.262 221.686L606.803 303.873Z"
        fill="currentColor"
      />
    </Svg>
  );
};
