import { G, Path, Svg, SvgProps } from "react-native-svg";

export const EggIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 113.75 135.3" {...props}>
      <G id="Layer_1-2">
        <Path d="m63.85,2.87c-8.56,4.16-13.41,8.95-18.21,17.96-5.06,9.51-7.73,11.87-17.76,15.67-4.23,1.6-8.92,3.54-10.51,4.33-3.55,1.77-11.2,8.94-12.75,11.94C1.73,58.37-.49,67.85.09,72.09c.56,4.07,1.72,6.21,5.98,11.06,7.74,8.8,8.8,11.53,10.16,26.21.37,3.96,1.15,8.16,1.74,9.28,2.62,5.01,13.26,13.64,19,15.4,4.57,1.4,18.02,1.73,20.95.5,5.29-2.21,9.96-6.34,14.32-12.69,4.08-5.94,4.61-6.42,9.84-8.94,3.07-1.48,7.29-3.51,9.49-4.57,2.41-1.17,6.14-4.1,9.56-7.51,6.2-6.2,9.96-12.9,11.67-20.82,1.23-5.66,1.28-13.99.1-16.65-2.52-5.72-7.44-12.76-12.94-18.56-1.49-1.57-2.84-3.37-3.07-4.1-.22-.68-.7-1.3-1.02-1.3-1.06,0-1.14-2.39-.21-6.83.49-2.36.91-6.65.93-9.52.03-4.44-.3-5.88-2.18-9.59C89.99,4.79,82.62-.03,73.82,0c-3.33.01-5.19.55-9.96,2.87h0Zm13.58,5.77c3.48.96,7.9,4.95,9.78,8.84,1.77,3.66,1.87,7.92.35,14.47-.62,2.66-.94,5.83-.71,7.03.85,4.51,3.88,9.49,8.76,14.4,2.78,2.8,6.24,6.95,7.75,9.32l2.73,4.27-.38,7.23c-.33,6.35-.69,7.91-2.94,12.76-3.7,7.98-9.11,13.17-18.05,17.33-9.56,4.44-12.32,6.54-16.61,12.56-5.28,7.44-9.5,10.1-16.63,10.51-9.83.56-17.89-2.36-25.39-9.2-2.62-2.39-4-7.45-4.56-16.73-.58-9.51-2.57-13.79-12-25.8-2.71-3.45-2.73-7.37-.08-15.29,1.67-4.98,2.35-6.05,5.65-8.89,4.57-3.92,5.6-4.48,12.35-6.7,14.4-4.73,18.62-8.1,24.94-19.9,4.68-8.74,9.44-13.62,15.26-15.66,4.1-1.43,6.15-1.55,9.76-.56h0Z" />
        <Path d="m50.61,51.61c-8.33.98-14.32,4.58-17.06,10.25-2.65,5.47-1.85,21.36,1.39,27.71,2.99,5.84,11.65,10.7,20.18,11.31,9.7.69,18.63-4.89,23.55-14.72,1.34-2.68,1.77-4.88,2.01-10.27.28-6.28.14-7.21-1.66-11.02-2.87-6.07-5.66-8.85-11.69-11.58-5.64-2.56-7.51-2.75-16.72-1.66h0Zm5.46,7.08c11.06,3.25,11.57,3.55,14.38,8.35,4.64,7.93.49,20.12-8.17,24.01-6.69,3-18.35-.27-21.11-5.92-1.46-2.99-2.51-12.97-1.87-17.75.86-6.43,9.39-10.86,16.77-8.69h0Z" />
      </G>
    </Svg>
  );
};
