import { Button } from "tamagui";
import { brandShadow } from "../../../../tamagui.config";
type HeaderButtonProps = {
  onPress: () => void;
  children: React.ReactNode;
  disabled?: boolean;
};

export const HeaderButton = ({
  onPress,
  children,
  disabled,
}: HeaderButtonProps) => {
  return (
    <Button
      borderWidth={1}
      borderColor="black"
      unstyled
      p="$3"
      w={56}
      h={56}
      borderRadius={8}
      justifyContent="center"
      alignItems="center"
      bg="white"
      zIndex={1000}
      disabled={disabled}
      pressStyle={{
        scaleX: 0.99,
        scaleY: 0.99,
        shadowOffset: { height: 1, width: 1 }, // Reduced shadow offset for pressed effect
        shadowOpacity: 0.7, // Lower opacity for a softer shadow when pressed
        // Keep the shadow radius unchanged or adjust slightly if needed
      }}
      {...brandShadow}
      onPress={onPress}
    >
      {children}
    </Button>
  );
};
