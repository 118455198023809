import { graphql } from "relay-hooks";
import { RouteCardFragment$key } from "./__generated__/RouteCardFragment.graphql";
import { useFragment } from "react-relay";
import { Image, View, YStack } from "tamagui";
import { Linking } from "react-native";

type RouteCardProps = {
  strava_id: string;
  image_url?: string;
};
export const RouteCard = ({ strava_id, image_url }: RouteCardProps) => {
  return (
    <YStack
      w="100%"
      onPress={() => Linking.openURL(`https://strava.com/routes/${strava_id}`)}
    >
      <View width={"100%"}>
        <View w="100%" overflow="hidden" pos="relative">
          <Image
            source={{ uri: image_url ?? "" }}
            w="100%"
            resizeMode="cover"
            height={225}
          />
        </View>
      </View>
    </YStack>
  );
};
