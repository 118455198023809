import { useState } from "react";
import { KeyboardAvoidingView, Platform, Pressable } from "react-native";
import { Heading, ScrollView, Text, View, YStack } from "tamagui";

import { iProfile } from "../../../features/Profile";
import { Loader } from "../../../components/design-system/Loader";
import { EditProfile as EditProfileComponent } from "../../../features/Profile/components/EditProfile/EditProfile";
import { Button } from "../../../components/design-system";
import { brandShadow, customColorTokens } from "../../../../tamagui.config";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { Header } from "../../../components/design-system/Header";
import { useLogOut } from "../../../hooks/useLogOut";
import {
  ArrowLeftIcon,
  CloseIcon,
} from "../../../components/design-system/icons";
import { ClubNavigatorParamList } from "../../../navigators/ClubNavigator";
import { graphql } from "relay-runtime";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { EditProfileQuery } from "./__generated__/EditProfileQuery.graphql";
import { useUpdateProfile } from "./hooks/useUpdateProfile";
import { supabase } from "../../../../lib/supabase";
import { ConfirmationModal } from "../../../components/design-system/ConfirmationModal";
import * as Burnt from "burnt";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import { ArrowLeft } from "@tamagui/lucide-icons";

type EditProfileProps = {
  queryRef: PreloadedQuery<EditProfileQuery>;
  refreshQuery: () => void;
};

export const EditProfileQueryString = graphql`
  query EditProfileQuery($id: UUID!) {
    profilesCollection(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          name
          bio
          avatar_url
          breakfast_food
          instagram
          emergency_contact_name
          emergency_contact_number
        }
      }
    }
  }
`;

export const EditProfile = ({ queryRef, refreshQuery }: EditProfileProps) => {
  const [loading, setLoading] = useState(false);
  const data = usePreloadedQuery<EditProfileQuery>(
    EditProfileQueryString,
    queryRef
  );

  const user = data.profilesCollection?.edges[0]?.node;

  const [profile, onProfileChange] = useState<iProfile | null>(user ?? null);
  const [logOut] = useLogOut();

  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  const [updateProfile, isProfileUpdating] = useUpdateProfile(() => {
    navigation.navigate("Profile");
    refreshQuery?.();
  });

  const onSubmit = async () => {
    const {
      name,
      avatar_url,
      breakfast_food,
      emergency_contact_name,
      emergency_contact_number,
    } = profile ?? {};

    const input = {
      name,
      avatar_url,
      breakfast_food,
      emergency_contact_name,
      emergency_contact_number,
    };

    updateProfile(input, user?.id ?? "");
  };

  const defaultValues: iProfile = {
    name: profile?.name ?? "",
    bio: profile?.bio ?? "",
    avatar_url: profile?.avatar_url ?? "",
    breakfast_food: profile?.breakfast_food ?? "",
    instagram: profile?.instagram ?? "",
    emergency_contact_name: profile?.emergency_contact_name ?? "",
    emergency_contact_number: profile?.emergency_contact_number ?? "",
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteAccount = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("profiles")
      .delete()
      .eq("id", profile?.id);
    if (error) {
      setLoading(false);
      Burnt.toast({
        title: "Unable to delete account",
        haptic: "error",
      });
    } else {
      await logOut();
      setLoading(false);
    }
  };

  return (
    <>
      <Header
        hideBorder
        title={
          <HeaderButton onPress={() => navigation.navigate("Profile")}>
            <CloseIcon width={16} height={16} color="black" />
          </HeaderButton>
        }
      />
      {isProfileUpdating || loading ? <Loader /> : null}
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "position" : "height"}
        enabled
        contentContainerStyle={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          width: "100%",
        }}
        style={{ flex: 1, width: "100%" }}
      >
        <ScrollView
          style={{
            backgroundColor: customColorTokens.brandParchment,
            flex: 1,
          }}
          w="100%"
          contentContainerStyle={{
            paddingVertical: 16,
          }}
        >
          <EditProfileComponent
            id={user?.id ?? ""}
            defaultValues={defaultValues}
            onChange={(val) => onProfileChange(val)}
          />
          <View px="$4" mt="$6" gap="$3">
            <Button
              onPress={onSubmit}
              {...brandShadow}
              pressStyle={{
                bg: "$brandYellow",
                shadowColor: "$colorTransparent",
                shadowOffset: { width: 0, height: 0 },
                borderWidth: 1,
                borderColor: "black",
                scale: 0.98,
              }}
            >
              SAVE
            </Button>
            <YStack
              mt="$4"
              justifyContent="center"
              alignItems="center"
              gap="$3"
            >
              <Text>More Actions</Text>

              <Pressable onPress={logOut}>
                <Text textDecorationLine="underline" fontFamily="$heading">
                  LOG OUT
                </Text>
              </Pressable>
              <Pressable onPress={() => setIsDeleteModalOpen(true)}>
                <Text textDecorationLine="underline" fontFamily="$heading">
                  DELETE ACCOUNT
                </Text>
              </Pressable>
            </YStack>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
      <ConfirmationModal
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteAccount}
        title="Delete Account"
        subtitle="Are you sure you want to delete your account?"
        isOpen={isDeleteModalOpen}
      />
    </>
  );
};
