import { useMutation } from "react-relay";
import { graphql } from "relay-hooks";

import {
  attendancesFilter,
  useLeaveRideMutation,
} from "./__generated__/useLeaveRideMutation.graphql";

export const useLeaveRide = (callback: () => void) => {
  const [leaveRide, isLeavingRide] = useMutation<useLeaveRideMutation>(
    useLeaveRideMutationString
  );

  const handleJoinRide = (filter: attendancesFilter) => {
    leaveRide({
      variables: {
        filter,
      },
      onCompleted: () => {
        callback();
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return [handleJoinRide, isLeavingRide] as const;
};

const useLeaveRideMutationString = graphql`
  mutation useLeaveRideMutation($filter: attendancesFilter!) {
    deleteFromattendancesCollection(atMost: 1, filter: $filter) {
      records {
        id
        user_id
        event_id
        events {
          id
        }
      }
    }
  }
`;
