import { Path, Svg, SvgProps } from "react-native-svg";

export const BcAppIcon = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 270 71" fill="none" {...props}>
      <Path
        d="M267.157 0.237878C266.437 0.534713 266.088 1.17291 265.491 3.27302C265.239 4.16352 264.422 6.52707 263.677 8.527C262.931 10.5269 262.185 12.7346 262.018 13.4396C261.851 14.1483 261.358 15.8848 260.916 17.3282C260.166 19.7845 260.144 19.9737 260.556 20.5933C261.287 21.6953 262.4 21.354 263.365 19.7325C263.699 19.1722 264.415 17.3504 264.972 15.6547C266.155 12.0334 267.213 9.31361 268.823 5.72933C270.363 2.31201 270.385 1.10612 268.931 0.352901C268.07 -0.0923515 267.97 -0.0997723 267.157 0.237878Z"
        fill="black"
      />
      <Path
        d="M5.80819 0.872395C3.27025 1.49946 3.24799 1.52914 2.79161 4.61994C2.10517 9.28767 1.69702 12.6976 0.769412 21.5099C0.546785 23.6359 0.249962 25.9921 0.108966 26.7787C-0.154476 28.248 0.0533071 29.2313 0.802816 30.0439C1.14789 30.4186 1.96046 30.4706 7.09942 30.4483L12.999 30.4223L14.4164 29.632C16.1566 28.6599 17.7966 26.8789 18.8578 24.801C19.5813 23.3799 19.6593 22.9903 19.6593 20.7121C19.6593 18.3077 19.6185 18.1259 18.7539 16.7234C17.7855 15.1539 16.5648 13.9109 15.7336 13.6437C15.3774 13.5287 15.2809 13.3543 15.4108 13.0501C16.0824 11.462 16.3904 9.85908 16.5091 7.30259L16.6464 4.36392L15.6965 3.12463C14.3608 1.38073 13.0769 0.850133 9.81175 0.679453C8.09011 0.590402 6.68015 0.65719 5.81191 0.872395H5.80819ZM10.7987 4.09677C12.9768 4.33052 13.4777 4.9984 13.2588 7.37679C13.0398 9.75889 12.7133 10.9611 12.1382 11.4805C11.6558 11.9184 9.27745 12.1224 6.26457 11.9889L5.12546 11.9369L5.3147 8.15228C5.41859 6.07443 5.55588 4.30826 5.61524 4.24147C5.79334 4.0374 7.93428 3.75912 8.62071 3.84817C8.98434 3.8964 9.96389 4.00772 10.7987 4.10048V4.09677ZM12.4684 16.4599C12.8469 16.6047 13.6855 17.254 14.3422 17.9107C15.5147 19.0832 15.5333 19.1278 15.5333 20.8494C15.5333 22.8753 14.7541 24.5116 13.2291 25.673L12.3163 26.3705L8.31645 26.0143L4.31659 25.6581L4.47985 22.1889C4.5689 20.2891 4.71362 18.215 4.79525 17.6065C4.89667 16.8644 5.36047 16.4649 6.18667 16.408C9.42959 16.1928 11.8265 16.2113 12.4684 16.4562V16.4599Z"
        fill="black"
      />
      <Path
        d="M187.182 2.15975C184.903 2.97976 182.013 5.53625 180.918 7.70685C178.959 11.5806 179.26 18.0738 181.534 21.0533C182.354 22.1256 185.36 23.9734 187.055 24.4446C187.712 24.6265 189.322 24.786 190.64 24.8008C192.74 24.8231 193.215 24.7378 194.528 24.0921C196.606 23.0718 197.415 22.1961 197.415 20.9754C197.415 19.443 196.758 19.02 194.706 19.2352C188.907 19.8437 187.456 19.6916 185.868 18.3039C184.584 17.1796 184.106 15.3021 184.391 12.4933C184.655 9.91085 185.512 8.33392 187.489 6.79038C188.833 5.74403 189.011 5.71806 192.328 6.052C194.71 6.29318 195.33 5.95924 195.107 4.56411C194.925 3.40646 193.871 2.80166 191.111 2.26364C188.603 1.77386 188.276 1.76644 187.185 2.15604L187.182 2.15975Z"
        fill="black"
      />
      <Path
        d="M86.1135 2.36758C85.8092 2.4789 85.4642 2.71636 85.3046 2.92044C84.8186 3.54379 84.4178 5.70698 84.4178 7.7069C84.4178 9.47307 84.1284 12.7717 83.2305 21.3316C83.0338 23.2054 82.9374 24.9864 83.0153 25.3092C83.2528 26.2962 83.8947 26.8305 84.852 26.8305C86.2656 26.8305 86.5662 26.0699 86.9743 21.4615C87.171 19.2575 87.3788 17.3874 87.4344 17.3318C87.4901 17.2761 88.0689 17.5841 88.6997 18.0034C89.349 18.4338 90.5104 19.1759 91.3193 19.6731C92.1281 20.174 93.2598 21.0125 93.8646 21.5617C95.3599 22.9197 96.1577 23.1906 97.2819 22.7305C98.3951 22.2741 98.7884 21.0571 98.1353 20.0664C97.5268 19.1425 95.7347 17.7919 92.9741 16.1815C91.6309 15.3986 90.5178 14.6862 90.5178 14.6083C90.5178 14.5304 90.9222 14.2076 91.4046 13.8959C92.3396 13.2948 95.052 10.3153 96.7439 8.03713C97.3079 7.27649 98.0426 6.54183 98.3913 6.38228C99.5972 5.83313 98.8737 3.95936 97.4526 3.95936C96.3988 3.95936 95.4787 4.66805 92.8257 7.5288C90.7144 9.80701 88.2878 11.9368 87.8055 11.9368C87.7646 11.9368 87.798 9.99995 87.8797 7.61785C87.965 5.23575 87.9353 3.10596 87.8203 2.86849C87.5494 2.31935 86.8148 2.10414 86.1061 2.36758H86.1135Z"
        fill="black"
      />
      <Path
        d="M205.096 2.6681C204.413 3.95191 204.057 6.49356 203.809 11.8848C203.664 15.0239 203.597 17.6694 203.66 17.7733C203.723 17.8772 203.653 18.8233 203.508 19.8623C203.052 23.0866 203.927 23.8881 207.905 23.8881C208.918 23.8881 210.521 24.081 211.452 24.3148C213.478 24.8231 214.484 24.5486 214.977 23.3575C215.782 21.3947 213.252 19.9142 209.1 19.9142H207.352L207.437 14.9237C207.556 7.88498 207.745 4.55672 208.068 3.95933C208.506 3.13933 208.395 2.88702 207.404 2.47887C206.113 1.94086 205.467 1.99651 205.107 2.6681H205.096Z"
        fill="black"
      />
      <Path
        d="M29.0059 2.6905C28.4493 2.8315 27.8259 3.20625 27.6219 3.52535C27.2137 4.16354 25.8743 8.74222 25.3919 11.1466C24.9244 13.4767 24.2973 16.9497 23.93 19.2502C23.7408 20.4227 23.3771 22.3892 23.1211 23.6322C22.6907 25.7101 22.687 25.9624 23.0655 26.8751C23.5627 28.0662 24.5274 28.4335 25.4884 27.799C26.3047 27.261 27.1173 24.6711 27.6924 20.7715C27.7629 20.2891 27.915 19.8476 28.0337 19.7696C28.1525 19.6917 29.0578 19.7103 30.0708 19.8067L31.8926 19.9849L33.1838 22.0107C33.8925 23.1239 34.6458 24.3112 34.8535 24.6452C35.4435 25.595 36.9573 26.133 37.9183 25.7323C39.7216 24.9865 39.6363 23.7064 37.6141 21.1759C36.7459 20.0887 36.0186 19.0647 36.0186 18.9348C36.0186 18.8049 36.8052 18.1742 37.7662 17.5397C41.2615 15.2281 42.2299 13.9665 42.549 11.2987C42.7531 9.60305 42.1445 6.75714 41.2318 5.16907C40.9127 4.61622 40.1706 3.80734 39.588 3.38435C38.538 2.62 38.4749 2.61258 34.271 2.52353C31.9408 2.47529 29.5587 2.5495 29.0022 2.68679L29.0059 2.6905ZM37.718 6.48999C38.8385 7.61054 39.1614 10.9722 38.282 12.3413C37.6067 13.3914 34.3489 15.3765 32.8313 15.6622C31.0837 15.9924 28.85 15.7475 28.6422 15.2058C28.5643 14.998 28.6237 14.204 28.7758 13.4471C28.928 12.6864 29.2656 10.7236 29.5216 9.07987L29.9891 6.09297H33.6476C36.6123 6.09297 37.384 6.17089 37.7106 6.49741L37.718 6.48999Z"
        fill="black"
      />
      <Path
        d="M139.852 3.1208C136.676 4.6829 135.618 6.41567 135.607 10.0853C135.6 12.1854 135.655 12.4414 136.342 13.3505C137.076 14.3263 139.117 15.97 140.891 17.009C141.421 17.3206 142.512 17.5952 143.599 17.6954C145.903 17.9032 146.293 18.111 146.505 19.2538C146.735 20.4708 146.219 21.7101 145.243 22.3038C144.438 22.7898 142.508 22.7824 140.887 22.2815C139.217 21.7658 137.633 23.083 137.985 24.697C138.293 26.1144 139.748 26.8268 142.687 27.0049C144.891 27.1385 145.325 27.0865 146.56 26.5522C148.126 25.8769 149.848 24.1404 150.634 22.4448C151.61 20.3447 151.076 17.1722 149.451 15.4061C148.46 14.33 146.408 13.5323 144.634 13.5323C143.128 13.5323 142.939 13.4544 141.239 12.178C139.796 11.0908 139.44 10.6827 139.44 10.115C139.44 8.82375 139.971 7.44717 140.679 6.89061C141.692 6.09657 142.831 6.21902 144.505 7.29876C146.174 8.37849 146.334 8.40075 147.239 7.68464C148.215 6.91287 148.137 6.08915 146.954 4.67177C145.74 3.21727 144.783 2.70894 142.883 2.50116C141.648 2.36387 141.214 2.45663 139.844 3.12822L139.852 3.1208Z"
        fill="black"
      />
      <Path
        d="M243.024 2.62358C242.72 2.74974 242.304 3.06141 242.052 3.35083C241.488 4.00015 239.851 9.32834 239.287 12.3412C239.043 13.6584 238.679 15.4469 238.475 16.3411C238.274 17.2279 238.107 18.3113 238.107 18.7343C238.107 19.1573 237.851 20.3669 237.532 21.4318C236.352 25.3723 237.551 26.1849 244.463 26.1589C247.35 26.1478 248.43 25.7322 250.107 23.992C251.346 22.7045 252.074 21.0274 252.3 18.9272C252.515 16.9533 252.133 15.8327 250.897 14.7938C250.367 14.3486 250.174 14.022 250.367 13.9033C250.946 13.5434 251.947 10.7754 252.103 9.10201C252.385 6.08913 250.871 3.77011 247.988 2.78684C246.697 2.3453 243.914 2.25625 243.024 2.62729V2.62358ZM247.606 6.87945C248.823 7.62525 249.035 9.10201 248.23 11.2021C247.907 12.0481 247.506 12.1928 244.972 12.3857L242.894 12.5453V11.5583C242.894 10.4192 243.651 7.16145 244.033 6.67167C244.437 6.1485 246.619 6.27465 247.606 6.87945ZM245.937 16.9867C248.037 17.7176 248.152 17.8438 247.929 19.1796C247.513 21.6507 246.823 22.1442 243.758 22.1442C242.567 22.1442 241.525 22.0885 241.458 22.018C241.328 21.8845 241.762 17.2835 241.951 16.7752C242.141 16.2706 244.189 16.3782 245.937 16.9867Z"
        fill="black"
      />
      <Path
        d="M152.667 2.92784C150.894 3.42875 150.393 5.07247 151.754 5.92217C152.073 6.12253 153.287 6.36 154.452 6.45276C155.617 6.54181 156.611 6.66796 156.667 6.73104C156.723 6.79412 156.574 8.32653 156.337 10.1484C156.07 12.2002 155.977 14.048 156.096 15.0239C156.366 17.2761 156.307 20.1146 155.951 22.2147C155.595 24.3148 155.814 25.2202 156.845 25.8992C157.743 26.4891 159.094 26.2146 159.761 25.3018C160.214 24.6821 160.259 24.1998 160.199 20.1999C160.166 17.7696 160.118 13.8662 160.099 11.5249L160.062 7.26905H162.507C164.785 7.26905 165.001 7.22082 165.661 6.56036C166.522 5.69954 166.581 4.64577 165.813 3.87771C165.156 3.22097 163.583 3.01318 157.532 2.78313C155.127 2.69037 153.302 2.74232 152.671 2.92413L152.667 2.92784Z"
        fill="black"
      />
      <Path
        d="M48.4299 3.22841C47.7212 3.76271 46.2444 11.3023 45.8326 16.4932C45.6842 18.393 45.3391 20.5784 45.0422 21.506C44.43 23.4317 44.3929 24.6376 44.9198 25.3315C45.2426 25.7545 45.7435 25.8213 48.9605 25.8769C50.9864 25.9103 52.9269 26.0587 53.2906 26.2072C54.8861 26.8565 56.2255 25.4131 55.3981 23.9401C54.8601 22.9828 53.5688 22.6154 50.6561 22.5932C48.4039 22.5746 48.1924 22.5301 48.3705 22.1183C48.6711 21.4096 49.005 19.3837 49.0198 18.1407C49.0273 17.5322 49.1571 16.9533 49.3018 16.8606C49.4503 16.7715 50.2257 16.8309 51.0383 16.9978C52.99 17.3986 55.8508 17.0943 56.5223 16.419C57.3164 15.6176 57.168 14.4154 56.207 13.8254C55.5057 13.395 55.0048 13.3468 52.4446 13.4692L49.4948 13.6102L49.6988 12.4451C49.8102 11.8107 49.9437 10.9201 49.992 10.4934C50.1998 8.69389 50.4076 7.26166 50.53 6.74591L50.6599 6.18934L54.3072 6.65686C56.3071 6.91288 58.1958 7.05387 58.4852 6.96853C60.0287 6.51957 60.5185 4.60498 59.294 3.80353C58.129 3.03918 49.3055 2.57166 48.4299 3.22841Z"
        fill="black"
      />
      <Path
        d="M105.315 4.00769C104.61 5.12082 104.083 9.78113 104.064 15.0759C104.049 19.5581 103.875 22.0107 103.378 24.6489C103.044 26.4224 105.185 27.5578 106.383 26.2443C107.426 25.1015 107.57 24.4708 107.619 20.8234L107.667 17.254L108.869 17.4061C109.526 17.4877 110.491 17.6658 110.995 17.7994C111.507 17.933 112.591 18.0406 113.426 18.0406C114.665 18.0406 115.077 17.9218 115.745 17.3653C116.832 16.4562 116.899 15.1501 115.897 14.3487C115.255 13.8366 114.832 13.7847 111.389 13.7847H107.589L107.5 12C107.452 11.013 107.444 9.51398 107.489 8.64574L107.563 7.07622L109.615 7.26546C110.754 7.36935 112.554 7.65134 113.667 7.89994C116.527 8.53443 117.856 8.48248 118.479 7.71071C119.095 6.95007 119.106 6.31559 118.516 5.6106C117.971 4.95756 115.411 4.40471 110.98 3.97801C109.437 3.82959 107.641 3.63665 106.955 3.5476C105.841 3.39918 105.674 3.44742 105.311 4.01511L105.315 4.00769Z"
        fill="black"
      />
      <Path
        d="M68.7928 4.33053C67.8652 5.10973 65.4719 10.2635 61.6242 19.7585C60.9675 21.38 59.884 23.9921 59.205 25.5839C58.192 27.966 58.0214 28.5968 58.2292 29.1793C58.5705 30.1403 60.0102 30.2813 60.9155 29.4391C61.2717 29.1088 61.5389 28.7267 61.524 28.5708C61.5092 28.4187 62.1362 27.0124 62.9154 25.4429L64.3328 22.5933L67.5498 22.5822C69.3234 22.5748 71.1192 22.5005 71.5496 22.4189C72.3251 22.2668 72.3437 22.2928 73.3492 24.8233C74.4549 27.6098 75.0597 28.2554 76.5142 28.2072C77.5754 28.1738 78.5661 27.1015 78.5661 25.9884C78.5661 25.5505 78.2024 24.3558 77.7423 23.2909C77.2897 22.2408 76.555 19.7585 76.1098 17.7846C75.6645 15.8106 74.8445 12.9313 74.2879 11.4026C73.7277 9.85909 73.082 7.84803 72.8446 6.89445C72.3696 4.9947 72.0654 4.4604 71.1971 4.00402C70.3103 3.5365 69.6239 3.62926 68.7928 4.33053ZM70.1582 13.6066C70.5738 14.7198 71.6609 18.9237 71.5533 19.009C71.5274 19.0313 70.2881 19.1055 68.8187 19.176L66.1361 19.3022L67.3902 16.7457C68.0841 15.3283 68.7965 13.8033 68.9857 13.3283C69.175 12.846 69.4161 12.4675 69.5311 12.4675C69.6462 12.4675 69.9245 12.9759 70.1619 13.6066H70.1582Z"
        fill="black"
      />
      <Path
        d="M232.108 4.86839C231.751 5.22459 231.451 5.6921 231.451 5.88134C231.451 6.35627 230.742 11.4544 230.397 13.4803C230.034 15.6064 229.399 17.5098 228.842 18.1406C228.212 18.8567 226.26 19.6359 225.095 19.6359C223.281 19.6359 222.88 18.8011 221.93 13.0017C221.603 11.0017 221.522 9.50272 221.641 7.68461C221.826 4.89807 221.685 4.49363 220.535 4.49363C219.455 4.49363 219.002 5.32848 218.639 7.98887C218.375 9.91458 218.416 10.8496 218.932 14.8532C219.6 20.0181 219.667 20.2704 220.694 21.4874C221.626 22.5894 222.613 23.0866 224.464 23.3798C225.677 23.5727 226.212 23.5208 227.529 23.0792C231.403 21.7843 232.764 20.1183 233.959 15.2057C234.312 13.7623 234.794 11.7698 235.043 10.7494C236.078 6.49356 236.082 5.92215 235.095 4.82386C234.345 3.98901 232.972 4.01499 232.111 4.87581L232.108 4.86839Z"
        fill="black"
      />
      <Path
        d="M125.722 5.3286C125.04 5.66625 124.275 6.99829 123.103 9.88501C121.782 13.1391 120.84 15.3282 118.747 20.0145C116.55 24.9271 116.309 25.8325 116.999 26.5746C117.259 26.8528 117.697 27.0829 117.975 27.0829C118.684 27.0829 119.519 26.6042 119.519 26.1961C119.519 26.0032 120.135 24.7268 120.873 23.3836C122.019 21.3057 122.316 20.9495 122.799 21.0646C123.11 21.1425 124.761 21.354 126.457 21.5321L129.548 21.8623L129.837 22.686C129.997 23.135 130.435 24.3965 130.798 25.4577C131.652 27.94 131.826 28.1404 133.091 28.1404C135.169 28.1404 135.481 26.0625 133.904 22.7194C133.444 21.7436 132.828 19.9329 132.535 18.6936C132.242 17.4469 131.362 15.0462 130.572 13.3246C129.025 9.95551 128.557 8.55297 128.55 7.24689C128.542 5.72561 127.01 4.68669 125.722 5.32489V5.3286ZM127.975 16.8495L128.505 17.9144L127.656 18.0035C127.195 18.0517 126.06 18.0183 125.173 17.9292L123.541 17.766L124.212 16.2447C124.58 15.4099 125.121 14.1965 125.414 13.5472L125.945 12.371L126.691 14.0815C127.099 15.0203 127.678 16.2707 127.971 16.8532L127.975 16.8495Z"
        fill="black"
      />
      <Path
        d="M257.84 26.5784C256.998 26.9123 256.367 28.4484 256.705 29.3352C257.198 30.6265 259.228 30.8268 260.04 29.6654C261.183 28.0329 259.636 25.8623 257.836 26.5784H257.84Z"
        fill="black"
      />
      <Path
        d="M68.218 36.7115C67.9508 36.9787 67.1976 37.9508 66.5334 38.8896C63.0642 43.7762 61.5392 47.29 60.8676 51.9577C60.4446 54.8964 60.4446 55.1895 60.875 57.1226C61.8917 61.705 63.6022 65.2262 65.7134 67.0815C67.0269 68.2354 68.8376 68.0128 69.4721 66.6214C70.0621 65.3338 69.9174 64.9257 67.8877 62.1392C67.3609 61.4156 66.4629 59.7459 65.8915 58.4287C64.8786 56.0911 64.8526 55.9613 64.8526 52.8445C64.8526 50.032 64.9416 49.4012 65.6058 47.5275C66.5 44.9932 69.6205 38.9304 70.3478 38.3033C70.9822 37.7579 70.9822 37.2236 70.3478 36.6559C69.7133 36.0845 68.8228 36.1104 68.2217 36.7152L68.218 36.7115Z"
        fill="black"
      />
      <Path
        d="M197.953 39.446C197.315 40.0842 197.645 41.446 199.248 44.7371C201.356 49.0672 202.105 51.0931 202.179 52.6366C202.269 54.5661 200.187 62.4136 198.751 65.5527C197.627 68.009 197.567 68.2539 197.857 69.2C198.22 70.3911 199.389 71.0181 200.51 70.6248C202.161 70.046 203.756 66.061 204.658 60.2876C205.01 58.0353 205.43 55.694 205.597 55.0967C206.194 52.9223 205.393 49.5755 203.307 45.5237C202.194 43.3605 201.942 42.9153 200.569 40.663C199.705 39.2457 198.647 38.7522 197.953 39.446Z"
        fill="black"
      />
      <Path
        d="M74.4999 40.919C73.9544 41.1305 73.4609 41.8281 73.4609 42.3846C73.4609 43.3605 74.3477 43.8317 76.8782 44.2064C78.8522 44.4959 79.2863 44.6443 79.1824 44.9819C79.1119 45.2083 78.9338 46.3103 78.7854 47.4011C78.3958 50.2879 77.7947 52.9965 77.0341 55.2524C76.1992 57.7347 76.1547 59.5528 76.9154 60.3691C77.6055 61.1149 78.5331 61.1038 79.2344 60.3431C79.9505 59.5639 81.2714 54.3656 81.8539 50.0355C82.0988 48.2137 82.4068 46.2657 82.5404 45.7166L82.7816 44.7148L84.8334 44.8855C87.3417 45.0932 88.7516 44.6294 89.0374 43.5015C89.2674 42.6035 88.9594 42.0878 87.7944 41.4051C86.9669 40.919 86.3399 40.8633 80.8929 40.8188C77.6018 40.7891 74.7225 40.8374 74.4999 40.9264V40.919Z"
        fill="black"
      />
      <Path
        d="M165.717 42.9858C164.452 43.3791 163.617 44.0173 162.897 45.1415C162.537 45.7018 162.459 47.0153 162.363 53.9538C162.3 58.436 162.322 62.332 162.415 62.6029C162.508 62.8811 162.834 63.1891 163.161 63.3116C163.717 63.5193 165.157 63.1891 165.209 62.8403C165.224 62.7439 165.279 62.0686 165.339 61.3339C165.394 60.6029 165.576 59.1559 165.743 58.1169L166.047 56.232L167.806 56.258C169.32 56.2803 169.843 56.1504 171.569 55.3341C174.025 54.1653 175.416 52.9891 175.832 51.7276C176.34 50.184 176.002 47.6238 175.027 45.6239C174.311 44.1546 173.999 43.8021 173.086 43.4051C171.546 42.7372 167.287 42.496 165.717 42.9858ZM170.834 47.0227C171.491 47.212 172.229 49.8278 171.836 50.5625C171.171 51.8018 167.168 53.0114 166.626 52.132C166.378 51.7276 166.4 47.2713 166.652 46.8632C166.804 46.622 167.327 46.5812 168.619 46.7111C169.58 46.8075 170.585 46.9485 170.834 47.019V47.0227Z"
        fill="black"
      />
      <Path
        d="M94.2129 43.2195C93.8456 43.6647 92.8549 47.4753 92.8549 48.4364C92.8549 48.8111 92.3762 51.2489 91.79 53.8573C91.2037 56.4657 90.7251 58.9814 90.7251 59.4526C90.7251 60.6437 91.393 61.4785 92.3466 61.4785C93.5636 61.4785 94.0571 60.7439 94.4281 58.3877C94.9328 55.1968 95.1368 54.4287 95.4596 54.4992C95.6266 54.5363 96.877 54.6884 98.2202 54.8369C101.663 55.219 101.905 55.297 101.741 55.9834C101.181 58.3135 100.936 60.3914 101.137 61.1483C101.556 62.7438 103.433 63.3412 104.472 62.2132C104.943 61.7012 105.055 61.1372 105.185 58.6809C105.27 57.0594 105.444 54.7515 105.578 53.5011C105.712 52.2544 105.801 49.5235 105.782 47.3974L105.745 43.5497L105.01 43.2826C104.402 43.0636 104.187 43.1082 103.756 43.5497C103.252 44.0692 103.185 44.4884 102.799 49.5755C102.621 51.9316 102.521 52.1134 101.667 51.657C101.404 51.516 100.009 51.3305 98.5653 51.2489C97.1219 51.1635 95.8863 51.0411 95.8158 50.9706C95.7453 50.9001 95.8418 49.223 96.031 47.249C96.3019 44.3883 96.3056 43.572 96.0347 43.2492C95.634 42.7668 94.5951 42.752 94.2092 43.2232L94.2129 43.2195Z"
        fill="black"
      />
      <Path
        d="M187.267 43.7168C186.458 43.8318 185.141 44.2585 184.328 44.6629C182.974 45.342 182.777 45.5609 181.772 47.5385C181.037 48.9819 180.581 50.3214 180.365 51.6534C180.191 52.7406 179.917 53.9316 179.757 54.2989C179.597 54.67 179.442 55.9835 179.416 57.2228C179.382 58.7181 179.174 60.0316 178.796 61.1224C178.087 63.1743 178.058 64.4433 178.707 65.0295C179.649 65.8792 181.137 65.3004 181.137 64.0834C181.137 63.7903 181.579 62.3617 182.113 60.9184C183.07 58.3359 183.104 58.284 183.95 58.284C185.093 58.284 188.94 57.5604 190.51 57.0521C192.892 56.2803 194.673 54.3583 195.511 51.6497C196.235 49.3195 195.652 47.0636 193.875 45.3234C192.302 43.7836 190.254 43.2827 187.267 43.7131V43.7168ZM189.976 48.2955C190.38 48.3845 190.903 48.7556 191.141 49.1192C191.512 49.6869 191.526 49.9429 191.241 50.9558C191.055 51.6126 190.643 52.3844 190.302 52.7035C189.656 53.3083 187.746 53.8908 185.441 54.1765L183.972 54.3583L184.15 53.0671C184.343 51.6497 185.415 49.2416 186.102 48.6776C186.491 48.3548 188.432 47.9504 188.989 48.0728C189.13 48.1025 189.571 48.2027 189.976 48.2917V48.2955Z"
        fill="black"
      />
      <Path
        d="M148.571 44.4997C147.684 45.2863 146.749 46.9931 145.101 50.8408C144.693 51.7907 143.714 53.7535 142.909 55.2229C142.103 56.6922 140.99 58.7329 140.415 59.783C139.198 62.013 139.091 63.1446 140.033 63.7606C140.437 64.024 140.905 64.1242 141.25 64.0129C141.558 63.9127 142.627 62.5807 143.654 61.0111L145.502 58.1875L148.971 58.1764L152.441 58.1652L152.96 59.924C153.242 60.885 153.48 61.8089 153.48 61.961C153.48 62.1132 153.776 62.5955 154.162 63.0482C155.101 64.1613 156.626 64.2207 157.453 63.1743C157.754 62.7959 158.014 62.1948 158.014 61.8831C158.014 61.2226 155.917 54.9965 154.211 50.5885C153.554 48.8929 152.823 46.8336 152.571 45.9802C152.173 44.637 151.995 44.3847 151.201 44.0544C149.91 43.5127 149.632 43.561 148.567 44.4997H148.571ZM150.088 51.9688C150.333 52.5773 150.619 53.4753 150.708 53.9205L150.871 54.7442L149.187 54.8963C148.248 54.9817 147.469 55.0151 147.428 54.9706C147.309 54.8518 149.302 50.8408 149.48 50.8408C149.561 50.8408 149.843 51.3603 150.088 51.9688Z"
        fill="black"
      />
      <Path
        d="M116.031 44.199C115.244 44.3141 114.276 44.6443 113.905 44.9263C113.107 45.5311 112.914 46.5366 111.968 54.9445L111.374 60.2355L111.96 61.3227C112.732 62.7587 113.226 63.0369 115.834 63.4933C119.86 64.1983 121.641 63.9163 122.331 62.4618C123.125 60.7921 121.533 59.8422 117.66 59.6679L114.951 59.5491V58.0278C114.951 57.2004 115.025 55.7867 115.114 54.9259L115.277 53.3416L117.556 53.6941C120.216 54.1022 122.802 53.9798 123.381 53.4121C123.641 53.156 123.722 52.7739 123.607 52.3435C123.459 51.7832 123.203 51.6273 122.038 51.4047C121.277 51.26 119.504 51.0708 118.138 50.9854C116.769 50.9038 115.6 50.7999 115.563 50.7591C115.459 50.6441 115.407 49.646 115.444 48.4809L115.481 47.3418L116.747 47.505C122.142 48.2026 124.984 47.275 123.522 45.2899C122.676 44.1434 119.533 43.6833 116.038 44.1916L116.031 44.199Z"
        fill="black"
      />
    </Svg>
  );
};
