import { G, Path, Svg, SvgProps } from "react-native-svg";

export const CGroupIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 254.42 266.45" {...props}>
      <G id="Layer_1-2">
        <Path
          fill={props.fill ?? "#e1b228"}
          d="m118.5.16c-5.75.88-8.5,1.35-10.39,1.8-1.17.27-4.52.95-7.41,1.49-7.69,1.44-7.84,1.48-12.53,3.08-8.01,2.74-19.1,7.54-23.07,9.99-.54.33-2.75,1.68-4.94,3.03-2.2,1.35-5.51,3.24-7.42,4.25-5.02,2.64-6.34,3.6-10.52,7.67-2.07,2.02-4.97,4.73-6.44,6.02-4.89,4.3-13.13,14.1-17.79,21.17-9.73,14.74-13.26,24.83-16.84,48.13-.08.55-.24,2.26-.34,3.8-.1,1.55-.33,4.33-.5,6.25-.41,4.56-.41,15.6,0,20.78,1.65,20.63,2.89,26.88,8.98,44.99,3.03,9.01,7.53,21.57,8.63,24.08,3.82,8.7,9.91,17.88,16.65,25.08,1.2,1.29,2.6,2.83,3.12,3.45,4,4.76,11.04,11.42,16.16,15.27,5.39,4.05,7.25,4.99,13.12,6.61.95.26,3.58,1,5.84,1.65,8.66,2.48,10.17,2.86,13.69,3.49,4.9.88,10.93,1.73,15.16,2.14,1.09.11,3.3.42,4.93.69,4.43.75,11.05,1.14,22.2,1.32,9.32.15,10.12.11,12.06-.51,3.26-1.04,5.13-1.38,8.32-1.51,1.98-.08,3.77-.39,5.43-.94,3.89-1.29,10.45-3.67,13.34-4.84,3.45-1.4,6.72-2.47,9.57-3.14,6.78-1.59,12.43-4.26,19.21-9.07,1.13-.8,3-2.1,4.11-2.85,1.13-.77,2.63-1.81,3.36-2.34.72-.52,2.29-1.53,3.45-2.22,2.95-1.74,3.26-2,7.6-6.22,2.1-2.05,4.79-4.48,6.01-5.44,1.35-1.06,2.21-1.99,2.21-2.37,0-1.34,1.34-4.05,3.06-6.18,1.86-2.31,7.1-9.64,9.21-12.87,4.05-6.22,8.9-15.79,9.48-18.68.15-.73.79-3.12,1.44-5.35.65-2.23,1.4-5.4,1.68-7.09.28-1.68,1.26-6.33,2.18-10.31,2.2-9.51,2.83-12.65,3.15-15.63.14-1.37.3-8.55.34-15.99.04-7.44.19-17.75.32-22.93.22-8.7.05-13.14-.72-18.43-.25-1.73-1.33-6.9-1.72-8.23-.18-.63-.72-2.5-1.19-4.1-.47-1.62-1.71-5.54-2.78-8.74-1.06-3.2-2.02-6.24-2.13-6.78-.94-4.58-1.26-5.47-2.89-8.1-.96-1.54-2.08-3.25-2.49-3.8-.41-.55-1.14-1.73-1.6-2.61-1.4-2.64-4.94-7.83-6.24-9.17-.68-.7-1.86-1.65-2.64-2.15-.77-.49-2.83-2.2-4.52-3.76-4.29-3.96-3.99-3.73-11.08-8.44-1.04-.69-2.55-1.59-3.36-2-.82-.42-1.77-1.02-2.13-1.34-.92-.82-4.71-2.68-8.29-4.06-1.66-.64-4.18-1.83-5.62-2.64-5.09-2.89-8.85-4.76-9.98-4.97-2.26-.41-9.42-2.89-11.71-4.05-6.74-3.43-9.61-4.01-20.85-4.24-10.86-.22-15.78-.44-18.87-.83-3.48-.44-10.84-1.3-12.21-1.43-.65-.06-1.88,0-2.79.14h0Z"
        />
        <Path
          fill="black"
          d="m117.03,48.32c-2.52.35-13.72,3.82-17.08,5.29-4.55,1.99-7.57,3.73-10.58,6.08-11.74,9.17-16.61,14.71-22.39,25.47-.7,1.31-1.92,3.58-2.7,5.02-5.06,9.42-6.29,12.9-8.1,22.89-.45,2.45-1.05,5.72-1.34,7.25-1.05,5.52-.69,21.22.69,29.7,1.45,8.92,2.59,14.84,3.16,16.39.75,2.04,3.12,6.81,3.96,8.01.32.45,1.42,2.35,2.45,4.24,2.13,3.89,3.24,5.39,6.64,8.92,1.3,1.35,2.89,3.15,3.49,3.96,3.96,5.31,11.3,10.46,17.77,12.49,1.17.37,4.52,1.56,7.41,2.64,9.17,3.44,10.51,3.74,18.46,4.16,4.09.22,7.28.6,11.4,1.37,2.15.4,4.24.5,8.41.39,5.37-.14,5.64-.18,8.02-1.21,1.35-.58,3.37-1.57,4.46-2.18,1.11-.62,3.53-1.73,5.45-2.49,3.77-1.5,5.49-2.66,9.42-6.35,1.44-1.36,3.4-3.17,4.4-4.07,2.07-1.88,6.56-7.01,9.5-10.87,2.01-2.64,5.38-8.05,5.38-8.63,0-.15.56-1.16,1.24-2.23,1.12-1.76,2.06-3.56,2.06-3.96,0-.07-.86-.68-1.93-1.37-1.07-.69-3.75-2.53-6.01-4.12-6.4-4.52-9.82-6.53-12.84-7.56-1.54-.52-3.68-1.39-4.77-1.93l-1.98-.97-1.68,1.73c-.92.95-2.56,2.42-3.63,3.26-1.08.85-3.42,2.88-5.24,4.55-4.78,4.36-7.41,5.77-12.97,6.94-7.44,1.57-8.71,1.69-15.54,1.53-6.88-.16-7.64-.31-11.96-2.34-1.68-.79-2.07-1.2-4.24-4.4-1.31-1.95-2.71-3.9-3.1-4.35-1.5-1.71-3.22-5.46-4.44-9.67-.69-2.39-1.66-5.59-2.15-7.13-1.16-3.59-1.47-5.87-1.7-12.43-.16-4.65-.1-5.64.5-7.97.74-2.88,2.57-7.94,4-11.08,1.29-2.83,5.51-8.29,8.12-10.54,2.51-2.16,7.13-5.25,9.23-6.18,3.56-1.58,13.75-3.08,20.56-3.03,3.54.03,4.08.12,7.91,1.39,5.87,1.95,7.23,2.81,8.86,5.57.72,1.22,1.78,2.67,2.35,3.22.57.54,1.24,1.46,1.48,2.02l.44,1.04,3.08-.75c4.21-1.03,5.96-1.61,11.24-3.68,2.48-.98,4.65-1.78,4.8-1.78.31,0,3.83-1.37,5.84-2.27.72-.32,1.81-.7,2.4-.83.85-.18,1.04-.35.86-.78-.12-.3-.72-2.24-1.34-4.35-1.02-3.46-1.98-6.15-3.81-10.74-.36-.9-1.51-3.25-2.57-5.26s-2.53-5.05-3.3-6.81c-.94-2.17-2.02-3.96-3.38-5.6-2.49-3.01-5.23-5.87-5.63-5.87-.17,0-.82-.38-1.41-.84-1.66-1.27-5.74-3.12-6.88-3.12-.62,0-2.32-.75-4.46-1.95-3.64-2.06-5.15-2.52-11.21-3.44-4.24-.64-21.28-.89-25.05-.37h0Z"
        />
      </G>
    </Svg>
  );
};
