import { Text } from "tamagui";
import React from "react";
import { Header } from "../../components/design-system/Header";

import { EventsList } from "./EventsList";

export const Events = () => {
  return (
    <>
      <Header
        title="BC Events"
        headerRight={<Text>Rides by Breakfast Club</Text>}
      />

      <EventsList />
    </>
  );
};
