import { Modal } from "react-native";
import { rideAtom } from "../atoms";
import { useAtom } from "jotai";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Label, RadioGroup, Text, View, XStack, YStack } from "tamagui";
import { HeaderButton } from "../../design-system/Button/HeaderButton";
import { ArrowLeftIcon, LinkIcon } from "../../design-system/icons";

import { Globe2 } from "@tamagui/lucide-icons";

type VisibilityModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const VisibilityModal = ({ isOpen, onClose }: VisibilityModalProps) => {
  const [ride, setRide] = useAtom(rideAtom);

  const { top } = useSafeAreaInsets();
  return (
    <Modal
      visible={isOpen}
      animationType="fade"
      transparent={false}
      onRequestClose={onClose}
    >
      <View flex={1} paddingTop={top} bg="$brandParchment">
        <View
          px="$4"
          pb="$4"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <XStack
            justifyContent="space-between"
            w="100%"
            mb="$5"
            alignItems="flex-start"
          >
            <View>
              <HeaderButton onPress={() => onClose()}>
                <ArrowLeftIcon color="black" width={16} height={16} />
              </HeaderButton>
            </View>
          </XStack>
        </View>
        <View px="$4" flex={1}>
          <Text fontSize={16} fontFamily="$heading" textTransform="uppercase">
            Who can see this ride?
          </Text>

          <RadioGroup
            value={ride?.visibility ?? ""}
            onValueChange={(val) => {
              setRide({
                ...ride,
                visibility: val as any,
              });
              onClose();
            }}
          >
            <YStack>
              <XStack alignItems="center" justifyContent="space-between">
                <YStack>
                  <XStack alignItems="center" gap={"$2"}>
                    <Label fontSize={20}>Public</Label>
                    <Globe2 size={20} />
                  </XStack>
                  <Text fontSize={14}>Anyone can see it</Text>
                </YStack>

                <RadioGroup.Item
                  value="Public"
                  size="$5"
                  borderColor="black"
                  bg="white"
                >
                  <RadioGroup.Indicator />
                </RadioGroup.Item>
              </XStack>
            </YStack>

            <YStack>
              <XStack alignItems="center" justifyContent="space-between">
                <YStack>
                  <XStack alignItems="center" gap={"$2"}>
                    <Label fontSize={20}>Private</Label>
                    <LinkIcon width={20} />
                  </XStack>
                  <Text fontSize={14}>
                    Only people with the link can see it
                  </Text>
                </YStack>

                <RadioGroup.Item
                  value="Private"
                  size="$5"
                  borderColor="black"
                  bg="white"
                >
                  <RadioGroup.Indicator />
                </RadioGroup.Item>
              </XStack>
            </YStack>
          </RadioGroup>
        </View>
      </View>
    </Modal>
  );
};
