import { OnboardingNavigator } from "./OnboardingNavigator";
import { BaseNavigatorParamList } from "./BaseNavigator";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

const Stack = createNativeStackNavigator<BaseNavigatorParamList>();

export const UnauthenticatedNavigator = () => {
  return (
    <Stack.Navigator
      initialRouteName={"Onboarding"}
      screenOptions={{ headerShown: false, title: "Onboarding" }}
    >
      <Stack.Screen name="Onboarding" component={OnboardingNavigator} />
    </Stack.Navigator>
  );
};
