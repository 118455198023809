import { atom } from "jotai";
import { iProfile } from "../types";

const defaultProfileState: iProfile = {
  name: "",
  bio: "",
  avatar_url: null,
  breakfast_food: "",
};

export const userProfileStateAtom = atom<iProfile>(defaultProfileState);
