import { SafeAreaView } from "react-native-safe-area-context";
import { Heading, ScrollView, Text, View, XStack, YStack } from "tamagui";
import { brandShadow, customColorTokens } from "../../../../tamagui.config";

import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useMemo } from "react";
import { supabase } from "../../../../lib/supabase";
import { Image, Linking } from "react-native";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import mixpanel from "../../../../mixpanel";
import Markdown from "react-native-markdown-display";
import { Loader } from "../../../components/design-system/Loader";
import { ClubNavigatorParamList } from "../../../navigators/ClubNavigator";
import {
  CloseIcon,
  CompassIcon,
  MapIcon,
  SmsIcon,
} from "../../../components/design-system/icons";
import { PhoneIcon } from "../../../components/design-system/icons/PhoneIcon";
import { ArrowRightIcon } from "../../../components/design-system/icons/ArrowRightIcon";
import parsePhoneNumber from "libphonenumber-js";
import { graphql } from "relay-hooks";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { PartnerQuery } from "./__generated__/PartnerQuery.graphql";

type PartnerProps = {
  queryRef: PreloadedQuery<PartnerQuery>;
};

export const Partner = ({ queryRef }: PartnerProps) => {
  const data = usePreloadedQuery<PartnerQuery>(PartnerQueryString, queryRef);
  const partner = data.partnersCollection?.edges[0]?.node;

  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  const imageUrl = useMemo(
    () =>
      partner?.image_url
        ? supabase.storage.from("partners").getPublicUrl(partner.image_url)
            ?.data?.publicUrl
        : null,
    [partner?.image_url]
  );

  const trackPartnerInfoPress = (type: string) => {
    mixpanel.track(type, { partner: partner?.name, partnerId: partner?.id });
  };

  const onPerkPress = (perkId: string, perkName: string) => {
    mixpanel.track("Partner Perk Pressed", {
      partner: partner?.name,
      partnerId: partner?.id,
      perk: perkName,
    });
    navigation.navigate("Perk", { perkId, partnerId: partner?.id ?? "" });
  };
  const phone = parsePhoneNumber(partner?.phone ?? "", "US");
  const formattedPhone = phone?.format("E.164");

  return (
    <ScrollView backgroundColor="$brandParchment">
      <SafeAreaView
        style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
      >
        <View
          px="$4"
          pb="$4"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <XStack justifyContent="space-between" w="100%">
            <View>
              <HeaderButton onPress={() => navigation.navigate("Profile")}>
                <CloseIcon width={16} height={16} />
              </HeaderButton>
            </View>
            <XStack space="$2">
              {partner?.url ? (
                <HeaderButton
                  onPress={() => {
                    trackPartnerInfoPress("Partner Website Pressed");
                    Linking.openURL(partner?.url ?? "");
                  }}
                >
                  <CompassIcon />
                </HeaderButton>
              ) : null}
              {partner?.phone && !partner.has_sms ? (
                <HeaderButton
                  onPress={() => {
                    trackPartnerInfoPress("Partner Phone Pressed");
                    Linking.openURL(`tel:${formattedPhone}`);
                  }}
                >
                  <PhoneIcon />
                </HeaderButton>
              ) : null}
              {formattedPhone && partner?.has_sms ? (
                <HeaderButton
                  onPress={() => {
                    trackPartnerInfoPress("Partner Sms Pressed");
                    Linking.openURL(`sms:${formattedPhone}`);
                  }}
                >
                  <SmsIcon />
                </HeaderButton>
              ) : null}
              {partner?.address ? (
                <HeaderButton
                  onPress={() => {
                    trackPartnerInfoPress("Partner Address Pressed");
                    Linking.openURL(
                      `http://maps.apple.com/?address=${partner.address}`
                    );
                  }}
                >
                  <MapIcon />
                </HeaderButton>
              ) : null}
            </XStack>
          </XStack>
        </View>

        <YStack mt="$4" gap="$4" px="$4" flex={1}>
          <View minHeight={100} justifyContent="center" alignItems="center">
            {partner?.image_url && imageUrl ? (
              <Image
                style={{
                  width: "100%",
                  objectFit: "contain",
                  minHeight: 100,
                }}
                source={{ uri: imageUrl }}
                resizeMode="contain"
              />
            ) : partner?.image_url ? (
              <Loader />
            ) : (
              <Heading fontSize="$3" textTransform="uppercase">
                {partner?.name}
              </Heading>
            )}
          </View>

          <Markdown
            style={{
              heading3: {
                fontSize: 20,
                fontWeight: "bold",
                textTransform: "uppercase",
              },
              paragraph: {
                fontSize: 16,
                marginBottom: 16,
                fontFamily: "Souvenir",
              },
            }}
          >
            {partner?.description ?? ""}
          </Markdown>
          <Text fontFamily="$heading" fontSize={16} textTransform="uppercase">
            Perks
          </Text>
          {partner?.perksCollection?.edges.map((perk, index) => (
            <View
              borderWidth={1}
              borderColor="black"
              borderRadius={15}
              bg="white"
              pos="relative"
              pressStyle={{
                scaleX: 0.99,
                scaleY: 0.99,
                shadowOffset: { height: 1, width: 1 }, // Reduced shadow offset for pressed effect
                shadowOpacity: 0.7, // Lower opacity for a softer shadow when pressed
                // Keep the shadow radius unchanged or adjust slightly if needed
              }}
              key={index}
              onPress={() => onPerkPress(perk.node.id, perk.node.name ?? "")}
              {...brandShadow}
              justifyContent="center"
            >
              <XStack
                alignItems="center"
                justifyContent="space-between"
                px="$4"
                py="$5"
              >
                <Text fontFamily="$heading">{perk.node.name}</Text>
                <ArrowRightIcon width={20} height={20} />
              </XStack>
            </View>
          ))}
        </YStack>
      </SafeAreaView>
    </ScrollView>
  );
};

export const PartnerQueryString = graphql`
  query PartnerQuery($id: BigInt!) {
    partnersCollection(filter: { id: { eq: $id } }, first: 1) {
      edges {
        node {
          id
          name
          description
          url
          phone
          has_sms
          address
          image_url
          perksCollection {
            edges {
              node {
                id
                name
                code
              }
            }
          }
        }
      }
    }
  }
`;
