import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  Landing,
  Phone,
  Code,
  Profile,
  Name,
  BreakfastFood,
  Avatar,
} from "../screens/Onboarding";
import { useLayoutEffect } from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { BaseNavigatorParamList } from "./BaseNavigator";
import { useAtomValue } from "jotai";
import { isAuthenticatedAtom } from "../../atoms/global";

export type OnboardingNavigatorParamList = {
  Landing: undefined;
  Phone: undefined;
  Code: { phone: string };
  Profile: undefined;
  Name: undefined;
  BreakfastFood: undefined;
  Avatar: undefined;
};

const Stack = createNativeStackNavigator<OnboardingNavigatorParamList>();

export const OnboardingNavigator = () => {
  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();
  const isAuthed = useAtomValue(isAuthenticatedAtom);

  useLayoutEffect(() => {
    if (isAuthed) {
      navigation.reset({
        index: 0,
        routes: [{ name: "Home" }],
      });
    } else {
      navigation?.navigate("Onboarding", { screen: "Landing" });
    }
  }, [isAuthed, navigation]);

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, title: "Onboarding" }}
    >
      <Stack.Screen name="Landing" component={Landing} />
      <Stack.Screen name="Phone" component={Phone} />
      <Stack.Screen name="Code" component={Code} />
      <Stack.Screen name="Name" component={Name} />
      <Stack.Screen name="BreakfastFood" component={BreakfastFood} />
      <Stack.Screen name="Avatar" component={Avatar} />
      <Stack.Screen name="Profile" component={Profile} />
    </Stack.Navigator>
  );
};
