import { Modal, Pressable } from "react-native";
import { useFragment } from "react-relay";
import { graphql } from "relay-hooks";
import { Heading, Text, View, YStack } from "tamagui";
import { EventDetailConfirmationModal_event$key } from "./__generated__/EventDetailConfirmationModal_event.graphql";
import { brandShadow } from "../../../../../tamagui.config";
import {
  CloseIcon,
  PartyGroupIcon,
} from "../../../../components/design-system/icons";
import { Button } from "../../../../components/design-system";
import { EventImage } from "../../components/EventImage";
import Avatar from "../../../../components/Avatar";
import { Role } from "../../../../features/Profile/types";
import { Event } from "../../../../hooks/useGetEvent";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  copyUrl: () => void;
  event: Event;
};

const exclamations = [
  "AWW YEAH",
  "HECK YES",
  "OH SNAP",
  "BOOYAH",
  "WOOT WOOT",
  "HOLY COW",
  "SWEET BEANS",
  "HOT DIGGITY DOG",
  "HELL YEAH",
  "YEEHAW",
  "YIPPEE",
  "YOWZA",
  "LET'S PARTY",
  "COOLIO",
];

function getRandomExclamation() {
  const randomIndex = Math.floor(Math.random() * exclamations.length);
  return exclamations[randomIndex];
}

export const EventDetailConfirmationModal = ({
  isOpen,
  onClose,
  copyUrl,
  event,
}: Props) => {
  const host = event.host;
  // const role = host ? host.user_rolesCollection?.edges[0]?.node.role : null;

  return (
    <Modal
      visible={isOpen}
      onRequestClose={() => {
        onClose();
      }}
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
      transparent={true}
      animationType="fade"
    >
      <View
        flex={1}
        justifyContent="center"
        alignItems="center"
        backgroundColor="rgba(0,0,0,0.5)"
        position="relative"
        p="$4"
        onPress={onClose}
        w="100%"
      >
        <View
          backgroundColor="$brandParchment"
          borderRadius={8}
          p="$5"
          gap="$4"
          width="100%"
          {...brandShadow}
        >
          <View justifyContent="center" alignItems="center" pos="relative">
            <Pressable
              onPress={onClose}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <CloseIcon width={20} height={20} />
            </Pressable>
            <Heading size="$2" textAlign="center" w="100%" mt={"$6"} mb="$4">
              {getRandomExclamation()}!
            </Heading>
            {event.type === "ClubRide" && host ? (
              <YStack justifyContent="center" alignItems="center">
                <Avatar
                  imageStyle={{
                    borderRadius: 999,
                  }}
                  size={80}
                  url={host.avatar_url ?? null}
                  // role={role as Role}
                />
                <Text fontSize={16} mt={4}>
                  {host.name}
                </Text>
              </YStack>
            ) : (
              <EventImage width={90} height={90} type={event.type ?? "PopUp"} />
            )}
          </View>

          <Text textAlign="center" fontSize={18}>
            Your spot is confirmed for {event.name} on {event.date}.
          </Text>
          {event.type === "ClubRide" ? (
            <Text textAlign="center" fontSize={14}>
              This event is a ride from the Breakfast Club community, not an
              official Breakfast Club Ride. Please use caution and ride at your
              own risk.
            </Text>
          ) : (
            <Text textAlign="center" fontSize={14}>
              Please use caution and ride at your own risk.
            </Text>
          )}

          <Button onPress={onClose} textAlign="center" fontStyle="italic">
            I GOT IT
          </Button>
        </View>
      </View>
    </Modal>
  );
};
