import { View } from "tamagui";
import { RouteProp, useRoute } from "@react-navigation/native";
import { ClubNavigatorParamList } from "../../navigators/ClubNavigator";
import { Suspense, useEffect } from "react";

import { Loader } from "../../components/design-system/Loader";
import { Member, MemberQueryString } from "./Member";
import { useQueryLoader } from "react-relay";
import { MemberQuery } from "./__generated__/MemberQuery.graphql";

export const MemberQueryRenderer = () => {
  const route = useRoute<RouteProp<ClubNavigatorParamList, "Member">>();
  const userId = route.params.id;

  const [queryRef, loadQuery] = useQueryLoader<MemberQuery>(MemberQueryString);

  useEffect(() => {
    if (userId) {
      loadQuery({ id: userId });
    }
  }, [loadQuery, userId]);

  return (
    <View bg="$brandParchment" flex={1}>
      <Suspense fallback={<Loader />}>
        {queryRef ? <Member queryRef={queryRef} /> : null}
      </Suspense>
    </View>
  );
};
