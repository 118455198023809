import { graphql } from "relay-hooks";
import {
  auth_tokensInsertInput,
  useCreateAuthTokenMutation,
} from "./__generated__/useCreateAuthTokenMutation.graphql";
import { useMutation } from "react-relay";

export const useCreateAuthToken = (
  callback: (token: auth_tokensInsertInput) => void
) => {
  const [createAuthToken, isUpdating] = useMutation<useCreateAuthTokenMutation>(
    useCreateAuthTokenMutationString
  );

  const handleCreateAuthToken = (input: auth_tokensInsertInput) => {
    createAuthToken({
      variables: {
        input: [input],
      },
      onCompleted: (res) => {
        if (res.insertIntoauth_tokensCollection?.records) {
          callback(res.insertIntoauth_tokensCollection.records[0]);
        }
      },
      onError: (err) => {
        console.error(err);
      },
    });
  };

  return [handleCreateAuthToken, isUpdating] as const;
};

export const useCreateAuthTokenMutationString = graphql`
  mutation useCreateAuthTokenMutation($input: [auth_tokensInsertInput!]!) {
    insertIntoauth_tokensCollection(objects: $input) {
      records {
        id
        provider
        code
        expires_at
        refresh_token
        token
      }
    }
  }
`;
