import { Pressable, SectionList } from "react-native";
import { customColorTokens } from "../../../../tamagui.config";
import { AnimatePresence, Text, View, XStack } from "tamagui";

import { format } from "date-fns";
import { EventCard } from "../../../components/design-system/EventCard";
import { paceOptions, terrainOptions } from "./Filters";
import { supabase } from "../../../../lib/supabase";
import { useEffect, useState } from "react";
import { useIsFocused } from "@react-navigation/native";

import { Event, Group } from "../../../features/Events/types";
import { Loader } from "../../../components/design-system/Loader";
import { Pill } from "../../../components/design-system/Pill";
import { CloseIcon } from "../../../components/design-system/icons";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";

export const EventsList = () => {
  const currentUser = useAtomValue(currentUserAtom);
  const [paceFilter, setPaceFilter] = useState<Group | null>(null);
  const [terrainFilter, setTerrainFilter] = useState<string | null>(null);

  const handlePacePress = (pace: string) => {
    setPaceFilter(paceFilter === pace ? null : (pace as Group));
  };

  const handleTerrainPress = (terrain: string) => {
    setTerrainFilter(terrain === terrainFilter ? null : terrain);
  };

  const [events, setEvents] = useState<Event[] | null>(null);

  const [friendsFilter, setFriendsFilter] = useState(false);

  const isFocused = useIsFocused();

  const [loading, setLoading] = useState(false);

  const today = new Date();
  const date = format(today, "yyyy-MM-dd");

  const buildBaseQuery = () => {
    return supabase
      .from("events")
      .select(`*, routes(*), profiles(avatar_url, name)`)
      .eq("type", "ClubRide")
      .order("date", { ascending: true })
      .filter("date", "gte", date)
      .in("terrain", terrainFilter ? [terrainFilter] : terrainOptions)
      .eq("visibility", "Public")
      .overlaps("groups", paceFilter ? [paceFilter] : paceOptions);
  };

  const getFriendsSubqueries = (userId: string) => {
    const friendsAsUser = supabase
      .from("friendships")
      .select("friend_id")
      .eq("user_id", userId)
      .eq("status", "accepted");

    const friendsAsFriend = supabase
      .from("friendships")
      .select("user_id")
      .eq("friend_id", userId)
      .eq("status", "accepted");

    return `hostId.in.(${friendsAsUser}),hostId.in.(${friendsAsFriend})`;
  };

  const fetchEvents = async () => {
    if (!isFocused) return;

    try {
      !events && setLoading(true);
      let query = buildBaseQuery();

      if (friendsFilter && currentUser?.id) {
        query = query.or(getFriendsSubqueries(currentUser.id));
      }

      const { data } = await query;
      const eventMap =
        (data?.map((event: any) => ({
          ...event,
          route: event.routes,
        })) as Event[]) ?? [];

      setEvents(eventMap);
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [isFocused, terrainFilter, paceFilter, friendsFilter]);

  const groupedData = events?.reduce((acc: any, curr) => {
    const eventMonthYear = format(new Date(curr.date), "MMMM yyyy");
    if (!acc[eventMonthYear]) {
      acc[eventMonthYear] = [];
    }
    acc[eventMonthYear].push(curr);
    return acc;
  }, {});

  const newSections = groupedData
    ? Object.keys(groupedData).map((key) => ({
        title: key,
        data: groupedData[key],
      }))
    : [];

  const renderSectionHeader = ({
    section: { title },
  }: {
    section: {
      title: string;
    };
  }) => (
    <View bg="$brandParchment" p="$4">
      <Text fontSize={16} fontFamily="$heading" textTransform="uppercase">
        {title}
      </Text>
    </View>
  );

  const renderItem = ({ item }: { item: any }) => {
    return (
      <View px="$4" mb="$4">
        <EventCard event={item} />
      </View>
    );
  };

  const renderPills = () => (
    <XStack
      gap="$2"
      bg="$brandParchment"
      pb="$2"
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="flex-start"
      px="$4"
    >
      <AnimatePresence>
        {/* Friends Pill */}
        <Pressable
          onPress={() => setFriendsFilter(!friendsFilter)}
          key="friends"
        >
          <Pill
            text="Friends"
            bg="white"
            borderColor={"black"}
            icon={
              friendsFilter ? (
                <CloseIcon fill="black" color="black" width={12} height={12} />
              ) : null
            }
          />
        </Pressable>

        {/* Terrain Pills */}
        {terrainOptions.map((t) => {
          const isSelected = terrainFilter === t;
          const shouldShow = terrainFilter === null || isSelected;
          if (!shouldShow) return null;

          return (
            <Pressable
              onPress={() => handleTerrainPress(t)}
              key={`terrain-${t}`}
            >
              <Pill
                text={t}
                bg={"white"}
                color={"black"}
                borderColor={"black"}
                icon={
                  isSelected ? (
                    <CloseIcon
                      fill="black"
                      color="black"
                      width={12}
                      height={12}
                    />
                  ) : null
                }
              />
            </Pressable>
          );
        })}

        {/* Pace Pills */}
        {paceOptions.map((p) => {
          const isSelected = paceFilter === p;
          const shouldShow = paceFilter === null || isSelected;
          if (!shouldShow) return null;

          return (
            <Pressable onPress={() => handlePacePress(p)} key={`pace-${p}`}>
              <Pill
                text={p}
                bg={"white"}
                color={"black"}
                borderColor={"black"}
                icon={
                  isSelected ? (
                    <CloseIcon
                      fill="black"
                      color="black"
                      width={12}
                      height={12}
                    />
                  ) : null
                }
              />
            </Pressable>
          );
        })}
      </AnimatePresence>
    </XStack>
  );

  return (
    <View flex={1}>
      {loading ? <Loader /> : null}
      <View pt="$4" bg="$brandParchment">
        {renderPills()}
      </View>
      <SectionList
        sections={newSections}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        stickySectionHeadersEnabled={true}
        style={{ backgroundColor: customColorTokens.brandParchment }}
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        ListEmptyComponent={
          <View
            flex={1}
            justifyContent="center"
            alignItems="center"
            h="100%"
            minHeight={300}
            gap="$4"
          >
            <Text
              fontSize={20}
              textAlign="center"
              px="$8"
            >{`Looks like there aren't any rides! Try hosting one yourself!`}</Text>
          </View>
        }
      />
    </View>
  );
};
