import { Image } from "tamagui";
import { EventType } from "../../../features/Events/types";

export const EventImage = ({
  width = 100,
  height = 100,
  type,
}: {
  width?: number;
  height?: number;
  type: EventType;
}) => {
  return (
    <>
      {type === "CentralMachineWorks" ? (
        <Image
          resizeMethod="scale"
          resizeMode="contain"
          style={{ objectFit: "contain", width, height }}
          source={require("../../../../assets/cmw.png")}
        />
      ) : null}

      {type === "TheMeteorCafe" ? (
        <Image
          resizeMethod="scale"
          resizeMode="contain"
          style={{ objectFit: "contain", width, height }}
          source={require("../../../../assets/meteor.png")}
        />
      ) : null}

      {type === "GravelGang" ? (
        <Image
          resizeMethod="scale"
          resizeMode="contain"
          source={require("../../../../assets/gravelgang.png")}
          style={{
            objectFit: "contain",
            width: width,
            height: 75,
          }}
        />
      ) : null}
      {type === "PopUp" ? (
        <Image
          resizeMethod="scale"
          resizeMode="contain"
          source={require("../../../../assets/popup.png")}
          style={{
            objectFit: "contain",
            width: width,
            height: height,
          }}
        />
      ) : null}
      {type === "Babes" ? (
        <Image
          source={require("../../../../assets/babes.png")}
          resizeMethod="scale"
          resizeMode="contain"
          style={{
            objectFit: "contain",
            width: width,
            height: 50,
          }}
          borderWidth={1}
          borderColor={"black"}
        />
      ) : null}
      {type === "SwimClub" ? (
        <Image
          source={require("../../../../assets/swimclub.png")}
          resizeMethod="scale"
          resizeMode="contain"
          style={{
            objectFit: "contain",
            width: width,
            height: 50,
          }}
          borderWidth={1}
          borderColor={"black"}
        />
      ) : null}
    </>
  );
};
