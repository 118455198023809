import { View } from "tamagui";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";

import { ClubNavigatorParamList } from "../../../../navigators/ClubNavigator";

import mixpanel from "../../../../../mixpanel";
import { MemberListItem } from "../../../../components/MemberListItem";
import { usePaginationFragment } from "react-relay";
import { graphql } from "relay-hooks";
import { MembersList$key } from "./__generated__/MembersList.graphql";
import { FlashList } from "@shopify/flash-list";

type MembersListProps = {
  refreshMembers: () => void;
  refreshing: boolean;
  fragmentRef: MembersList$key;
};
export const MembersList = ({ fragmentRef }: MembersListProps) => {
  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment(
    MembersListFragment,
    fragmentRef
  );

  const navigateToMember = (userId: string) => {
    mixpanel.track("Member Profile View", { userId });

    navigation.navigate("Member", { id: userId });
  };

  return (
    <FlashList
      contentContainerStyle={{ paddingBottom: 100 }}
      onEndReached={() => hasNext && loadNext(20)}
      keyExtractor={(item) => item.node.id}
      showsVerticalScrollIndicator={false}
      ItemSeparatorComponent={() => <View h={1} bg="$gray4" />}
      data={data?.profilesCollection?.edges ?? []}
      onEndReachedThreshold={0.25}
      scrollEventThrottle={500}
      estimatedItemSize={67}
      refreshing={isLoadingNext}
      renderItem={({ item }) => {
        const role =
          item.node.user_rolesCollection?.edges?.[0]?.node?.role ?? "Member";
        const member = item.node;
        return (
          <MemberListItem
            id={member.id ?? ""}
            name={member.name ?? ""}
            role={role}
            avatarUrl={member.avatar_url ?? null}
            onPress={navigateToMember}
          />
        );
      }}
    />
  );
};

export const MembersListFragment = graphql`
  fragment MembersList on Query
  @refetchable(queryName: "MembersListRefetchQuery") {
    profilesCollection(
      filter: { name: { ilike: $searchTerm } }
      orderBy: { name: AscNullsLast }
      first: $first
      after: $after
      last: $last
      before: $before
    ) @connection(key: "MembersListFragment_profilesCollection") {
      edges {
        node {
          id
          name
          avatar_url

          user_rolesCollection {
            edges {
              node {
                role
              }
            }
          }
        }
      }
    }
  }
`;

export const MembersListQueryString = graphql`
  query MembersListQuery(
    $searchTerm: String
    $after: Cursor
    $before: Cursor
    $first: Int
    $last: Int
  ) {
    ...MembersList
  }
`;
