import { useEffect, useState } from "react";
import Carousel from "react-native-reanimated-carousel";
import { Text, View, YStack } from "tamagui";
import { sanityClient } from "../../../../../sanityClient";
import { Dimensions, Image } from "react-native";

type RideRule = {
  title: string;
  imageUrl?: string;
  subtitle?: string;
};

export const TableManners = () => {
  const [rules, setRules] = useState<RideRule[]>([]);
  const screenWidth = Dimensions.get("window").width;

  useEffect(() => {
    sanityClient
      .fetch('*[_type == "rule"]{title, subtitle,"imageUrl": image.asset->url}')
      .then((data) => setRules(data));
  }, [setRules]);

  return (
    <YStack flex={1} position="relative">
      <Text
        px="$4"
        fontFamily="$heading"
        fontSize={16}
        textTransform="uppercase"
        my="$4"
      >
        Table Manners
      </Text>
      <Carousel
        width={screenWidth}
        height={300}
        style={{
          width: screenWidth,
        }}
        loop
        pagingEnabled={true}
        snapEnabled={true}
        autoPlay={true}
        autoPlayInterval={3000}
        mode="parallax"
        modeConfig={{
          parallaxScrollingScale: 0.9,
          parallaxScrollingOffset: 50,
        }}
        data={rules}
        renderItem={({ item, index }: { item: RideRule; index: number }) => (
          <View
            w={screenWidth}
            key={index}
            justifyContent="center"
            alignItems="center"
            py="$8"
            flexDirection="column"
            flex={1}
          >
            <Image
              source={{ uri: item.imageUrl }}
              style={{
                objectFit: "contain",
                width: 200,
                height: 200,
                marginBottom: 10,
              }}
            />
            <Text
              fontFamily="$heading"
              textAlign="center"
              fontSize={18}
              textTransform="uppercase"
              maxWidth={"80%"}
            >
              {item.title}
            </Text>
            <Text textAlign="center" fontSize={16} maw="80%">
              {item.subtitle}
            </Text>
          </View>
        )}
      />
    </YStack>
  );
};
