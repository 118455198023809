import { KeyboardAvoidingView } from "react-native";
import { Heading, View, YStack } from "tamagui";
import { Logo } from "../../components/Logo";
import { Button, TextArea } from "../../components/design-system";
import { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { currentUserProfileAtom } from "../../../atoms/global";
import { useAtom } from "jotai";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { BaseNavigatorParamList } from "../../navigators/BaseNavigator";
import { customColorTokens } from "../../../tamagui.config";
import { iProfile } from "../../features/Profile";

export const BreakfastFood = () => {
  const [breakfastFood, setBreakfastFood] = useState("");

  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();

  const [profileState, setProfileState] = useAtom(currentUserProfileAtom);

  const saveBreakfastFood = async () => {
    const profile: iProfile = {
      ...profileState,
      breakfast_food: breakfastFood,
    };
    setProfileState(profile);

    navigation.navigate("Onboarding", {
      screen: "Avatar",
    });
  };

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: customColorTokens.brandParchment }}
    >
      <YStack
        justifyContent="space-between"
        flex={1}
        alignItems="flex-start"
        p="$4"
        w="100%"
        space={10}
      >
        <View w="100%" alignItems="center" justifyContent="center">
          <Logo />
          <Heading w="100%" size={"$2"} mt={32} textTransform="uppercase">
            {`WHAT'S YOUR FAVORITE BREAKFAST FOOD?`}
          </Heading>
        </View>

        <KeyboardAvoidingView
          behavior="padding"
          enabled
          keyboardVerticalOffset={80}
          style={{ width: "100%", flex: 1 }}
        >
          <YStack w="100%" flex={1} justifyContent="space-between">
            <View justifyContent="flex-start" flex={1}>
              <TextArea
                onChangeText={(text) => setBreakfastFood(text)}
                placeholder="Skratch High Carb, Breakfast Taco, etc."
                autoFocus
                rows={3}
              />
            </View>
            <Button
              w="100%"
              disabled={breakfastFood.length === 0}
              onPress={saveBreakfastFood}
            >
              CONTINUE
            </Button>
          </YStack>
        </KeyboardAvoidingView>
      </YStack>
    </SafeAreaView>
  );
};
