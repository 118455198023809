import { useState } from "react";
import { supabase } from "../../lib/supabase";
import * as ImagePicker from "expo-image-picker";

import { decode } from "base64-arraybuffer";

export const useUploadAvatar = ({
  onAvatarUpload,
  userId,
}: {
  onAvatarUpload: (url: string) => void;
  userId: string;
}) => {
  const [avatarUploading, setAvatarUploading] = useState(false);

  async function uploadAvatar() {
    try {
      setAvatarUploading(true);

      const options: ImagePicker.ImagePickerOptions = {
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        base64: true,
      };

      const result = await ImagePicker.launchImageLibraryAsync(options);

      // Save image if not cancelled
      if (!result.canceled) {
        const res = result.assets[0];

        const fileExtension = getFileExtensionFromBase64(res.uri ?? "");
        const fileName = res.fileName ?? `${userId}-avatar.${fileExtension}`;

        if (fileName && res.base64) {
          const { data, error } = await supabase.storage
            .from("avatars")
            .upload(fileName, decode(res.base64), {
              contentType: "image/png",
              upsert: true,
            });

          data?.path && onAvatarUpload(data?.path);
          if (error) {
            throw error;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAvatarUploading(false);
    }
  }
  return { avatarUploading, uploadAvatar };
};

function getFileExtensionFromBase64(base64String: string): string | null {
  const mimeTypeRegex = /^data:(image\/\w+);base64,/;
  const match = base64String.match(mimeTypeRegex);

  if (match && match[1]) {
    // Extract MIME type and map it to a file extension
    switch (match[1]) {
      case "image/jpeg":
        return "jpg";
      case "image/png":
        return "png";
      case "image/gif":
        return "gif";
      case "image/webp":
        return "webp";
      case "image/bmp":
        return "bmp";
      case "image/tiff":
        return "tiff";
      // Add more MIME types and their corresponding extensions if needed
      default:
        return null; // MIME type not recognized
    }
  } else {
    return null; // MIME type not found in the string
  }
}
