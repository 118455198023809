import { atom } from "jotai";
import { Ride } from "../types";

const defaultRide: Ride = {
  name: null,
  description: null,
  date: null,
  time: null,
  type: "ClubRide",
  location: null,
  groups: null,
  terrain: "Road",
  route: undefined,
  visibility: "Public",
};

export const rideAtom = atom<Ride | null>(defaultRide);
