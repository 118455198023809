import { useCallback, useEffect, useState } from "react";
import { supabase } from "../../lib/supabase";

import { useIsFocused } from "@react-navigation/native";
import { id } from "date-fns/locale";

type ProfileSmall = {
  user_id: string;
  name: string;
  avatar_url: string | null;
  role: "Team" | "RideLeader" | "PaidMember" | "Member";
};

type Attendance = ProfileSmall & {
  id: string;
};

export type Event = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  date?: string | null;
  time?: string | null;
  type?:
    | "ClubRide"
    | "Babes"
    | "CentralMachineWorks"
    | "TheMeteorCafe"
    | "GravelGang"
    | "SwimClub";
  location?: string | null;
  groups?: ("A" | "B" | "C" | "D" | "Party")[] | null;
  terrain?: "Road" | "Gravel" | "MTB" | "Mixed";
  route?: {
    id: string | null;
    name: string | null;
    elevation: number | null;
    distance: number | null;
    strava_id: string | null;
    image_url: string | null;
  } | null;
  visibility?: "Public" | "Private";
  invited_users?: { id: string; name: string }[] | null;
  host?: ProfileSmall | null;
  attendees?: Attendance[] | null;
};

export const useGetEvent = (eventId?: string) => {
  const [isFetchingEvent, setIsFetching] = useState(false);
  const isFocused = useIsFocused();
  const [event, setEvent] = useState<Event | null>(null);

  const fetchEvent = useCallback(async () => {
    setIsFetching(true);
    if (!eventId) {
      return;
    }
    const { data, error } = await supabase
      .from("events")
      .select(
        "*, route:routes(id, name, elevation, distance, strava_id, image_url), host:profiles(id, name, avatar_url, roles: user_roles(role)), attendees:attendances(id, profile:profiles(id, name, avatar_url, roles: user_roles(role)))"
      )
      .eq("id", eventId)

      .single();

    if (error) {
      console.error("Error fetching event:", error);
      return;
    }

    const attendees: Attendance[] = data.attendees.map(
      (a: { profile: any; id: string }) => ({
        user_id: a.profile.id,
        id: a.id,
        role: a.profile.roles?.[0]?.role ?? "Member",
        name: a.profile.name,
        avatar_url: a.profile.avatar_url,
      })
    );

    const host = data.host
      ? {
          name: data.host.name,
          avatar_url: data.host.avatar_url,
          user_id: data.host.id,
          role: data.host.roles?.[0]?.role ?? "Member",
        }
      : undefined;

    setEvent({
      ...data,
      invited_users: [],
      attendees,
      host,
    });

    setIsFetching(false);
  }, [eventId, setIsFetching]);

  useEffect(() => {
    if (isFocused) {
      fetchEvent();
    }
  }, [isFocused, fetchEvent]);

  return { event, setEvent, fetchEvent, isFetchingEvent } as const;
};
