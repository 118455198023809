import { Button, Heading, ScrollView, Text, View, XStack } from "tamagui";
import { EventCard } from "../../../../components/design-system/EventCard";
import { brandShadow } from "../../../../../tamagui.config";
import { useIsFocused } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { supabase } from "../../../../../lib/supabase";
import { currentUserAtom } from "../../../../../atoms/global";
import { useAtomValue } from "jotai";
import { Event } from "../../../../features/Events/types";
import { iProfile } from "../../../../features/Profile";

type Invite = {
  id: string;
  event: Event;
  status: string;
  user: iProfile;
};

export const MyInvites = () => {
  const [invites, setInvites] = useState<Invite[] | null>(null);

  const isFocused = useIsFocused();

  const user = useAtomValue(currentUserAtom);

  useEffect(() => {
    if (isFocused) {
      supabase
        .from("invites")
        .select(
          "*, event:events(*, route:routes(*), profiles(*)), user:profiles(*)"
        )
        .eq("recipient_id", user?.id)
        .order("created_at", { ascending: false })
        .then(({ data }) => {
          const invitesMap = data?.map((i) => ({
            id: i.id,
            user: i.user,
            event: i.event,
            status: "pending",
          }));

          setInvites(invitesMap ?? []);
        });
    }
  }, [isFocused, setInvites]);

  return (
    <ScrollView px="$4">
      <Heading
        fontSize="$4"
        textTransform="uppercase"
        py="$4"
        pb={invites?.length === 0 ? "$2" : "$4"}
      >
        Your Invites
      </Heading>
      {invites?.length === 0 ? (
        <View px="$4" justifyContent="center" alignItems="center" h={100}>
          <Text>{`You don't have any invites`}</Text>
        </View>
      ) : null}
      {invites?.map((i) => (
        <View mb="$4" key={i.id}>
          <EventCard event={i.event} />
          <XStack gap="$2" mt="$2">
            <Button
              size="$3"
              fontSize={12}
              bg="#E15425"
              color="white"
              fontFamily={"$heading"}
              flex={1}
              pressStyle={{
                bg: "#E15425",
                shadowColor: "$colorTransparent",
                shadowOffset: { width: 0, height: 0 },
                borderWidth: 1,
                borderColor: "black",
                scale: 0.98,
              }}
              borderWidth={1}
              borderColor={"black"}
              {...brandShadow}
            >
              DECLINE
            </Button>

            <Button
              size="$3"
              fontSize={12}
              bg="#C2EABD"
              borderWidth={1}
              borderColor="black"
              color="black"
              flex={1}
              fontFamily={"$heading"}
              pressStyle={{
                bg: "$brandYellow",
                shadowColor: "$colorTransparent",
                shadowOffset: { width: 0, height: 0 },
                borderWidth: 1,
                borderColor: "black",
                scale: 0.98,
              }}
              {...brandShadow}
            >
              ACCEPT
            </Button>
          </XStack>
          <View bg="black" h={1} w="100%" mt="$4" />
        </View>
      ))}
    </ScrollView>
  );
};
