import { useQueryLoader } from "react-relay";
import { EditProfileQuery } from "./__generated__/EditProfileQuery.graphql";
import { EditProfile, EditProfileQueryString } from "./EditProfile";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";
import { Suspense, useEffect } from "react";
import { Loader } from "@tamagui/lucide-icons";

export const EditProfileQueryRenderer = () => {
  const user = useAtomValue(currentUserAtom);

  const [queryRef, loadQuery] = useQueryLoader<EditProfileQuery>(
    EditProfileQueryString
  );

  useEffect(() => {
    if (user?.id) {
      loadQuery({ id: user.id });
    }
  }, [user?.id, loadQuery]);

  const refreshQuery = () => {
    if (user?.id) {
      loadQuery({ id: user.id }, { fetchPolicy: "network-only" });
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      {queryRef ? (
        <EditProfile queryRef={queryRef} refreshQuery={refreshQuery} />
      ) : null}
    </Suspense>
  );
};
