import { Alert, KeyboardAvoidingView, Platform } from "react-native";
import { Heading, ScrollView, View } from "tamagui";
import { Logo } from "../../components/Logo";
import { Button } from "../../components/design-system";
import { useEffect, useMemo, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";

import { isEmpty } from "lodash";
import { supabase } from "../../../lib/supabase";
import { Session } from "@supabase/supabase-js";
// import { router } from "expo-router";
import { Loader } from "../../components/design-system/Loader";
import { EditProfile } from "../../features/Profile/components/EditProfile/EditProfile";
import { iProfile } from "../../features/Profile";

import { useNavigation } from "@react-navigation/native";
import { customColorTokens } from "../../../tamagui.config";
import { isAuthenticatedAtom } from "../../../atoms/global";
import { useSetAtom } from "jotai";

export const Profile = () => {
  const [loading, setLoading] = useState(false);

  const navigation = useNavigation();
  const setIsAuthenticated = useSetAtom(isAuthenticatedAtom);

  const [formState, setFormState] = useState<iProfile>({
    name: "",
    bio: "",
    group: "",
    avatar_url: "",
    breakfast_food: "",
    instagram: "",
  });

  const onProfileChange = (val: iProfile) => {
    setFormState(val);
  };

  const isFormValid = useMemo(
    () =>
      !isEmpty(formState.name) &&
      !isEmpty(formState.breakfast_food) &&
      !isEmpty(formState.avatar_url),
    [formState]
  );

  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  async function updateProfile() {
    try {
      setLoading(true);
      if (!session?.user) throw new Error("No user on the session!");
      const { name, bio, breakfast_food, avatar_url } = formState;
      const updates = {
        id: session?.user.id,
        name,
        bio,
        breakfast_food,
        avatar_url,
      };

      const { error } = await supabase.from("profiles").upsert(updates);

      if (error) {
        throw error;
      }
    } catch (error) {
      if (error instanceof Error) {
        Alert.alert(error.message);
      }
    } finally {
      setLoading(false);
      setIsAuthenticated(true);
      navigation.navigate("Home" as never);
    }
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: customColorTokens.brandParchment,
          width: "100%",
        }}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "position" : "height"}
          enabled
          style={{ flex: 1 }}
          contentContainerStyle={{
            width: "100%",
            flex: 1,
          }}
          keyboardVerticalOffset={4}
        >
          <ScrollView showsVerticalScrollIndicator={false}>
            <View alignItems="center" px="$4">
              <Logo />
              <Heading w="100%" size={"$2"} textTransform="uppercase">
                Edit your profile
              </Heading>
            </View>
            <EditProfile
              id={session?.user.id ?? ""}
              onChange={onProfileChange}
              defaultValues={formState}
            />
          </ScrollView>
          <View px="$4" pt="$1">
            <Button
              disabled={!isFormValid}
              opacity={!isFormValid ? 0.5 : 1}
              w="100%"
              onPress={() => updateProfile()}
            >
              Continue
            </Button>
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </>
  );
};
