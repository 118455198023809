import { useCallback, useEffect, useState } from "react";
import { supabase } from "../../lib/supabase";

import { useIsFocused } from "@react-navigation/native";
import { iProfile } from "../features/Profile";

type Friend = {
  status: "pending" | "accepted";
  friend: iProfile;
  id: string;
};

export const useGetFriendsList = (userId?: string) => {
  const isFocused = useIsFocused();
  const [friends, setFriends] = useState<Friend[]>([]);

  const fetchFriends = useCallback(async () => {
    if (!userId) {
      return;
    }
    const { data, error } = await supabase
      .from("friendships")
      .select(
        "*, user_profile:profiles!friendships_user_id_fkey(*), friend_profile:profiles!friendships_friend_id_fkey(*)"
      )
      .eq("status", "accepted")
      .or(`user_id.eq.${userId},friend_id.eq.${userId}`);

    if (error) {
      console.error("Error fetching friends:", error);
      return;
    }

    const friendsList = data.map((d) => ({
      id: d.id,
      status: d.status,
      friend: d.user_id === userId ? d.friend_profile : d.user_profile,
    }));

    setFriends(friendsList);
  }, [userId]);

  useEffect(() => {
    if (isFocused) {
      fetchFriends();
    }
  }, [isFocused, fetchFriends]);

  return { friends, setFriends, fetchFriends } as const;
};
