import { G, Path, Svg, SvgProps } from "react-native-svg";

export const StarIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 122.51 113.86" {...props}>
      <G id="Layer_2" data-name="Layer 2">
        <G id="Layer_1-2" data-name="Layer 1">
          <G id="Layer_1-2-2" data-name="Layer 1-2">
            <Path
              fill="#e1b229"
              d="M34.7,1.5C33.13,1.45,34,24.55,34,29.89c-11.7.17-50.3-.65-25.94,14.76,6.07,3.76,9.17,7.81,15.77,10.5a46.37,46.37,0,0,0-6.75,5.94C5.31,74.53,0,81.54,0,83.61c1.64,5,1,3.25,12.9,3.69,8.65-.08,12.44-.1,17.54-.41,1.66,38.78,6,29.5,28.19,7.3,4.6,4.61,20.05,15.34,22.79,16,2.77,0,3.61-1.36,4.47-7.3.75-5.22,1.55-9.71,2.42-14.2,9.65.39,19.47,6.21,26.88,3.46a3.43,3.43,0,0,0,.89-2c0-1.71-5.54-13.22-6.89-14.33S101.73,67,99.63,64.43A147.32,147.32,0,0,1,113,56.8c7.5-4.43,12-6.1,7.85-10.66-2-3-30.35-4-34.33-5C90,3,93.71-10.53,60.73,23.56c-1.94-2.94-4-5-8.8-10.17-5.5-4.3-12.52-17.9-17.24-11.88h0Zm17,24c5.83,7.3,8.7,13.72,14.34,5.25,3.2-4.23,13.38-15.22,14.1-15.22.42,0,.19,5.05-1,21.7-.71,10-.59,10.18,8.5,10.82,5.48.65,23.36,2.2,18.7,3.79a53.47,53.47,0,0,0-6.18,3.55c-4.62,3.64-11.22,4.29-10.27,10.1.26,1,4,5.31,8.32,9.52,7.78,7.61,9.39,10,6.21,9-4.23-1.25-18-3.37-19.45-3-7.88,2.23-4.84,12.23-6.35,19.08C74.79,98.39,61,87.28,56.84,86.54c-1.75-1.36-16.28,16-17.78,15.08-.88-.65.6-22.85-1.25-22-1.6-1.63-6.85-2.09-13-1.15-6.89,1.06-14.13,1.65-14.13,1.15,4.67-5.38,10-12.85,16.56-17.54,14.24-8.62-1.88-13-8.16-19.75-2.32-2.15-4.54-3.87-5-3.89,1.07-1.81,6.51-1.06,13.27-1.26,13.86.28,13.42.66,14.42-12.7l.74-9.83c2.8,3,7.29,7.83,9.15,10.86h0Z"
            />
          </G>
          <Path
            fill="#e1b229"
            d="M33.78,33.57v7.56a13.37,13.37,0,0,0,.2,3.95,3.65,3.65,0,0,0,3.63,2.43c1.09,0,2.16-.13,3.25-.11.55,0,1.1,0,1.65.07l.82.07.55.06c-.16,0-.16,0,0,0a28,28,0,0,1,3.37.7c.53.15,1.05.31,1.58.49l.77.27.52.2c.28.12.34.14.17.06a2.58,2.58,0,0,0,3.42-.89c.56-1.07.38-2.88-.9-3.42a31.28,31.28,0,0,0-13.21-2.61c-.51,0-1,.05-1.51.08,0,0-.6,0-.6,0,.1-.32,2.16,1.43,1.23,1l0,.22V33.57a2.52,2.52,0,0,0-2.5-2.5,2.55,2.55,0,0,0-2.5,2.5Z"
          />
          <Path
            fill="#e1b229"
            d="M107.53,49.62a70.91,70.91,0,0,1-29.3-9.08c-2.8-1.59-5.32,2.73-2.52,4.32a76.86,76.86,0,0,0,31.82,9.76,2.52,2.52,0,0,0,2.5-2.5,2.55,2.55,0,0,0-2.5-2.5Z"
          />
          <Path
            fill="#e1b229"
            d="M16.72,79.63V79A2.83,2.83,0,0,0,16,77.18a2,2,0,0,0-.81-.52,1.88,1.88,0,0,0-1-.21l-.67.09a2.5,2.5,0,0,0-1.1.64l-.39.51A2.43,2.43,0,0,0,11.72,79v.68a2.69,2.69,0,0,0,.73,1.77,2,2,0,0,0,.81.52,1.85,1.85,0,0,0,1,.21l.66-.09A2.48,2.48,0,0,0,16,81.4l.39-.51a2.53,2.53,0,0,0,.34-1.26Z"
          />
          <Path fill="#e1b229" d="M13.08,81.9c3.21,0,3.22-5,0-5s-3.23,5,0,5Z" />
          <Path fill="#e1b229" d="M12.85,81.9c3.22,0,3.22-5,0-5s-3.22,5,0,5Z" />
          <Path
            fill="#e1b229"
            d="M68.77,20.93a90.42,90.42,0,0,1-6,22.07c-1.52,3.76,1.65,8.24,5.24,9.23,4.28,1.17,7.7-1.47,9.22-5.24a104.09,104.09,0,0,0,6.59-26.06c.52-4-3.82-7.5-7.5-7.5-4.49,0-7,3.47-7.5,7.5Z"
          />
          <Path
            fill="#e1b229"
            d="M97.61,46.15a86.29,86.29,0,0,1-26.33-4.37c-9.2-3-13.14,11.47-4,14.46a100.37,100.37,0,0,0,30.32,4.91c9.65.09,9.66-14.91,0-15Z"
          />
          <Path
            fill="#e1b229"
            d="M105.63,74.71A126.62,126.62,0,0,1,85.3,59.27c-7.06-6.57-17.69,4-10.61,10.61A146.86,146.86,0,0,0,98.06,87.66c8.25,5,15.8-7.92,7.57-13Z"
          />
          <Path
            fill="#e1b229"
            d="M83.75,92.17Q72.38,67.29,63.44,41.41C60.3,32.34,45.8,36.24,49,45.4q9.6,27.68,21.82,54.34c1.7,3.72,7,4.55,10.26,2.69,3.81-2.16,4.37-6.6,2.69-10.26Z"
          />
          <Path
            fill="#e1b229"
            d="M27.2,76.66v-.93L21.7,83c5.3-2,9.58-4.33,12.92-9,2.63-3.69,4.22-8.07,6.05-12.19,1.65-3.69,1.07-8.06-2.69-10.26-3.2-1.87-8.61-1-10.26,2.69-2.44,5.46-4,12.05-10,14.31-3.14,1.18-5.48,3.72-5.5,7.24v.93c0,9.66,15,9.67,15,0Z"
          />
          <Path
            fill="#e1b229"
            d="M17.18,48.69l26.43,9.13c9.15,3.16,13.08-11.32,4-14.46L21.17,34.22C12,31.06,8.1,45.55,17.18,48.69Z"
          />
          <Path
            fill="#e1b229"
            d="M36.15,47.4c5.18-.07,10.36-.06,15.54.06,9.66.2,9.66-14.8,0-15-5.18-.12-10.36-.13-15.54-.06-9.64.13-9.67,15.13,0,15Z"
          />
          <Path
            fill="#e1b229"
            d="M36.39,19.53A58.31,58.31,0,0,0,54.73,53C61.86,59.53,72.5,49,65.34,42.43A45.34,45.34,0,0,1,50.85,15.54c-1.57-9.5-16-5.47-14.46,4Z"
          />
          <Path
            fill="#e1b229"
            d="M53.06,32.55c1,11.19,6.29,20,15.32,26.54,1.14.84,11.89,8.37,12.68,7.7-.64.54-1.47-6-2-.09-.15,1.55-.31,3.1-.49,4.64-.14,1.24-.29,2.48-.46,3.71-.11.88-.24,1.75-.37,2.63q-.63,2.59,1.6-1.12h7.57c-3.93-1.7-6.18-6.71-6.5-10.8a7.75,7.75,0,0,0-7.5-7.5c-7.66-.71-18.51-3.18-21.3,6.75-2.33,8.27,2.58,17,6.76,23.72L64.8,77.44c-8.58.1-17.46-2-22.54,7-4.74,8.42,8.21,16,13,7.57.39-.69,7.84.49,9.58.47,5.55-.06,9.52-6.37,6.48-11.28a56.6,56.6,0,0,1-3.88-6.81,16.2,16.2,0,0,1-1.08-3.19,4.32,4.32,0,0,1-.26-1.55c0-1.65-.47-1-1.47,1.8l-3.79,1c3.89-.42,8.18.47,12.06.83l-7.5-7.5c.68,8.61,4.41,17.66,11.88,22.61,4.39,2.9,10.11,2.75,13.2-2,2.55-3.88,2.44-9.69,3-14.15.49-4.18,1.79-9.26,0-13.28-1.86-4.24-6.47-6.07-10.25-8.25C76,46.57,68.86,41.59,68.06,32.55c-.83-9.55-15.84-9.63-15,0Z"
          />
          <Path
            fill="#e1b229"
            d="M69.28,94.67c9.65,0,9.67-15,0-15s-9.67,15,0,15Z"
          />
          <Path
            fill="#e1b229"
            d="M48.82,95c-.28-6.13-2.61-11.53-3.61-17.5a21.85,21.85,0,0,1,3.52-15.53l-14-3.78c.22,5.3.65,10.57,1.33,15.83.6,4.68.56,10.28,4.32,13.64,3.93,3.53,11.29,2.53,12.53-3.31l5.91-27.87c2-9.41-12.46-13.44-14.46-4L38.48,80.32,51,77q1.14,2.65.63.51c-.15-.92-.3-1.84-.43-2.77-.27-1.8-.48-3.61-.67-5.42-.39-3.73-.62-7.46-.78-11.19a7.57,7.57,0,0,0-14-3.79A36.68,36.68,0,0,0,30,74.52C30.08,81.6,33.51,88,33.82,95c.43,9.61,15.43,9.66,15,0Z"
          />
        </G>
      </G>
    </Svg>
  );
};
