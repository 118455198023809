import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../atoms/global";
import { supabase } from "../../lib/supabase";
import { useState } from "react";

export const useRemoveFriendRequest = () => {
  const currentUser = useAtomValue(currentUserAtom);

  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveFriendRequest = async (
    friendshipId: string,
    callback: () => void
  ) => {
    setIsLoading(true);
    await supabase
      .from("friendships")
      .delete()
      .eq("id", friendshipId)

      .then((res) => console.log(res));

    callback();
    setIsLoading(false);
  };

  return [handleRemoveFriendRequest, isLoading] as const;
};
