import React from "react";
import { Heading, Text, View } from "tamagui";

import { Header } from "../../components/design-system/Header";

import { MySchedule } from "./components/MySchedule";
import { ArrowLeft } from "@tamagui/lucide-icons";
import { Pressable } from "react-native";
import { RidesNavigatorParamList } from "../../navigators/CreateARideNavigator";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { MyInvites } from "./components/MyInvites/MyInvites";

export const MyCalendar = () => {
  const navigation = useNavigation<NavigationProp<RidesNavigatorParamList>>();
  return (
    <View flex={1} bg="$brandParchment">
      <Header
        title={
          <Pressable onPress={() => navigation.goBack()}>
            <ArrowLeft />
          </Pressable>
        }
        headerCenter={
          <Text fontSize={16} textTransform="uppercase" fontFamily="$heading">
            Your Ride Calendar
          </Text>
        }
        headerRight={<View />}
      />

      <MySchedule
        ListHeaderComponent={
          <>
            <MyInvites />

            <Heading fontSize="$4" textTransform="uppercase" px="$4" pt="$2">
              Your rides
            </Heading>
          </>
        }
      />
    </View>
  );
};
