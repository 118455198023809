import { ImageBackground } from "react-native";
import { YStack, View, Text } from "tamagui";
import { Button } from "../../components/design-system/Button";
import { SafeAreaView } from "react-native-safe-area-context";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { OnboardingNavigatorParamList } from "../../navigators/OnboardingNavigator";
import { brandShadow } from "../../../tamagui.config";
import { ClubLogo } from "../../components/ClubLogo";
import * as Linking from "expo-linking";

import { Platform } from "react-native";

export const Landing = () => {
  const navigation =
    useNavigation<NavigationProp<OnboardingNavigatorParamList>>();

  const isWeb = Platform.OS === "web";
  const webSafeAreaStyle = isWeb
    ? {
        marginBottom: "env(safe-area-inset-bottom)",
      }
    : {};

  return (
    <ImageBackground
      source={require("../../../assets/pattern.png")}
      style={{ flex: 1, width: "100%" }}
    >
      <SafeAreaView
        style={{
          flex: 1,
          width: "100%",
        }}
      >
        <YStack
          justifyContent="center"
          flex={1}
          alignItems="center"
          p="$4"
          enterStyle={{
            scale: 1.5,
            y: -10,
            opacity: 0,
          }}
          animation="quick"
          elevation="$4"
        >
          <ClubLogo width="100%" height={150} color="black" />
          <View
            position="absolute"
            bottom={4}
            w="100%"
            gap="$2"
            $platform-web={{ pb: "$8", px: "$4" }}
          >
            <Text
              textAlign="center"
              onPress={() =>
                Linking.openURL("https://breakfastclubatx.com/terms-of-service")
              }
            >
              By pressing enter you agree to our terms of service
            </Text>
            <Button
              onPress={() => navigation.navigate("Phone")}
              w="100%"
              style={{ ...webSafeAreaStyle }}
              {...brandShadow}
            >
              ENTER
            </Button>
          </View>
        </YStack>
      </SafeAreaView>
    </ImageBackground>
  );
};
