import { G, Path, Svg, SvgProps } from "react-native-svg";

export const ArrowLeftIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 100.68 96.71" {...props}>
      <G id="Layer_1-2">
        <Path
          fill="currentColor"
          d="M45.8903 95.9699C50.3703 93.3499 48.6303 88.9799 39.0403 78.7399C34.6603 74.0699 31.5503 70.3499 23.8103 60.5199L19.8903 55.5399L21.8703 54.9999C25.9103 53.8999 43.8503 52.9899 68.6203 52.6299C82.6103 52.4299 95.1203 52.0999 96.4003 51.8999C99.0603 51.4799 101.22 48.7699 100.56 46.6699C99.3703 42.8799 86.5703 40.8299 72.4403 42.1799C67.4003 42.6599 55.5803 43.0099 46.0903 42.9499C36.6003 42.8899 26.4703 43.0899 23.5003 43.3899L18.1103 43.9399L20.0903 41.4799C21.1703 40.1399 23.3903 37.1499 24.9703 34.8899C26.5503 32.6399 29.2603 29.0499 30.9203 27.0099C34.4203 22.7099 40.6503 13.5899 42.3903 10.2199C44.1203 6.87993 43.8903 4.05992 41.7203 1.88992C39.5903 -0.240077 36.8103 -0.590081 34.5503 0.979919C33.7303 1.54992 30.5403 5.70993 27.5203 10.1499C20.0903 21.0699 7.88031 37.9499 2.28031 45.0299C-1.61969 49.9599 -1.35969 50.5599 9.38031 61.5499C14.6503 66.9399 20.0803 72.7799 21.5103 74.5899C25.7403 79.8899 42.5603 96.7399 43.6403 96.7099C44.2103 96.6999 45.2103 96.3699 45.8903 95.9699Z"
        />
      </G>
    </Svg>
  );
};
