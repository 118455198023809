import { View, Heading, Text, YStack } from "tamagui";
import Avatar from "./Avatar";
import { StarIcon } from "./design-system/icons";
import { graphql, useFragment } from "relay-hooks";
import { ProfileCard_fragment$key } from "./__generated__/ProfileCard_fragment.graphql";
import { format } from "date-fns";

type ProfileCardProps = {
  fragmentRef: ProfileCard_fragment$key | null;
};

export const ProfileCard = ({ fragmentRef }: ProfileCardProps) => {
  const data = useFragment(ProfileCardFragment, fragmentRef);
  const role = data?.user_rolesCollection?.edges?.[0]?.node?.role ?? "Member";
  const roleDisplay =
    role === "PaidMember" ? "Club" : role.split(/(?=[A-Z])/).join(" ");

  const date = new Date(data?.created_at);

  const formattedDate = format(date, "MM/yy");

  return (
    <YStack
      justifyContent="center"
      alignItems="center"
      pos="relative"
      p="$4"
      borderRadius={15}
      gap="$6"
      display="flex"
      flexDirection="column"
      w="100%"
      h={350}
    >
      <View pos="relative">
        <Avatar
          size={150}
          imageStyle={{
            borderRadius: 999,
          }}
          url={data?.avatar_url ?? null}
        />
        {role !== "Member" ? (
          <View
            borderRadius={999}
            p="$1"
            justifyContent="space-between"
            alignItems="center"
            pos="absolute"
            bottom={-20}
            right={0}
          >
            <StarIcon width={50} height={50} />
            <Heading fontSize={12} textTransform="uppercase" fontStyle="italic">
              {roleDisplay}
            </Heading>
          </View>
        ) : null}
      </View>

      <YStack gap="$1" justifyContent="center" alignItems="center">
        <Heading
          size="$4"
          textTransform="uppercase"
          fontFamily="$heading"
          flexShrink={0}
        >
          {data?.name}
        </Heading>
        <View justifyContent="center" alignItems="center">
          <Text fontFamily="$heading" fontSize={16} mt="$2">
            Favorite Breakfast Food
          </Text>
          <Text>{data?.breakfast_food}</Text>
        </View>

        <View justifyContent="center" alignItems="center">
          <Text fontFamily="$heading" fontSize={16} mt="$2">
            Member Since
          </Text>
          <Text>{formattedDate}</Text>
        </View>
      </YStack>
    </YStack>
  );
};

export const ProfileCardFragment = graphql`
  fragment ProfileCard_fragment on profiles {
    id
    name
    avatar_url
    bio
    strava_code
    breakfast_food
    instagram
    created_at
    user_rolesCollection {
      edges {
        node {
          role
        }
      }
    }
  }
`;
