import * as React from "react";
import { Animated, Easing } from "react-native";
import { MotiView } from "moti";
import { Loader } from "@tamagui/lucide-icons";

type Props = {
  fadeInDelay?: number;
};

const SpinnerComponent = ({ fadeInDelay = 1000 }: Props) => {
  const fadeInValue = new Animated.Value(0);
  const spinValue = new Animated.Value(0);

  Animated.sequence([
    Animated.delay(fadeInDelay),
    Animated.timing(fadeInValue, {
      toValue: 1,
      duration: 1500,
      easing: Easing.linear,
      useNativeDriver: true,
    }),
  ]).start();

  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 360,
      duration: 300000,
      easing: Easing.linear,
      useNativeDriver: true,
    })
  ).start();

  return (
    <MotiView
      from={{
        scale: 0.8,
      }}
      animate={{
        scale: 1,
      }}
      transition={{
        loop: true,
        type: "timing",
        duration: 1500,
        delay: 0,
      }}
      style={{
        zIndex: 20,
        flexGrow: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loader />
    </MotiView>
  );
};

SpinnerComponent.displayName = "Spinner";

export const Spinner = React.memo(SpinnerComponent);
