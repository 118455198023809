import { View } from "tamagui";
import { Spinner } from "./Spinner";

export const Loader = () => {
  return (
    <View
      flex={1}
      justifyContent="center"
      alignItems="center"
      position="absolute"
      zIndex={1000}
      top={0}
      right={0}
      left={0}
      bottom={0}
    >
      <View zIndex={1000}>
        <Spinner />
      </View>
      <View
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="$brandParchment"
        opacity={0.9}
      />
    </View>
  );
};
