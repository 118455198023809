import { createNativeStackNavigator } from "@react-navigation/native-stack";

import {
  RouteProp,
  getFocusedRouteNameFromRoute,
} from "@react-navigation/native";
import { useLayoutEffect } from "react";
import { HomeTabsParamList } from "./HomeTabsNavigator";

import { CommunityRides } from "../screens/Community/CommunityRides";

import { MyCalendar } from "../screens/MyCalendar";
import { CreateRideForm } from "../screens/CreateRide";

export type RidesNavigatorParamList = {
  Community: undefined;
  FindARide: undefined;
  CreateRide: undefined;
  Route: undefined;
  RideInformation: undefined;
  Share: { eventId: string };
  Calendar: undefined;
};

const Stack = createNativeStackNavigator<RidesNavigatorParamList>();

const tabBarHiddenRoutes = ["MySchedule", "Calendar", "CreateRide"];

export const RidesNavigator = ({
  navigation,
  route,
}: {
  navigation: any;
  route: RouteProp<HomeTabsParamList, "rides">;
}) => {
  useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);

    if (tabBarHiddenRoutes.includes(routeName ?? "")) {
      navigation.setOptions({ tabBarStyle: { display: "none" } });
    } else {
      navigation.setOptions({ tabBarStyle: { display: "flex" } });
    }
  }, [navigation, route]);

  return (
    <Stack.Navigator screenOptions={{ headerShown: false, title: "Profile" }}>
      <Stack.Screen name="Community" component={CommunityRides} />
      <Stack.Screen name="Calendar" component={MyCalendar} />
      <Stack.Screen
        name="CreateRide"
        component={CreateRideForm}
        options={{
          presentation: "fullScreenModal",
        }}
      />
    </Stack.Navigator>
  );
};
