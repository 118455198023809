import React from "react";
import { Button, Text, XStack } from "tamagui";
import Avatar from "./Avatar";
import { ArrowRightIcon } from "./design-system/icons/ArrowRightIcon";
import { Role } from "../features/Profile/types";
import { UserRole } from "./__generated__/ProfileCard_fragment.graphql";

type MemberListItemProps = {
  id: string;
  name: string;
  avatarUrl: string | null;
  role: UserRole;
  onPress: (id: string) => void;
  hideArrow?: boolean;
};

const MemberListItemUnmemoized = ({
  id,
  name,
  avatarUrl,
  role,
  onPress,
  hideArrow,
}: MemberListItemProps) => {
  return (
    <Button onPress={() => onPress(id)} unstyled chromeless>
      <XStack alignItems="center" py="$2">
        <XStack flexGrow={1} alignItems="center">
          <Avatar
            url={avatarUrl}
            size={40}
            height={40}
            imageStyle={{
              marginRight: 12,
              borderRadius: 999,
            }}
            role={role as Role}
          />
          <Text fontSize="$3" ml={12}>
            {name}
          </Text>
        </XStack>

        {hideArrow ? null : <ArrowRightIcon width={20} height={20} />}
      </XStack>
    </Button>
  );
};

export const MemberListItem = React.memo(MemberListItemUnmemoized);
