import { G, Path, Svg, SvgProps } from "react-native-svg";

export const CompassIcon = (props: SvgProps) => {
  return (
    <Svg id="Layer_2" viewBox="0 0 89.6 90.37" {...props}>
      <G id="Layer_1-2">
        <Path d="m38.37.62c-9.3,1.04-20.23,6.62-26.18,13.38C7.74,19.06,1.78,31.12.64,37.37c-.52,2.86-.78,9.05-.57,13.82.33,7.65.72,9.38,3.41,15.14,3.81,8.14,6.21,10.97,12.76,15,8.56,5.27,18.44,8.58,26.74,8.95,6.29.28,8.06,0,14.64-2.4,4.11-1.49,8.41-3.32,9.54-4.05,4.44-2.89,15.82-14.65,18.2-18.82,3.3-5.76,4.73-14.73,4.08-25.5-.61-10.12-2.33-15.49-6.9-21.47-4.92-6.45-9.71-9.83-18.93-13.36C50.3-.42,49.25-.59,38.37.62h0Zm22.62,11.12c9.05,3.85,16,9.3,18.72,14.68,3.95,7.82,4.82,25.9,1.6,33.16-1.97,4.44-12.71,16.79-16.61,19.1-15.05,8.9-40.68,5.08-51.85-9.22-.99-1.27-2.82-4.73-4.06-7.69-1.99-4.73-2.27-6.7-2.34-16.24-.05-6.9.19-11.4,3.33-17.54,6.2-12.09,12.18-17.35,21.43-18.82,2.96-.47,5.55-1.05,5.8-1.3.25-.25,3.78-.32,7.82-.16,6.4.25,8.52.78,16.17,4.03Z" />
        <Path d="m47.86,25.21c-18.41,6.35-21.18,7.65-21.78,10.26-1.14,4.98-3.08,30.79-2.44,32.44,1.14,2.92,3.82,3.08,8.9.55,2.71-1.36,7.41-3.39,10.6-4.58,3.13-1.17,8.61-3.65,12.09-5.47q6.37-3.33,7.53-9.4c1.88-9.85,2.66-16.12,2.74-22.19.07-4.98-.21-5.81-2.01-6.02-1.15-.14-8.16,1.84-15.62,4.42h0Zm11.36,7.57c-1.11,9.45-2.48,16.23-3.54,17.51-1.24,1.49-24.9,12.55-25.53,11.94-.27-.27.07-5.92.75-12.48l1.24-11.99,12.54-4.93c6.87-2.7,13.12-4.95,13.83-4.98.93-.04,1.13,1.34.71,4.93h0Z" />
      </G>
    </Svg>
  );
};
