import { Suspense, useState } from "react";
import { MembersList, MembersListQueryString } from "./MembersList";
import { Loader } from "../../../../components/design-system/Loader";
import { useLazyLoadQuery } from "react-relay";
import { MembersListQuery } from "./__generated__/MembersListQuery.graphql";

import { SafeAreaView } from "react-native-safe-area-context";
import { Heading, View } from "tamagui";
import { customColorTokens } from "../../../../../tamagui.config";

import { HeaderButton } from "../../../../components/design-system/Button/HeaderButton";
import { CloseIcon } from "../../../../components/design-system/icons";
import { useNavigation } from "@react-navigation/native";
import { Input } from "../../../../components/design-system";
import { debounce } from "lodash";

export const MembersListQueryRenderer = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [refreshing, setRefreshing] = useState(false);

  const refreshMembers = () => {
    setRefreshing(true);
    setRefreshing(false);
  };

  const navigation = useNavigation();
  const setDebounceSearchTerm = debounce(setSearchTerm, 500);

  const data = useLazyLoadQuery<MembersListQuery>(MembersListQueryString, {
    searchTerm: `%${searchTerm}%`,
  });

  return (
    <SafeAreaView
      edges={["top"]}
      style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
    >
      <View px="$4" justifyContent="flex-start" alignItems="flex-start">
        <HeaderButton onPress={() => navigation.goBack()}>
          <CloseIcon width={16} height={16} />
        </HeaderButton>
      </View>
      <View flex={1} px="$4">
        <View py="$4" justifyContent="center">
          <Heading size="$3" textTransform="uppercase">
            Members
          </Heading>
          <Input
            mt="$4"
            placeholder="Search"
            onChangeText={(text) => setDebounceSearchTerm(text)}
          />
        </View>
        <View flex={1} pos="relative">
          <Suspense fallback={<Loader />}>
            {data ? (
              <MembersList
                fragmentRef={data}
                refreshing={refreshing}
                refreshMembers={refreshMembers}
              />
            ) : null}
          </Suspense>
        </View>
      </View>
    </SafeAreaView>
  );
};
